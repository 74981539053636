import React, { Component } from "react";


import BootstrapTable from "react-bootstrap-table-next";


import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";


// koneksi
import { GetWisata, PostWisata, DeleteWisata, cekLogin, ID_DESA } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../function/Format";
// import DatePicker from "reactdatepicker";


const frmDef = {
  uuid: "",
  username: "",
  password: "",
  status: null,
  no_hp: null,
  alamat: null,
  email: null,
  nama: '',
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset:false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKel: [],
      kelStat: [],
      pendStat: [],
      frmPen: [],
      jpStat: [],
      frmJp: [],
      frmRole: [],
      frmRoleAdmin: [],
      formPenduduk: "none",
      dataVerifikasi: [],
      photoIndex: 0,
      isOpen: false,
      idVerifikasi: null,
      statusAkun: null,
      akun: DataToken(sessionStorage.getItem('access_token')),
      dataReset:{
        uuid:'',
        username:'',
      }
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    PostWisata("pengguna" + link, null, {
			id_desa: ID_DESA,
      q: this.state.textCari
		}, dtkat => {

      console.log('datanya')
      console.log(dtkat)
      this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results, loading: false });
      this.pagination(dtkat.data.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  
  // Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
  
    this.state.status.btnForm = true;
    this.forceUpdate();

    let add = {
      uuid: this.state.dt.uuid,
      id_desa: ID_DESA,
      nama: document.getElementById("nama").value,
      username: document.getElementById("username").value,
      email: document.getElementById("email").value,
      alamat: document.getElementById("alamat").value,
      no_hp: document.getElementById("no_hp").value,
      role: 'Admin'
    };
    if (document.getElementById("password").value !== "") {
      add = {
        // uuid: this.state.dt.uuid,
        // username: document.getElementById("username").value,
        // status: '3',
        // id_role: this.state.dt.id_role,
        // no_hp: document.getElementById("nohp").value,
        // email: document.getElementById("email").value,
        ...add,
        password: document.getElementById("password").value,
      };
    }
    
    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (add.uuid === "") {
      psn = "Tambah";
      resstat = 201;
      add.uuid = null;
    } else {
      psn = "Ubah";
      resstat = 201;
      metode = "update";
      
      add = {
        ...add,
        status: this.state.statusAkun.value,
      };
    }
    console.log('dataaaa simpan bos')
    console.log(add)
    PostWisata("pengguna/" + metode, add.uuid, add, res => {
      this.state.status.btnForm = false;
      this.state.status.form = false;
      this.forceUpdate();
      console.log('respon')
      console.log(res)
      if (res.status === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengguna",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengguna",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  
   // ================== simpan reset data ==================
  simpanReset = id => {
    if(id){
      GetWisata("pengguna/reset-password/" + id,null, res => {
        this.forceUpdate();
        console.log('resetnya');
        console.log(res);
        if (res ) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil reset Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal reset Data"
          });
        }
        this.tutupForm();

        this.componentDidMount();
      });
    }
    else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengguna",
        pesanAlert: "Gagal reset Data"
      });
    }
  };
  // ================== End simpan reset data ==================
  
  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    GetWisata("pengguna/show", id, data => {
      console.log('data edit');
      console.log(data);

      this.setState({
        dt: {
          uuid: data.results.uuid,
          nama: data.results.nama,
          username: data.results.username,
          no_hp: data.results.no_hp,
          alamat: data.results.alamat,
          email: data.results.email,
          status: data.results.status,
        },
        statusAkun: {
          value: data.results.status,
          label: data.results.status,
        }
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
    });
  };

  resetPassword = data => {
    this.setState({
      dataReset:data,
      status:{
        ...this.state.status,
        formReset:true,
      }
    })
  };
  
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    DeleteWisata("pengguna/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengguna",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengguna",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.state.status.formReset=false
    this.state.status.formVerifikasi = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Operator",
              statUbah: false
            });
            this.ubahData(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Reset Password",
              statUbah: false
            });
            this.resetPassword(row);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-repeat"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Akun",
              statUbah: false
            });
            this.konfirmHapus(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: e });
          break;

        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: null });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  
  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    var data = this.state.data;

    // var data = require('../assets/json/company');

    const frmDef = {
      uuid: "",
      username: "",
      nama: "",
      password: "",
      nik: "",
      status: null,
      no_hp: null,
      alamat: null,
      email: null
    };
    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "username",
        text: "Username",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "nama",
        text: "Nama",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "no_hp",
        text: "No. HP",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "alamat",
        text: "Alamat",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "email",
        text: "Email",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },

      // {
      //   dataField: "status",
      //   text: "Status",
      //   isDummyField: true,
      //   headerAlign: 'center',
      //   align: 'center',
      //   formatter: (cell, obj) => {
      //     let status = "Tidak Aktif"

      //     switch (obj.status) {
      //       case 3:
      //         status = "Aktif"
      //         break;
            
      //       case 1:
      //         status = "Aktif"
      //         break;
          
      //       default:
      //         status = "Tidak Aktif"
      //         break;
      //     }

      //     return status;
      //   },
      //   sort: true
      // },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama_role",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-5 text-right">
                      <input
                        type="text"
                        className="form-control"
                        style={{ marginBottom: '15px' }}
                        id="cariTable"
                        placeholder="Cari berdasarkan nama, username, atau email"
                        defaultValue={this.state.textCari}
                        onChange={(e) => {
                          this.setState({ textCari: e.target.value })
                        }}
                        onKeyPress={this.cariData}
                      />
                      <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                        <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            textCari: '',
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                          document.getElementById('cariTable').value = ''
                        }} ></span>
                      </div>
                    </div>
                    <div className="col-md-7" style={{ marginBottom: '15px' }}>
                      <Button
                        size="sm"
                        color="info"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            textCari: document.getElementById("cariTable").value,
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="col-md-12 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Pengguna",
                            dt: frmDef,
                            pfrmPrv: [],
                            prvStat: [],
                            frmKbkt: [],
                            kbktStat: [],
                            frmKec: [],
                            kecStat: [],
                            frmKel: [],
                            kelStat: [],
                            pendStat: [],
                            frmPen: [],
                            jpStat: [],
                            frmJp: [],
                            statusAktifStat: [],
                            statusPegawaiStat: [],
                            roleStat: [],
                            statusAkun: null,
                            statUbah: true
                          });
                          //console.log(this.state.statUbah);
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <>
                      <BootstrapTable keyField="id" data={data} columns={columns} />
                      <div className="pull-right text-white">
                        {this.state.awal}
                        {this.state.sebelum}
                        {this.state.hal.map(dt => {
                          return dt;
                        })}
                        {this.state.setelah}
                        {this.state.akhir}
                      </div>
                    </>
                  ) : (
                    <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.formVerifikasi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" >
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              {this.state.status.statGet === false ? (
                this.state.dataVerifikasi.length === 0 ? (
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Berkas Kosong
                  </Label>
                ) : (
                  <>
                    <div className="my-gallery card-body row">
                      {this.state.dataVerifikasi.map((pj, index) => {
                        return (
                          <figure className="col-xl-4 col-md-4 col-6">
                            <img
                              src={ pj.link}
                              alt="Gallery"
                              className="img-thumbnail"
                              onClick={() =>
                                this.setState({ photoIndex: 0, isOpen: true })
                              }
                            />
                            <Label
                              className="col-sm-12 col-form-label text-center"
                              htmlFor="inputEmail3"
                            >
                              {pj.jenis}
                            </Label>
                          </figure>
                        );
                      })}
                    </div>

                    {isOpen && (
                      <Lightbox
                        mainSrc={
                          
                          this.state.dataVerifikasi[photoIndex].link
                        }
                        nextSrc={
                          
                          this.state.dataVerifikasi[
                            (photoIndex + 1) % this.state.dataVerifikasi.length
                          ]
                        }
                        prevSrc={
                          
                          this.state.dataVerifikasi[
                            (photoIndex +
                              this.state.dataVerifikasi.length -
                              1) %
                              this.state.dataVerifikasi.length
                          ]
                        }
                        imageTitle={
                          0 +
                          1 +
                          "/" +
                          this.state.dataVerifikasi[photoIndex].link.length
                        }
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex +
                                this.state.dataVerifikasi.length -
                                1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex + 1) %
                              this.state.dataVerifikasi.length
                          })
                        }
                      />
                    )}
                  </>
                )
              ) : (
                <>
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Menunggu data
                  </Label>
                </>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              {" "}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.verifikasi('tolak',this.state.idVerifikasi)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Tolak Berkas

                  </button>
              {this.state.dataVerifikasi.length > 0 ? (
                <>
                 
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      this.verifikasi('terima',this.state.idVerifikasi)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Verifikasi Berkas
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          size="lg"
          open={this.state.status.formReset}
          onClose={this.tutupForm}
          styles={{ modal: { width: "50%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" >
            <div className="modal-body text-center">
            <p>Anda akan melakukan reset password terhadap {this.state.dataReset.username}?</p>
             Password akan berubah menjadi <b><u>12345678</u></b>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              {" "}
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      this.simpanReset(this.state.dataReset.uuid)}
                    }
                    disabled={this.state.status.btnForm}
                  >
                    Reset Password
                  </button>
           
            </div>
          </Form>
        </Modal>
        
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pengguna
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Ali Akbar"
                    required
                    defaultValue={this.state.dt.nama}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Username
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="masukan username"
                    required
                    defaultValue={this.state.dt.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Password{" "}
                  {this.state.statUbah === false
                    ? "( Silahkan di isi jika ubah password )"
                    : ""}
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="masukan Password"
                    required={this.state.statUbah}
                    defaultValue={this.state.dt.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Email
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="masukan email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No. HP
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="no_hp"
                    type="number"
                    placeholder="masukan no. hp"
                    required
                    defaultValue={this.state.dt.no_hp}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="masukan alamat"
                    required
                    defaultValue={this.state.dt.alamat}
                  />
                </Col>
              </FormGroup>
              
              {
                this.state.dt.uuid
                ?
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Status
                    </Label>
                    <Col sm="9">
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "status");
                        }}
                        defaultValue={this.state.statusAkun}
                        value={this.state.statusAkun}
                        name="role"
                        options={[
                          {
                            value: 'Tidak Aktif',
                            label: "Tidak Aktif",
                          },
                          {
                            value: 'Aktif',
                            label: "Aktif",
                          },
                        ]}
                        placeholder="Pilih Status"
                        isClearable
                        required
                      />
                    </Col>
                  </FormGroup>
                :
                  <></>
              }
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
