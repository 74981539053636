import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, GetWisata, PostWisata, ID_DESA, DeleteWisata } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';

import "../../assets/css/content-styles.css"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const frmDex = {
  uuid: null,
  nama_panduan: '',
  detail: '',
}
var HtmlToReactParser = require("html-to-react").Parser;

class PanduanWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnSubmit: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,

      judulForm: 'Tambah Panduan Wisata',

      foto_desa: null,
      uuid_desa_wisata: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      textCari: ''
    };
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true })

    let link = "";
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    if (this.state.textCari !== '') {
      link = link + '&q=' + this.state.textCari
    }

    GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
      if (res.results) {
        this.setState({
          uuid_desa_wisata: res.results.uuid
        });

        let uuid_desa_wisata = res.results.uuid

        let bodyData = {
          uuid_desa_wisata,
          q: this.state.textCari,
        }

        PostWisata("desa-wisata/panduan", null, bodyData, dtkat => {
          console.log(dtkat);
          // console.log(dtkat.data);

          if (dtkat.data) {
            this.setState({
              data: dtkat.data.results.data,
              pagination: dtkat.data.results,
              loading: false
            })
            // Pagination
            this.pagination(dtkat.data.results);
          }
          this.setState({ loading: false })

        });
      }
    });

  };

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  ubahData = data => {
    console.log(data);

    const dt = {
      uuid: data.uuid,
      nama_panduan: data.nama_panduan,
      detail: data.detail,
    }

    this.setState({
      dt,
      judulForm: "Ubah Panduan",
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  }

  hapusData = (uuid) => {
    this.setState({
      alert: null,
      loadingPreview: true
    })


    DeleteWisata('desa-wisata/panduan/delete', uuid, (res) => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: 'success',
          basicTitle: 'Data Panduan Wisata',
          pesanAlert: 'Berhasil hapus data'
        });
        this.fetch()
      } else {
        this.setState({
          show: true,
          basicType: 'danger',
          basicTitle: 'Data Panduan Wisata',
          pesanAlert: 'Gagal hapus data'
        });
      }
      this.setState({ alert: null })
      this.setState({ loadingPreview: false })
    })

  }

  cariData = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        textCari: e.target.value,
      });
      setTimeout(
        function () {
          this.fetch();
        }.bind(this),
        500
      );
    }
  };

  simpan = ((e) => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
      if (res.results) {
        this.setState({
          uuid_desa_wisata: res.results.uuid
        });

        let uuid_desa_wisata = res.results.uuid

        let bodyData = {
          uuid: document.getElementById("uuid").value,
          uuid_desa_wisata,
          nama_panduan: document.getElementById('nama_panduan').value,
          // detail: document.getElementById('detail').value,
          detail: this.state.dt.detail,
        }
        console.log(bodyData);

        let psn = "";
        let resstat = 204;
        let metode = "create";

        if (bodyData.uuid === "") {
          psn = "tambah";
          resstat = 201;
          bodyData.uuid = null;
        } else {
          psn = "ubah";
          resstat = 201;
          metode = "update";
        }

        PostWisata("desa-wisata/panduan/" + metode, bodyData.uuid, bodyData, res => {
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Berhasil " + psn + " Data",
              loadingPreview: false,
              status: {
                ...this.state.status,
                form: false
              }
            });
            // this.componentDidMount();
            this.fetch()
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Info " + JENIS_DESA,
              pesanAlert: "Gagal " + psn + " Data",
              loadingPreview: false,
            });
          }
        })

      }
    })

  })

  render() {
    var data = this.state.data
    var htmlToReactParser = new HtmlToReactParser();

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          // return index + 1

          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: 'nama_panduan',
        text: 'Judul',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'detail',
        text: 'Detail',
        headerAlign: 'center',
        // align: 'center',
        sort: false,
        formatter: function (cell, row) {
          // return row.detail.replace(/(<([^>]+)>)/gi, "")
          return <div className='ck-content'>
            {htmlToReactParser.parse(row.detail)}
          </div>
        },
      },
      {
        dataField: 'aksi',
        text: 'Aksi',
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: function (cell, row) {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                size="xs"
                color="success"
                className="btn-icon mr-2"
                onClick={() => this.ubahData(row)}
              >
                <i className="fa fa-pencil"></i>
              </Button>
              <Button
                size="xs"
                color="danger"
                className="btn-icon mr-2"
                onClick={() => {
                  this.setState({
                    alert: (
                      <ReactBootstrapSweetalert
                        showCancel
                        confirmBtnText="Hapus"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        type="danger"
                        title="Yakin ingin hapus data ?"
                        onCancel={() => {
                          this.setState({
                            alert: null
                          });
                        }}
                        onConfirm={() => this.hapusData(row.uuid)}
                      >
                        Proses ini tidak dapat dibatalkan!
                      </ReactBootstrapSweetalert>
                    )
                  });
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          )
        }.bind(this)
      }
    ]

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        <div className="row mt-4">
          {this.state.loading ? (
            <div className="col-sm-12" align="center">
              <img
                alt="loading"
                src={require("../../assets/images/tes-loading.gif")}
                style={{
                  borderRadius: "10px",
                  width: '100px'
                }} />
            </div>
          ) : (
            <>
              <div className="col-sm-5 text-right">
                <input
                  type="text"
                  className="form-control"
                  style={{ marginBottom: '15px' }}
                  id="cariTable"
                  placeholder="Cari berdasarkan judul atau detail panduan"
                  defaultValue={this.state.textCari}
                  onChange={(e) => {
                    this.setState({ textCari: e.target.value })
                  }}
                  onKeyPress={this.cariData}
                />
                <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                  <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                    this.setState({
                      textCari: '',
                      loading: true
                    });
                    setTimeout(
                      function () {
                        this.fetch();
                      }.bind(this),
                      500
                    );
                    document.getElementById('cariTable').value = ''
                  }} ></span>
                </div>
              </div>
              <div className="col-sm-2" style={{ marginBottom: '15px' }}>
                <Button
                  size="sm"
                  color="info"
                  className="btn-square"
                  onClick={() => {
                    this.setState({
                      textCari: document.getElementById("cariTable").value,
                      loading: true
                    });
                    setTimeout(
                      function () {
                        this.fetch();
                      }.bind(this),
                      500
                    );
                  }}
                >
                  <i className="fa fa-search"></i>
                </Button>
              </div>
              <div className="col-sm-5 text-right">
                <Button
                  size="sm"
                  color="success"
                  className="btn-square"
                  onClick={() => {
                    this.setState({
                      judulForm: 'Tambah Panduan Wisata',
                    });
                    this.bukaForm();
                  }}
                >
                  Tambah Data
                </Button>
              </div>
              <div className='col-sm-12'>
                <BootstrapTable
                  keyField="id"
                  data={data}
                  columns={columns}
                />
                <div className="pull-right text-white">
                  {this.state.awal}
                  {this.state.sebelum}
                  {this.state.hal.map(dt => {
                    return dt;
                  })}
                  {this.state.setelah}
                  {this.state.akhir}
                </div>
              </div>

            </>
          )}
        </div>

        {/* {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <Button
                          size="sm"
                          color="success"
                          className="btn-square"
                          onClick={() => {
                            this.setState({
                              judulForm: 'Tambah Panduan Wisata',
                              dt: '',
                            });
                            this.bukaForm();
                          }}
                        >
                          Tambah Data
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body datatable-react">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="row">
                          <div className="col-sm-12" align="center">
                            <img
                              alt="loading"
                              src={require("../../assets/images/tes-loading.gif")}
                              style={{
                                borderRadius: "10px",
                                width: '100px'
                              }} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-5">
                            <input
                              type="text"
                              className="form-control"
                              id="cariTable"
                              placeholder="Cari data berdasarkan judul panduan"
                              defaultValue={this.state.textCari}
                              onKeyPress={this.cariData}
                            />
                          </div>
                          <div className='col-sm-12'>
                            <BootstrapTable
                              keyField="id"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judulForm}</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

              <div className="form-row ">
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Judul Panduan</Label>
                  <input
                    className="form-control"
                    id="nama_panduan"
                    type="text"
                    placeholder="Contoh : Adat & Kebiasaan"
                    defaultValue={this.state.dt.nama_panduan}
                    minLength={3}
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          nama_panduan: e.target.value
                        }
                      })
                    }}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Deskripsi Detail</Label>
                  {/* <textarea className='form-control' name="detail" id="detail" cols="30" defaultValue={this.state.dt.detail} rows="4" placeholder='Contoh : 1. Upacara Adat' required></textarea> */}
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        dt: {
                          ...this.state.dt,
                          detail: data,
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    data={this.state.dt.detail}
                    config={{
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                    }}
                  />
                </Col>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnSubmit}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

PanduanWisata.propTypes = {

};

export default PanduanWisata;