import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, PostWisata, API_URL_WISATA, GetWisata, ID_DESA, DeleteWisata } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import "../../assets/css/content-styles.css"
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from 'axios';
import { DataToken } from '../../function/Format';
import { Link } from 'react-router-dom';

const frmDex = {
  uuid: null,
  judul: '',
  nama_penulis: '',
  tanggal: null,
  thumbnail: '',
  deskripsi: '',
}

const dateFormat = require('dateformat');
var HtmlToReactParser = require("html-to-react").Parser;

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new ArtikelWisata(loader);
  };
}

class ArtikelWisata extends Component {
  constructor(props) {
    super(props);
    this.loader = props
    this.state = {
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnSubmit: false,
        modalDetail: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,
      imagePreview: null,

      judulForm: 'Tambah Artikel Wisata',
      uuid_desa_wisata: null,

      content: '',
      ckimage_resp: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      textCari: ''
    };
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  // CKEDITOR 5
  upload() {
    return new Promise((resolve, reject) => {

      const data = new FormData();

      this.loader.file.then(result => {
        data.append('files[]', result);

        const url = process.env.REACT_APP_API_URL_WISATA;

        PostWisata('upload-file/multiple/desa-wisata', null, data, (res) => {
          console.log(res);

          if (res.data.results) {
            const xhr = res.data;
            const loader = this.loader;
            const genericErrorText = 'Couldn\'t upload file:' + ` ${loader.file.name}.`;

            const response = xhr.code;

            if (response == 200) {
              resolve({
                // default: xhr.results[0]
                default: API_URL_WISATA + xhr.results[0]
              });
            } else {
              reject()
            }
          }
        });

      })
    })
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();

      this.setState({
        ckimage_resp: { code: '406', message: 'Hapus' }
      });
    }
  }

  // END CKEDITOR 5

  fetch = (params = {}) => {
    this.setState({ loading: true })

    let link = "";
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    if (this.state.textCari !== '') {
      link = link + '&q=' + this.state.textCari
    }

    GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
      if (res.results) {
        this.setState({
          uuid_desa_wisata: res.results.uuid
        });

        let uuid_desa_wisata = res.results.uuid

        let bodyData = {
          uuid_desa_wisata,
          q: this.state.textCari,
        }

        PostWisata("desa-wisata/artikel", null, bodyData, dtkat => {
          console.log(dtkat);
          // console.log(dtkat.data);

          if (dtkat.data) {
            this.setState({
              data: dtkat.data.results.data,
              pagination: dtkat.data.results,
              loading: false
            })
            // Pagination
            this.pagination(dtkat.data.results);
          }
          this.setState({ loading: false })

        });
      }
    });

  };

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  ubahData = data => {
    console.log(data);

    const dt = {
      uuid: data.uuid,
      judul: data.judul,
      nama_penulis: data.nama_penulis,
      tanggal: data.tanggal,
      deskripsi: data.deskripsi,
      thumbnail: data.thumbnail,
    }

    let link_file = data.thumbnail

    this.setState({
      dt,
      link_file,
      content: dt.deskripsi,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  }

  hapusData = (uuid) => {
    this.setState({
      alert: null,
      loadingPreview: true
    })
    console.log(uuid);

    DeleteWisata('desa-wisata/artikel/delete', uuid, (res) => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: 'success',
          basicTitle: 'Data Artikel Wisata',
          pesanAlert: 'Berhasil hapus data'
        });
        this.fetch()
      } else {
        this.setState({
          show: true,
          basicType: 'danger',
          basicTitle: 'Data Artikel Wisata',
          pesanAlert: 'Gagal hapus data'
        });
      }
      this.setState({ alert: null })
      this.setState({ loadingPreview: false })
    })

  }

  cariData = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        textCari: e.target.value,
      });
      setTimeout(
        function () {
          this.fetch();
        }.bind(this),
        500
      );
    }
  };

  simpan = ((e) => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    let complete = false
    if (this.state.link_file !== '' && this.state.dt.deskripsi) {
      complete = true
    }

    if (complete) {
      GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
        console.log(res);
        if (res.results) {
          let token = DataToken(sessionStorage.getItem('access_token'));

          this.setState({
            uuid_desa_wisata: token.sub.uuid_desa_wisata
          });

          const uuid_pengguna = token.sub.uuid
          const uuid_desa_wisata = token.sub.uuid_desa_wisata
          let thumbnail = this.state.link_file

          // console.log(thumbnail);
          // console.log(this.state.dt.deskripsi);
          // console.log(dateFormat(new Date(), 'yyyy-mm-dd'));
          // console.log(document.getElementById('uuid').value);
          // console.log(document.getElementById('judul').value);
          // console.log(document.getElementById('nama_penulis').value);

          let bodyData = {
            uuid: document.getElementById("uuid").value,
            uuid_desa_wisata,
            uuid_pengguna,
            judul: document.getElementById('judul').value,
            nama_penulis: document.getElementById('nama_penulis').value,
            tanggal: dateFormat(new Date(), 'yyyy-mm-dd'),
            thumbnail,
            deskripsi: this.state.dt.deskripsi,
          }
          console.log(bodyData);

          let psn = "";
          let resstat = 204;
          let metode = "create";

          if (bodyData.uuid === "") {
            psn = "tambah";
            resstat = 201;
            bodyData.uuid = null;
          } else {
            psn = "ubah";
            resstat = 201;
            metode = "update";
          }

          PostWisata("desa-wisata/artikel/" + metode, bodyData.uuid, bodyData, res => {
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Berhasil " + psn + " Data",
                loadingPreview: false,
                status: {
                  ...this.state.status,
                  form: false
                }
              });
              this.fetch()
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Gagal " + psn + " Data",
                loadingPreview: false,
              });
            }
          })

        }
      })
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Artikel Wisata",
        pesanAlert: "Masukkan gambar thumbnail dan isi artikel",
        loadingPreview: false,
      });
    }

  })

  render() {
    var data = this.state.data
    var htmlToReactParser = new HtmlToReactParser();

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          // return index + 1

          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: 'tanggal',
        text: 'Tanggal',
        sort: true,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '200px'
        },
        formatter: (cell, obj) => {
          let plainTime = '-'

          if (obj.created_at) {

            let date = new Date(obj.created_at)
            date = date.toLocaleString('id-ID')

            const datetime = date.split(' ')

            date = datetime[0]
            let time = datetime[1]

            const splitDate = date.split('/')
            time = time.replaceAll('.', ':')

            const list_bulan = [
              'Januari',
              'Februari',
              'Maret',
              'April',
              'Mei',
              'Juni',
              'Juli',
              'Agustus',
              'September',
              'Oktober',
              'November',
              'Desember'
            ];

            plainTime = `${splitDate[0]} ${list_bulan[parseInt(splitDate[1]) - 1]} ${splitDate[2]}  ${time}`;
          }

          return plainTime
        }
      },
      {
        dataField: 'judul',
        text: 'Judul',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'nama_penulis',
        text: 'Penulis',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        formatter: function (cell, row) {
          return 'Admin Wisata'
        }
      },
      {
        dataField: 'thumbnail',
        text: 'Thumbnail Artikel',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        formatter: (cell, row, index) => {
          return <div className='d-flex justify-content-center'>
            <div className='parent-thumbnail-table'>
              <img
                src={API_URL_WISATA + row.thumbnail}
                alt={"Thumbnail " + (index + 1)}
                className='thumbnail-table-size'
                onClick={() => {
                  this.setState({ imagePreview: API_URL_WISATA + row.thumbnail })
                }}
              />
            </div>
          </div>
          // return <img
          //   src={API_URL_WISATA + row.thumbnail}
          //   alt={"Thumbnail " + (index + 1)}
          //   style={{
          //     height: '150px',
          //     width: '250px',
          //     objectFit: 'cover'
          //   }}
          // />
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'aksi',
        text: 'Aksi',
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: (cell, row) => {
          const getData = {
            uuid: row.uuid,
            judul: row.judul,
            status: row.status,
            nama_penulis: row.nama_penulis,
            tanggal: row.tanggal,
            deskripsi: row.deskripsi,
            thumbnail: row.thumbnail,
          }

          return (
            <div className='d-flex justify-content-center'>
              <Button
                size="xs"
                color="info"
                className="btn-icon mr-2"
                title="Detail Artikel"
                onClick={() => {
                  this.setState({
                    dt: row,
                    status: {
                      ...this.state.status,
                      modalDetail: true
                    }
                  })
                }}
              >
                {/* Next detail artikel mirip edit tp readonly */}
                <i className="fa fa-eye"></i>
              </Button>
              <Link to={{
                pathname: process.env.PUBLIC_URL + "/wisata/artikel/form",
                state: {
                  type: 'Ubah',
                  data: getData,
                }
              }}>
                <Button
                  size="xs"
                  color="success"
                  className="btn-icon mr-2"
                // onClick={() => this.ubahData(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              </Link>
              <Button
                size="xs"
                color="danger"
                className="btn-icon mr-2"
                onClick={() => {
                  this.setState({
                    alert: (
                      <ReactBootstrapSweetalert
                        showCancel
                        confirmBtnText="Hapus"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        type="danger"
                        title="Yakin ingin hapus data ?"
                        onCancel={() => {
                          this.setState({
                            alert: null
                          });
                        }}
                        onConfirm={() => this.hapusData(row.uuid)}
                      >
                        Proses ini tidak dapat dibatalkan!
                      </ReactBootstrapSweetalert>
                    )
                  });
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          )
        }
      }
    ]

    const thumbs = this.state.files.map(file => (
      <div style={{
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
      }} key={file.name}>
        <div style={{
          display: 'flex',
          minWidth: 0,
          overflow: 'hidden'
        }}>
          <img
            src={file.preview}
            style={{
              display: 'block',
              width: 'auto',
              height: '100%'
            }}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
        </div>
      </div>
    ));

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body datatable-react">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="col-sm-12" align="center">
                          <img
                            alt="loading"
                            src={require("../../assets/images/tes-loading.gif")}
                            style={{
                              borderRadius: "10px",
                              width: '100px'
                            }} />
                        </div>
                      ) : (
                        <>
                          <div className="col-sm-5" style={{ position: 'relative' }}>
                            <input
                              type="text"
                              className="form-control"
                              id="cariTable"
                              placeholder="Cari data berdasarkan judul artikel"
                              defaultValue={this.state.textCari}
                              onChange={(e) => {
                                this.setState({ textCari: e.target.value })
                              }}
                              onKeyPress={this.cariData}
                            />
                            <div className="clearBtnSearch2" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                              <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                this.setState({
                                  textCari: '',
                                  loading: true
                                });
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                                document.getElementById('cariTable').value = ''
                              }} ></span>
                            </div>
                          </div>
                          <div className="col-sm-2" style={{ marginBottom: '15px' }}>
                            <Button
                              size="sm"
                              color="info"
                              className="btn-square"
                              onClick={() => {
                                this.setState({
                                  textCari: document.getElementById("cariTable").value,
                                  loading: true
                                });
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                              }}
                            >
                              <i className="fa fa-search"></i>
                            </Button>
                          </div>
                          <div className="col-sm-5 text-right">
                            <Link to={{
                              pathname: process.env.PUBLIC_URL + "/wisata/artikel/form",
                              state: {
                                type: 'Tambah',
                                data: frmDex,
                              }
                            }}>
                              <Button
                                size="sm"
                                color="success"
                                className="btn-square"
                              // onClick={() => {
                              //   this.setState({
                              //     judul: 'Tambah Artikel Wisata',
                              //     dt: frmDex,
                              //     link_file: '',
                              //     files: [],
                              //     content: '',
                              //   });
                              //   this.bukaForm();
                              // }}
                              >
                                Tambah Data
                              </Button>
                            </Link>
                          </div>
                          <div className='col-sm-12'>
                            <BootstrapTable
                              keyField="id"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judulForm}</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

              {/* Pindahkan ini ke halaman baru */}
              {/* <div className="form-row">
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Judul Artikel</Label>
                  <input
                    className="form-control"
                    id="judul"
                    type="text"
                    placeholder="Contoh : Desa Sungai Petai meraih penghargaan sebagai Desa Wisata terbaik 2022"
                    defaultValue={this.state.dt.judul}
                    minLength={3}
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          judul: e.target.value
                        }
                      })
                    }}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Thumbnail Artikel</Label>
                  <Dropzone
                    onDrop={(files) => {
                      console.log(files);

                      if (files.length === 1) {
                        let type = files[0].type.split('/');

                        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {
                          let bodyFormData = new FormData();
                          bodyFormData.append('files', files[0]);

                          this.setState({ files })

                          PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                            console.log(data);

                            if (data.data.results) {
                              this.setState({
                                link_file: data.data.results
                              });
                            }
                          });

                        } else { // Jika bukan pdf
                          this.setState({
                            show: true,
                            basicType: 'warning',
                            basicTitle: 'Terjadi Kesalahan',
                            pesanAlert: 'Masukkan thumbnail dengan format gambar'
                          });
                        }
                      }
                    }}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            minHeight: '100px',
                            paddingTop: '40px',
                            padding: '5px 5px 5px 5px',
                            border: '1px solid #d2d2d2'
                          }}
                        >
                          <input {...getInputProps()} />
                          <div
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            {this.state.link_file ? (
                              <img
                                src={API_URL_WISATA + this.state.link_file}
                                alt="thumbnail artikel"
                                style={{
                                  // width: '470px',
                                  maxHeight: '300px',
                                  maxWidth: '500px'
                                }}
                              />
                            ) : (
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Isi Artikel</Label>
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        content: data,
                        dt: {
                          ...this.state.dt,
                          deskripsi: data
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    // data={this.state.content}
                    data={this.state.dt.deskripsi}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                    }}
                  />
                </Col>
              </div> */}

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Judul Artikel
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="judul"
                    type="text"
                    placeholder="Contoh : Desa Sungai Petai meraih penghargaan sebagai Desa Wisata terbaik 2022"
                    defaultValue={this.state.dt.judul}
                    minLength={3}
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          judul: e.target.value
                        }
                      })
                    }}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Nama Penulis
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_penulis"
                    type="text"
                    placeholder="Masukkan nama penulis artikel"
                    defaultValue={this.state.dt.nama_penulis}
                    minLength={3}
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          nama_penulis: e.target.value
                        }
                      })
                    }}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Thumbnail Artikel
                </Label>
                <Col sm="9">
                  <Dropzone
                    onDrop={(files) => {
                      console.log(files);

                      if (files.length === 1) {
                        let type = files[0].type.split('/');

                        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {
                          let bodyFormData = new FormData();
                          bodyFormData.append('files', files[0]);

                          this.setState({ files })

                          PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                            console.log(data);

                            if (data.data.results) {
                              this.setState({
                                link_file: data.data.results
                              });
                            }
                          });

                        } else { // Jika bukan pdf
                          this.setState({
                            show: true,
                            basicType: 'warning',
                            basicTitle: 'Terjadi Kesalahan',
                            pesanAlert: 'Masukkan thumbnail dengan format gambar'
                          });
                        }
                      }
                    }}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            minHeight: '100px',
                            paddingTop: '40px',
                            padding: '5px 5px 5px 5px',
                            border: '1px solid #d2d2d2'
                          }}
                        >
                          <input {...getInputProps()} />
                          <div
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            {this.state.link_file ? (
                              <img
                                src={API_URL_WISATA + this.state.link_file}
                                alt="thumbnail artikel"
                                style={{
                                  // width: '470px',
                                  maxHeight: '300px',
                                  maxWidth: '500px'
                                }}
                              />
                            ) : (
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Isi Artikel
                </Label>
                <Col sm="9">
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        content: data,
                        dt: {
                          ...this.state.dt,
                          deskripsi: data
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    // data={this.state.content}
                    data={this.state.dt.deskripsi}
                    config={{
                      extraPlugins: [MyCustomUploadAdapterPlugin],
                    }}
                  />
                </Col>
              </FormGroup>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnSubmit}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal Detail */}
        <Modal
          open={this.state.status.modalDetail}
          styles={{
            modal: {
              width: '90%',
              maxWidth: 'unset'
            }
          }}
          onClose={() => {
            let status = { ...this.state.status };
            status.modalDetail = false;
            this.setState({ status });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Detail Artikel Wisata</h5>
          </div>
          <div className="modal-body ck-content">
            <table>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Judul Artikel</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.judul}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Penulis</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.nama_penulis}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Pembuatan Artikel</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.tanggal}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Thumbnail</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>
                  {/* {API_URL_WISATA + this.state.dt.thumbnail} */}
                  <img
                    src={API_URL_WISATA + this.state.dt.thumbnail}
                    alt="thumbnail promo"
                    style={{
                      maxHeight: '150px',
                      maxWidth: '250px'
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Deskripsi</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td></td>
              </tr>
            </table>
            {htmlToReactParser.parse(this.state.dt.deskripsi)}

            {/* <div className="row">
              <div className="col-md-12">
                {this.state.modalDetail !== '' ? (
                  <img
                    alt="Dokumen"
                    src={this.state.modalDetail}
                    style={{ width: "100%" }}
                  />
                ) : (
                  <h6 style={{ textAlign: "center" }}>Tidak ada Foto</h6>
                )}
              </div>
            </div> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                let status = { ...this.state.status };
                status.modalDetail = false;
                this.setState({
                  status,
                  modalDetail: null,
                });
              }}
              style={{ fontWeight: 600 }}
            >
              Tutup
            </button>
          </div>
        </Modal>

        {/* Modal image preview */}
        <Modal
          open={this.state.imagePreview ? true : false}
          onClose={() => {
            this.setState({ imagePreview: null })
          }}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          styles={{ modal: { width: 'auto', maxWidth: 'unset' } }}
        >
          <div className="row">
            <div className="col-md-12 mt-4">
              <img src={this.state.imagePreview ? this.state.imagePreview : ''} alt="image-wisata-preview" style={{ width: '900px' }} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ArtikelWisata.propTypes = {

};

export default ArtikelWisata;