import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-responsive-modal";
import { Button, Form, FormGroup, Label, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import NumberFormat from 'react-number-format';
import Select from "react-select";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// koneksi
import { GetWisata, PostWisata, PatchWisata, DeleteWisata, API_URL_WISATA} from "../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import classnames from 'classnames';
import { DataToken, formatRupiah, HariTanggalJam, numberSeparator, Tanggal } from '../../function/Format';
import ReactDatetimeClass from 'react-datetime';

const frmPaket = {
  uuid: "",
  uuid_wisata: '',
  nama: "",
  deskripsi_singkat: '',
  foto: null,
  harga: '',
  orang: '',
  durasi_wisata: '',
  fasilitas: '',
  periode_booking: '',
};

const frmPengelola = {
  uuid: "",
  uuid_wisata: '',
  nama: "",
  jabatan: '',
  no_hp: '',
  alamat: '',
  status: null,
};

// const frmFasilitas = {
//   uuid_wisata: '',
//   deskripsi: "",
// };

const frmGaleri = {
  uuid: '',
  uuid_wisata: '',
  nama: "",
  link: "",
};

const frmTrafik = {
  uuid: "",
  uuid_wisata: '',
  tanggal_kunjungan: null,
  jumlah_orang: '',
};

const frmBooking = {
  uuid: "",
  status: '',
};

const frmBookingOffline = {
  uuid: "",
  uuid_desa_wisata: "",
  uuid_wisata: "",
  tanggal_kunjungan: new Date(),
  data_paket: [],
};

const dateFormat = require('dateformat');
var HtmlToReactParser = require("html-to-react").Parser;

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        form_pengelola: false,
        form_galeri: false,
        form_trafik: false,
        form_booking: false,
        statGet: false,
        formDetailBooking:false,
        formDetail:false
      },
      // Set Data
      data: [],
      data2: null,
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      dataFoto:{
        link:'',
      },
      loadingFoto: false,
      // Pagination
      pagination: [],
      pagination2: [],
      pagination3: [],
      pagination4: [],
      pagination5: [],
      // Paket
			awal: "",
			sebelum: "",
			hal: [],
			setelah: "",
			akhir: "",
			// Fasilitas
			awal2: "",
			sebelum2: "",
			hal2: [],
			setelah2: "",
			akhir2: "",
			// Galeri
			awal3: "",
			sebelum3: "",
			hal3: [],
			setelah3: "",
			akhir3: "",
			// Petugas
			awal4: "",
			sebelum4: "",
			hal4: [],
			setelah4: "",
			akhir4: "",
			// Trafik
			awal5: "",
			sebelum5: "",
			hal5: [],
			setelah5: "",
			akhir5: "",
			// Booking
			awal6: "",
			sebelum6: "",
			hal6: [],
			setelah6: "",
			akhir6: "",

      statUbah: false,
      dt: frmPaket,
      dt_pengelola: frmPengelola,
      // dt_fasilitas: frmFasilitas,
      dt_galeri: frmGaleri,
      dt_trafik: frmTrafik,
      dt_booking: frmBooking,
      dt_booking_offline: frmBookingOffline,
      detailBooking: null,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      listPaket: [],
      listOptionPaket: [],
      paketSelected: null,
      qtyBookingOffline: 1,
      keranjangBookingOffline: [],
      // Get Data Parent
      dataWisata:this.props.location.state,
      // Galeri
      detail_preview: false,
			detail_data: [],
      // Tabulasi
      active_tab_icon: "999",
			cek_posisi:'Info',
      statusPengelola: null,
    };
  }

  // Toggle Action
  
	toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			if(tab === '1'){
				this.state.cek_posisi = 'Pertama'
			}
			else if(tab === '2'){
				this.state.cek_posisi = 'Kedua'
			}
			else if(tab === '3'){
				this.state.cek_posisi = 'Ketiga'
			}
			else if(tab === '4'){
				this.state.cek_posisi = 'Keempat'
			}
			else if(tab === '5'){
				this.state.cek_posisi = 'Kelima'
			}
			else if(tab === '6'){
				this.state.cek_posisi = 'Keenam'
			}
			else if(tab === '999'){
				this.state.cek_posisi = 'Info'
			}
		
			this.setState({
				active_tab_icon: tab,
				loading: true,
        textCari: '',
			});
		}
		
		this.forceUpdate();
		this.componentDidMount();
	}
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Paket":
              link =
                "?page=" +
                Math.ceil(
                this.state.pagination.total / this.state.pagination.per_page
                );
              break;
      
            // case "Fasilitas":
            //   link =
            //     "?page=" +
            //     Math.ceil(
            //     this.state.pagination2.total /
            //     this.state.pagination2.per_page
            //     );
            //   break;
      
            case "Galeri":
              link =
                "?page=" +
                Math.ceil(
                this.state.pagination3.total /
                this.state.pagination3.per_page
                );
              break;
      
            case "Pengelola":
              link =
                "?page=" +
                Math.ceil(
                this.state.pagination4.total /
                this.state.pagination4.per_page
                );
              break;
      
            case "Trafik":
              link = 
                "?page=" +
                Math.ceil(
                this.state.pagination5.total /
                this.state.pagination5.per_page
                );
              break;
      
            case "Booking":
              link = 
                "?page=" +
                Math.ceil(
                this.state.pagination6.total /
                this.state.pagination6.per_page
                );
              break;
            default:
            // no default
            break;
          }
        case ">":
          switch (params.tab) {
            case "Paket":
              link =
              "?page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;
      
            // case "Fasilitas":
            //   link =
            //   "?page=" +
            //   (parseInt(this.state.pagination2.current_page) +
            //   1);
            //   break;
    
            case "Galeri":
              link =
              "?page=" +
              (parseInt(this.state.pagination3.current_page) + 1);
              break;
    
            case "Pengelola":
              link =
              "?page=" +
              (parseInt(this.state.pagination4.current_page) + 1);
              break;
    
            case "Trafik":
              link =
              "?page=" +
              (parseInt(this.state.pagination5.current_page) + 1);
              break;
    
            case "Booking":
              link =
              "?page=" +
              (parseInt(this.state.pagination6.current_page) + 1);
              break;
            default:
            // no default
            break;
      
          }
        case "<":
          switch (params.tab) {
            case "Paket":
              link =
              "?page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;
      
            // case "Fasilitas":
            //   link =
            //   "?page=" +
            //   (parseInt(this.state.pagination2.current_page) -
            //   1);
            //   break;
      
            case "Galeri":
              link =
                "?page=" +
                (parseInt(this.state.pagination3.current_page) - 1);
              break;
      
            case "Pengelola":
              link =
                "?page=" +
                (parseInt(this.state.pagination4.current_page) - 1);
              break;
      
            case "Trafik":
              link =
                "?page=" +
                (parseInt(this.state.pagination5.current_page) - 1);
              break;
      
            case "Booking":
              link =
                "?page=" +
                (parseInt(this.state.pagination6.current_page) - 1);
              break;
            default:
            // no default
            break;
          }
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    // Get data from db
    let domain = ''
		if(this.state.cek_posisi === "Pertama"){
			params.tab = 0
      domain = 'paket'
		}
		// else if(this.state.cek_posisi === "Kedua"){
    //   params.tab = 1
    //   domain = 'fasilitas'
		// }
		else if(this.state.cek_posisi === "Ketiga"){
      params.tab = 2
      domain = 'galeri'
		}
		else if(this.state.cek_posisi === "Keempat"){
      params.tab = 3
      domain = 'pengelola'
		}
		else if(this.state.cek_posisi === "Kelima"){
      params.tab = 4
      domain = 'booking-wisata/trafik'
		}
		else if(this.state.cek_posisi === "Keenam"){
      params.tab = 5
      domain = 'booking-wisata'
		}

    if(this.state.cek_posisi !== "Info"){
      if(params.tab === 1){
        GetWisata("wisata/"+domain+'/'+this.state.dataWisata.uuid, null, dtkat => {
          // console.log('data show')
          // console.log(dtkat)
          if(dtkat.results){
            this.setState({
              data2:dtkat.results,
              loading: false,
            })
          }
          else{
            this.setState({
              loading: false,
            })
          }
        });
      }
      else{
        PostWisata("wisata/"+domain + link, null, {
          uuid_wisata: this.state.dataWisata.uuid,
          q: this.state.textCari
        }, dtkat => {
          // console.log('data show')
          // console.log(dtkat)
          switch (params.tab) {
            case 0:
              this.setState({
                data: dtkat.data.results.data,
                pagination: dtkat.data.results,
                loading: false,
              });
              this.forceUpdate();
              break;
        
            case 1:
              this.setState({
                // data2: dtkat.data.results.data,
                // pagination2: dtkat.data.results,
                loading: false,
              });
              this.forceUpdate();
              break;
      
            case 2:
              this.setState({
                data3: dtkat.data.results.data,
                pagination3: dtkat.data.results,
                loading: false,
              });
              this.forceUpdate();
              break;
      
            case 3:
              this.setState({
                data4: dtkat.data.results.data,
                pagination4: dtkat.data.results,
                loading: false,
              });
              this.forceUpdate();
              break;
      
            case 4:
              if (dtkat.data && dtkat.data.results) {
                this.setState({
                  data5: dtkat.data.results.data,
                  pagination5: dtkat.data.results,
                  loading: false,
                });
                this.forceUpdate();
              }
              break;
      
            case 5:
              if (dtkat.data && dtkat.data.results) {
                this.setState({
                  data6: dtkat.data.results.data,
                  pagination6: dtkat.data.results,
                  loading: false,
                });
                this.forceUpdate();

                // Get list all paket
                GetWisata("wisata/paket/list/" + this.state.dataWisata.uuid, null, resListPaket => {
                  console.log(resListPaket);
                  if (resListPaket.results) {
                    let listOptionPaket = [];

                    resListPaket.results.map((value, index) => {
                      listOptionPaket.push({ label: value.nama, value: value.uuid })
                    })

                    this.setState({
                      listPaket: resListPaket.results,
                      listOptionPaket,
                    })
                    
                  }
                })
              }
              break;
            default:
              // no default
              break;
          }
          this.pagination(dtkat.data.results, params.tab);
        });
      }
    }
    else{
      if(this.state.dataWisata){
        this.setState({
          loading: false,
        });
      }
    }

  };

  // Pagination
  pagination = (data, tab) => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
		  let start = 1;
		  let end = 5;
		  let n = 0;
		  let p = 0;
		  if (data.current_page <= 3) {
			start = 1;
			end = 5;
			if (data.last_page > data.current_page) {
			  n = data.current_page + 1;
			}
		  } else {
			p = data.current_page - 1;
			n = data.current_page + 1;
			start = data.current_page - 2;
			end = data.current_page + 2;
		  }
		  if (end >= data.last_page - 2) {
			p = data.current_page - 1;
			if (start >= 5) {
			  start = data.last_page - 4;
			}
			end = data.last_page;
		  }
	
		  for (let i = start; i <= end; i++) {
			let warna = "primary-custom";
			if (i === data.current_page) {
			  warna = "danger";
			}
			hal.push(
			  <a
				href={() => false}
				key={i}
				onClick={() => this.fetch({ page: i, tab: tab })}
				className={
				  "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				}
			  >
				{i}
			  </a>
			);
		  }
		  if (p > 0) {
			sebelum = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: p, tab: tab })}
				className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{"<"}
			  </a>
			);
		  }
		  if (n > 0) {
			setelah = (
			  <a
				href={() => false}
				onClick={() => this.fetch({ page: n, tab: tab })}
				className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			  >
				{">"}
			  </a>
			);
		  }
	
		  awal = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: 1, tab: tab })}
			  className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {"<<"}
			</a>
		  );
		  akhir = (
			<a
			  href={() => false}
			  onClick={() => this.fetch({ page: data.last_page, tab: tab })}
			  className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
			>
			  {">>"}
			</a>
		  );
		}
	
		switch (tab) {
		  case 0:
			this.setState({ awal, sebelum, hal, setelah, akhir });
			break;
		  case 1:
			this.setState({
			  awal2: awal,
			  sebelum2: sebelum,
			  hal2: hal,
			  setelah2: setelah,
			  akhir2: akhir
			});
			break;
		  case 2:
			this.setState({
			  awal3: awal,
			  sebelum3: sebelum,
			  hal3: hal,
			  setelah3: setelah,
			  akhir3: akhir
			});
		  case 3:
			this.setState({
			  awal4: awal,
			  sebelum4: sebelum,
			  hal4: hal,
			  setelah4: setelah,
			  akhir4: akhir
			});
			break;
		  case 4:
			this.setState({
			  awal5: awal,
			  sebelum5: sebelum,
			  hal5: hal,
			  setelah5: setelah,
			  akhir5: akhir
			});
			break;
		  case 5:
			this.setState({
			  awal6: awal,
			  sebelum6: sebelum,
			  hal6: hal,
			  setelah6: setelah,
			  akhir6: akhir
			});
			break;

		  default:
			// no default
			break;
		}
	};
  
  // Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

  // ========================================================================

  /// upload file Foto

	processGbr = (d, jenis) => {
    if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
       
        if(jenis === 'paket'){
          this.setState({
            loadingFoto: true,
            status:{
              ...this.state.status,
              btnForm: true,
            },
          })
        }
        
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				PostWisata('upload-file/wisata', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
              if(jenis === 'paket'){
                this.setState({
                  foto_desa: uri,
                  foto_info_foto: '',
                  dataFoto: {
                    ...this.state.dataFoto,
                    link: data.data.results,
                  },
                  status:{
                    ...this.state.status,
                    btnForm: false,
                  },
                  loadingFoto: false,
                });
              }
						},
						'base64'
					);
				});
			} else {
        if(jenis === 'paket'){
          this.setState({
            foto_desa: '',
            foto_info_foto: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
			}
		} else {
      if(jenis === 'paket'){
        this.setState({
          foto_desa: '',
          foto_info_foto: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
		}
	};

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
  
    if (
      this.state.dataFoto.link !== "" &&
      this.state.dt.deskripsi_singkat !== "" &&
      this.state.dt.fasilitas !== ""
     ) {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let add = {
        uuid: this.state.dt.uuid,
        uuid_wisata: this.state.dataWisata.uuid,
        nama: document.getElementById("nama").value,
        // deskripsi_singkat: document.getElementById("deskripsi_singkat").value,
        deskripsi_singkat: this.state.dt.deskripsi_singkat,
        foto: this.state.dataFoto.link,
        harga: document.getElementById('harga').value.replace(/[^\d]/g, ''),
        orang: document.getElementById("orang").value,
        durasi_wisata: document.getElementById("durasi_wisata").value,
        // fasilitas: document.getElementById("fasilitas").value,
        fasilitas: this.state.dt.fasilitas,
        periode_booking: document.getElementById("periode_booking").value,
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 201;
        metode = "update";
      }
      
      PostWisata("wisata/paket/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Paket Wisata",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Paket Wisata",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      let message = ''
      if(this.state.dt.fasilitas === ''){
        message = message+'Fasilitas, ';
      }
      if(this.state.dt.deskripsi_singkat === ''){
        message = message+'Deskripsi, ';
      }
      if(this.state.dataFoto.link === ''){
        message = message+'Foto, ';
      }
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Penambahan Paket Wisata",
        pesanAlert: "Data "+message+"tidak boleh kosong"
      });
    }
  };

  // simpanFasilitas = e => {
  //   e.preventDefault();
  
  //   this.state.status.btnForm = true;
  //   this.forceUpdate();

  //   let add = {
  //     uuid_wisata: this.state.dataWisata.uuid,
  //     deskripsi: document.getElementById("deskripsi").value,
  //   };

  //   let psn = "";
  //   let resstat = 201;
  //   let metode = "update";
  //   if(this.state.data2){
  //     psn = "Ubah";
  //     // resstat = 201;
  //     // metode = "update";
  //   }
  //   else{
  //     psn = "Tambah";
  //     // resstat = 201;
  //   }

  //   PatchWisata("wisata/fasilitas/" + metode, null, add, res => {
  //     this.state.status.btnForm = false;
  //     this.tutupForm();
  //     this.forceUpdate();
  //     if (res.status === resstat) {
  //       this.setState({
  //         show: true,
  //         basicType: "success",
  //         basicTitle: "Data Fasilitas Wisata",
  //         pesanAlert: "Berhasil " + psn + " Data"
  //       });
  //     } else {
  //       this.setState({
  //         show: true,
  //         basicType: "danger",
  //         basicTitle: "Data Fasilitas Wisata",
  //         pesanAlert: "Gagal " + psn + " Data"
  //       });
  //     }
  //     this.componentDidMount();
  //   });
  // };

  simpanGaleri = e => {
    e.preventDefault();
  
    if (
      this.state.dataFoto.link != ""
     ) {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let add = {
        uuid: this.state.dt_galeri.uuid,
        uuid_wisata: this.state.dataWisata.uuid,
        nama: document.getElementById("nama").value,
        link: this.state.dataFoto.link,
        jenis: 'Foto',
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 201;
        metode = "update";
      }

      PostWisata("wisata/galeri/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.tutupForm();
        this.forceUpdate();
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Galeri Wisata",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Galeri Wisata",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Penambahan Galeri Wisata",
        pesanAlert: "Data Foto tidak boleh kosong "
      });
    }
  };

  simpanPengelola = e => {
    e.preventDefault();
  
    this.state.status.btnForm = true;
    this.forceUpdate();

    let add = {
      uuid: this.state.dt_pengelola.uuid,
      uuid_wisata: this.state.dataWisata.uuid,
      nama: document.getElementById("nama").value,
      jabatan: document.getElementById("jabatan").value,
      no_hp: document.getElementById("no_hp").value,
      alamat: document.getElementById("alamat").value,
    };

    let psn = "";
    let resstat = 204;
    let metode = "create";
    let status_cek = true;
    if (add.uuid === "") {
      psn = "Tambah";
      resstat = 201;
      add.uuid = null;
      add = {
        ...add,
        status: 'Aktif',
      };
    } else {
      psn = "Ubah";
      resstat = 201;
      metode = "update";
      if(this.state.statusPengelola){
        add = {
          ...add,
          status: this.state.statusPengelola.value,
        };
      }
      else{
        status_cek = false;
      }
    }

    if(status_cek){
      PostWisata("wisata/pengelola/" + metode, add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.tutupForm();
        this.forceUpdate();
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengelola Wisata",
            pesanAlert: "Berhasil " + psn + " Data"
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengelola Wisata",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    }
    else{
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pengelola Wisata",
        pesanAlert: "Data tidak boleh kosong",
        status:{
          ...this.state.status,
          btnForm: false,
        }
      });
    }
  };

  simpanBookingOffline = e => {
    // e.preventDefault();

    // Jika seluruh data form telah diisi
    if (this.state.dt_booking_offline && this.state.dt_booking_offline.tanggal_kunjungan && this.state.keranjangBookingOffline.length > 0) {

      let data_paket = []

      this.state.keranjangBookingOffline.map((val, index) => {
        const getDetailPaket = this.state.listPaket.filter((valPaket) => {
          return valPaket.uuid == val.paket.value
        })

        if (getDetailPaket[0]) {
          data_paket.push({
            uuid_paket: val.paket.value,
            qty: val.qty,
            jumlah_orang: getDetailPaket[0].orang * val.qty,
            harga: getDetailPaket[0].harga,
            total: getDetailPaket[0].harga * val.qty,
          })
        }

      })

      let bodyData = {
        uuid: this.state.dt_booking_offline.uuid,
        uuid_wisata: this.state.dataWisata.uuid,
        uuid_desa_wisata: this.state.dataWisata.uuid_desa_wisata,
        tanggal_kunjungan: dateFormat(this.state.dt_booking_offline.tanggal_kunjungan, 'yyyy-mm-dd'),
        data_paket,
      }

      // Disable submit button
      this.setState({
        status: {
          ...this.state.status,
          btnForm: true,
        }
      })

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (bodyData.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        bodyData.uuid = null;

        bodyData = {
          ...bodyData,
          uuid: null,
        }
      } else {
        psn = "Ubah";
        resstat = 201;
        metode = "update";
      }

      PostWisata("wisata/booking-wisata/" + metode, bodyData.uuid, bodyData, res => {
        this.tutupForm();
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Booking Wisata",
            pesanAlert: "Berhasil " + psn + " Data",
            status: {
              ...this.state.status,
              btnForm: false
            }
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Booking Wisata",
            pesanAlert: "Gagal " + psn + " Data",
            status: {
              ...this.state.status,
              btnForm: false
            }
          });
        }
        // this.componentDidMount();
        this.fetch();
      });

    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Pengunjung Wisata",
        pesanAlert: "Isi seluruh data dengan benar"
      });
    }

  }

  simpanBooking = e => {
    // e.preventDefault()

    if (this.state.dt_booking && this.state.dt_booking.status) {
      // Disable submit button
      this.setState({
        status: {
          ...this.state.status,
          btnForm: true,
        }
      })

      let bodyData = {
        uuid: this.state.dt_booking.uuid,
        status: this.state.dt_booking.status,
      }

      let resstat = 201;
      let metode = "update";      

      PostWisata("wisata/booking-wisata/" + metode, bodyData.uuid, bodyData, res => {
        this.tutupForm();
        
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Status Booking Wisata",
            pesanAlert: "Berhasil update data",
            status: {
              ...this.state.status,
              btnForm: false
            }
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Status Booking Wisata",
            pesanAlert: "Gagal update data",
            status: {
              ...this.state.status,
              btnForm: false
            }
          });
        }
        this.fetch();
      });

    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Status Booking",
        pesanAlert: "Isi seluruh data dengan benar"
      });
    }
  }
  // ========================================================================
  
  // ============================== Ubah Data ===============================

  ubahData = (id, jenis) => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    let link_sub = 'show';

    if(jenis === 'galeri'){
      link_sub = 'find';
    }
    
    GetWisata("wisata/"+jenis+"/"+link_sub, id, data => {
      if(jenis ==='paket'){
        this.setState({
          dt: {
            uuid: data.results.uuid,
            nama: data.results.nama,
            deskripsi_singkat: data.results.deskripsi_singkat,
            foto: data.results.foto,
            harga: data.results.harga,
            orang: data.results.orang,
            durasi_wisata: data.results.durasi_wisata,
            fasilitas: data.results.fasilitas,
            periode_booking: data.results.periode_booking,
          },
          dataFoto:{
            link:data.results.foto,
          },
          status:{
            ...this.state.status,
            form:true,
            btnAksi: false,
          }
        });
      }
      else if(jenis ==='galeri'){
        this.setState({
          dt_galeri: {
            uuid: data.results.uuid,
            nama: data.results.nama,
          },
          dataFoto:{
            link:data.results.link,
          },
          status:{
            ...this.state.status,
            form_galeri:true,
            btnAksi: false,
          }
        });
      }
      else if(jenis ==='pengelola'){
        this.setState({
          dt_pengelola: {
            uuid: data.results.uuid,
            nama: data.results.nama,
            jabatan: data.results.jabatan,
            no_hp: data.results.no_hp,
            alamat: data.results.alamat,
            status: data.results.status,
          },
          statusPengelola: {
            value: data.results.status,
            label: data.results.status,
          },
          status:{
            ...this.state.status,
            form_pengelola:true,
            btnAksi: false,
          }
        });
      }
      this.forceUpdate();
    });
  };

  ubahDataTrafik = (data) => {

    const dt_trafik = {
      uuid: data.uuid,
      uuid_desa_wisata: data.uuid_desa_wisata,
      tanggal_kunjungan: new Date(data.tanggal_kunjungan),
      jumlah_orang: data.jumlah_orang,
    }

    this.setState({
      dt_trafik,
      status: {
        ...this.state.status,
        form_trafik: true,
      },
    })
    this.forceUpdate();

  }

  ubahStatusBooking = (data) => {
    const dt_booking = {
      uuid: data.uuid,
      status: data.status,
    }

    this.setState({
      dt_booking,
      status: {
        ...this.state.status,
        form_booking: true,
      },
    })
  }
  
  // ============================== Detail Data ===============================

  detailData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    GetWisata("wisata/paket/show", id, data => {
      this.setState({
        dt: {
          uuid: data.results.uuid,
          nama: data.results.nama,
          deskripsi_singkat: data.results.deskripsi_singkat,
          foto: data.results.foto,
          harga: data.results.harga,
          orang: data.results.orang,
          durasi_wisata: data.results.durasi_wisata,
          fasilitas: data.results.fasilitas,
          periode_booking: data.results.periode_booking,
        },
        dataFoto:{
          link:data.results.foto,
        },
        status:{
          ...this.state.status,
          formDetail: true,
        }
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
    });
  };

  getDetailBooking = id => {
    // this.state.status.btnAksi = true;
    // this.forceUpdate();

    this.setState({
      status: {
        ...this.state.status,
        btnAksi: true
      }
    })

    GetWisata("wisata/booking-wisata/show", id, data => {
      if (data.results) {
        this.setState({
          detailBooking: data.results,
          status:{
            ...this.state.status,
            formDetailBooking: true,
          }
        });
      }

      this.setState({
        status: {
          ...this.state.status,
          btnAksi: false
        }
      })
      
    });
  };
  
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = (id,jenis) => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id,jenis)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = (id,jenis) => {
    this.hideAlert();
    let psn = "Hapus";
    DeleteWisata("wisata/"+jenis+"/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data "+jenis[0].toUpperCase() + jenis.substring(1)+" Wisata",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data "+jenis[0].toUpperCase() + jenis.substring(1)+" Wisata",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dataFoto:{
        link:'',
      },
      status:{
        form:false,
        form_pengelola:false,
        // form_fasilitas:false,
        form_galeri: false,
        form_trafik: false,
        form_booking: false,
        formDetail:false,
        formDetailBooking:false,
      },
      dt: frmPaket,
      dt_pengelola: frmPengelola,
      dt_galeri: frmGaleri,
      dt_trafik: frmTrafik,
    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {

    return (
      <>
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Detail Wisata",
              statUbah: false
            });
            this.detailData(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-eye"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Wisata",
              statUbah: false
            });
            this.ubahData(row.uuid,'paket');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Paket",
              statUbah: false
            });
            this.konfirmHapus(row.uuid,'paket');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  tombolAksiGaleri = (cell, row) => {

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Galeri",
              statUbah: false
            });
            this.ubahData(row.uuid,'galeri');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Galeri",
              statUbah: false
            });
            this.konfirmHapus(row.uuid,'galeri');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
      </>
    );
  };

  imagePreview = (cell, row) => {

    return (
      <>
        <img 
          src={API_URL_WISATA+row.link} 
          alt="" 
          style={{height: "150px", width:'250px', objectFit:'cover', border: '1px solid black'}}
          onClick={() => {
            this.setState({
              detail_preview: true,
              detail_data: {
                title: 'Preview Galeri '+ row.nama,
                link: row.link
              },
            });
          }}
        />
        {/* <br/><br/>
        <Button
          size="sm"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              detail_preview: true,
              detail_data: {
                title: 'Preview Galeri '+ row.nama,
                link: row.link
              },
            });
          }}
        >
          <i className="fa fa-eye"></i> Lihat Galeri
        </Button>{" "} */}
      </>
    );
  };

  tombolAksiPengelola = (cell, row) => {

    return (
      <>
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Wisata",
              statUbah: false
            });
            this.ubahData(row.uuid,'pengelola');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Pengelola",
              statUbah: false
            });
            this.konfirmHapus(row.uuid,'pengelola');
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: e });
          break;

        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: null });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  
  componentDidMount() {
    let tabnya = ""
		if(this.state.cek_posisi==="Pertama"){
			tabnya="Paket"
			this.setState({
				active_tab_icon: '1',
			})
		}
		// else if(this.state.cek_posisi==="Kedua"){
		// 	tabnya="Fasilitas"
		// 	this.setState({
		// 		active_tab_icon: '2',
		// 	})
		// }

		else if(this.state.cek_posisi==="Ketiga"){
			tabnya="Galeri"
			this.setState({
				active_tab_icon: '3',
			})
		}
		
		else if(this.state.cek_posisi==="Keempat"){
			tabnya="Pengelola"
			this.setState({
				active_tab_icon: '4',
			})
		}
		
		this.fetch({ page: null, where: [], tab: tabnya });
  }

  
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status":
          this.setState({ statusPengelola: e });
          break;

        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "status":
          this.setState({ statusPengelola: null });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };

  render() {
    var data = this.state.data;
    var data3 = this.state.data3;
    var data4 = this.state.data4;
    var data5 = this.state.data5;
    var data6 = this.state.data6;

		const styleImgIcon = {
			height: 'auto',
			width: '300px'
		};

    const frmPaket = {
      uuid: "",
      uuid_wisata: '',
      nama: "",
      deskripsi_singkat: '',
      foto: null,
      harga: '',
      orang: '',
      durasi_wisata: '',
      fasilitas: '',
      periode_booking: '',
    };

    const frmPengelola = {
      uuid: "",
      uuid_wisata: '',
      nama: "",
      jabatan: '',
      no_hp: '',
      alamat: '',
      status: null,
    };
    
    const frmGaleri = {
      uuid: '',
      uuid_wisata: '',
      nama: "",
      link: "",
    };
    
    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "nama",
        text: "Nama Paket",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "harga",
        text: "Harga",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: function (cell, row) {
					return "Rp. " + numberSeparator(row.harga);
				}
      },
      {
        dataField: "orang",
        text: "Jumlah Orang",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "durasi_wisata",
        text: "Durasi Wisata",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: this.tombolAksi
      }
    ];
    
    const columnsGaleri = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination3.current_page;
          let total_records_per_page = this.state.pagination3.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "nama",
        text: "Nama Galeri",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      // {
      //   dataField: "jenis",
      //   text: "Jenis",
      //   headerAlign: 'center',
      //   align: 'center',
      //   sort: true
      // },
      {
        dataField: "aksi",
        text: "Preview",
        headerAlign: 'center',
        align: 'center',
        formatter: this.imagePreview
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: this.tombolAksiGaleri
      }
    ];
    
    const columnsPengelola = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination4.current_page;
          let total_records_per_page = this.state.pagination4.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "nama",
        text: "Nama Pengelola",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "jabatan",
        text: "Jabatan",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "no_hp",
        text: "No. Handphone",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "alamat",
        text: "Alamat",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "status",
        text: "Status",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: this.tombolAksiPengelola
      }
    ];
    
    const columnsTrafik = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination5.current_page;
          let total_records_per_page = this.state.pagination5.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "tanggal_kunjungan",
        text: "Tanggal Kunjungan",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (key, obj, index) => {
          if (obj.tanggal_kunjungan) {
            return Tanggal(obj.tanggal_kunjungan)
          }
          return ''
        }
      },
      {
        dataField: "total",
        text: "Jumlah Kunjungan",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (key, obj, index) => {
          return numberSeparator(obj.total)
        },
      },
      // {
      //   dataField: "aksi",
      //   text: "Aksi",
      //   headerAlign: 'center',
      //   align: 'center',
      //   formatter: (key, obj, index) => {
      //     return (
      //       <div className="d-flex justify-content-center">
      //         <Button
      //           size="xs"
      //           color="success"
      //           className="btn-icon"
      //           onClick={() => {
      //             this.setState({
      //               judul: "Ubah Trafik Wisata",
      //               statUbah: false
      //             });
      //             this.ubahDataTrafik(obj);
      //           }}
      //           disabled={this.state.status.btnAksi}
      //         >
      //           <i className="fa fa-pencil"></i>
      //         </Button>
      
      //         <Button
      //           size="xs"
      //           color="danger"
      //           className="btn-icon"
      //           onClick={() => {
      //             this.setState({
      //               judul: "Hapus Data Trafik",
      //               statUbah: false
      //             });
      //             this.konfirmHapusTrafik(obj.uuid);
      //           }}
      //           disabled={this.state.status.btnAksi}
      //         >
      //           <i className="fa fa-trash"></i>
      //         </Button>{" "}
             
      //       </div>
      //     );
      //   }
      // }
    ];
    
    const columnsBooking = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination6.current_page;
          let total_records_per_page = this.state.pagination6.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "tanggal_kunjungan",
        text: "Tanggal Kunjungan",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (key, obj, index) => {
          if (obj.tanggal_kunjungan) {
            return Tanggal(obj.tanggal_kunjungan)
          }
          return ''
        }
      },
      {
        dataField: "kode_booking",
        text: "Kode Booking",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (key, obj, index) => {
          if (obj.data_booking && obj.data_booking.kode_booking) {
            return obj.data_booking.kode_booking
          }
          return '-'
        }
      },
      {
        dataField: "data_booking.jenis",
        text: "Jenis",
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: "jumlah_orang",
        text: "Jumlah Pengunjung",
        headerAlign: 'center',
        align: 'center',
        sort: true,
        formatter: (key, obj, index) => {
          return numberSeparator(obj.jumlah_orang)
        }
      },
      {
        dataField: "data_booking.status",
        text: "Status Booking",
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: "status",
        text: "Status Check In",
        headerAlign: 'center',
        align: 'center',
        sort: true,
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: (key, obj, index) => {
          return (
            <div className="d-flex justify-content-center">
              <Button
                size="xs"
                color="info"
                className="btn-icon mr-2"
                onClick={() => {
                  this.setState({
                    judul: "Detail Booking Wisata",
                    statUbah: false
                  });
                  this.getDetailBooking(obj.uuid);
                }}
                disabled={this.state.status.btnAksi}
              >
                <i className="fa fa-eye"></i>
              </Button>

              {obj.data_booking && obj.data_booking.status === 'Konfirmasi' && obj.status === 'Belum Check In' ? (
                <Button
                  size="xs"
                  color="success"
                  className="btn-icon"
                  title="Check In"
                  onClick={() => {
                    this.setState({
                      judul: "Update Status Booking",
                      statUbah: false
                    });
                    // this.ubahStatusBooking(obj);

                    const dt_booking = {
                      uuid: obj.uuid,
                      status: 'Check In',
                    }
                
                    this.setState({
                      dt_booking,
                    })

                    this.setState({
                      alert: (
                        <SweetAlert
                          showCancel
                          confirmBtnText="Konfirmasi"
                          confirmBtnBsStyle="success"
                          cancelBtnBsStyle="default"
                          type="info"
                          title="Apakah anda yakin bahwa ingin melakukan check in pada transaksi ini ?"
                          onCancel={this.hideAlert}
                          onConfirm={() => {
                            this.simpanBooking()
                            this.hideAlert()
                          }}
                        >
                          Proses ini tidak dapat dibatalkan, pastikan anda sudah memilih data yang sesuai...
                        </SweetAlert>
                      )
                    });
                  }}
                >
                  <i className="fa fa-refresh"></i>
                </Button>
              ) : ''}

            </div>
          );
        }
      }
    ];

    var htmlToReactParser = new HtmlToReactParser();

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <div>
                    <Button
                      size="sm"
                      color="default"
                      style={{ backgroundColor: '#FFC312' }}
                      onClick={this.props.history.goBack}
                    >
                      <i className="fa fa-arrow-"></i> Kembali
                    </Button>
                  </div>
                  <br/>
                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "999"
                        })}
                        onClick={() => {
                          this.toggle_icon("999");
                        }}
                      >
                        <i className="fa fa-info"/>
                        Detail Wisata
                      </NavLink>
                    </NavItem>

                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-clipboard"/>
                        Paket Wisata
                      </NavLink>
                    </NavItem>
{/* 
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="icofont icofont-ui-home" />
                        Fasilitas
                      </NavLink>
                    </NavItem> */}

                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="icofont icofont-ui-image" />
                        Galeri
                      </NavLink>
                    </NavItem>

                    {/* <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="icofont icofont-ui-image" />
                        Pengelola Wisata
                      </NavLink>
                    </NavItem> */}
                    
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="fa fa-bar-chart" />
                        Trafik Pengunjung
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "6"
                        })}
                        onClick={() => {
                          this.toggle_icon("6");
                        }}
                      >
                        <i className="fa fa-bell" />
                        Booking
                      </NavLink>
                    </NavItem>
                  </Nav>
									<TabContent activeTab={this.state.active_tab_icon}>
										<TabPane tabId="999">
											<div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
																{this.state.dataWisata ? (
																	<>
																		<div className='col-12'>
																			<div className="row">
                                        <div className="col-3">
                                          <strong>Nama Wisata</strong>
                                          <br/>
                                          {this.state.dataWisata.nama}
                                        </div>
                                        <div className="col-3">
                                          <strong>No. Whatsapp</strong>
                                          <br/>
                                          {this.state.dataWisata.nomor_wa}
                                        </div>
                                        <div className="col-3">
                                          <strong>Email</strong>
                                          <br/>
                                          {this.state.dataWisata.email}
                                        </div>
                                        <div className="col-3">
                                          <strong>Alamat</strong>
                                          <br/>
                                          {this.state.dataWisata.alamat}
                                        </div>
                                        <div className="col-12">
                                          &nbsp;
                                        </div>
                                        <div className="col-3">
                                          <strong>Latitude</strong>
                                          <br/>
                                          {this.state.dataWisata.latitude}
                                        </div>
                                        <div className="col-3">
                                          <strong>Longitude</strong>
                                          <br/>
                                          {this.state.dataWisata.longitude}
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Facebook</strong>
                                          <br/>
                                          {this.state.dataWisata.link_fb?this.state.dataWisata.link_fb:'-'}
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Fanspage</strong>
                                          <br/>
                                          {this.state.dataWisata.fanspage?this.state.dataWisata.fanspage:'-'}
                                        </div>
                                        <div className="col-12">
                                          &nbsp;
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Instagram</strong>
                                          <br/>
                                          {this.state.dataWisata.link_ig?this.state.dataWisata.link_ig:'-'}
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Youtube</strong>
                                          <br/>
                                          {this.state.dataWisata.link_youtube?this.state.dataWisata.link_youtube:'-'}
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Messanger</strong>
                                          <br/>
                                          {this.state.dataWisata.link_messanger?this.state.dataWisata.link_messanger:'-'}
                                        </div>
                                        <div className="col-3">
                                          <strong>Link Tiktok</strong>
                                          <br/>
                                          {this.state.dataWisata.link_tiktok?this.state.dataWisata.link_tiktok:'-'}
                                        </div>
                                        <div className="col-12">
                                          &nbsp;
                                        </div>
                                        <div className="col-6">
                                          <strong>Fasilitas</strong>
                                          <br/>
                                          {htmlToReactParser.parse(this.state.dataWisata.fasilitas)}
                                        </div>
                                        <div className="col-12">
                                          &nbsp;
                                        </div>
                                        <div className="col-12 align-center" style={{textAlign:'justify'}}>
                                          <strong>Deskripsi Singkat</strong>
                                          <br/>
                                          {htmlToReactParser.parse(this.state.dataWisata.deskripsi_singkat)}
                                        </div>
                                        {
                                          this.state.dataWisata.thumbnail?
                                          <>
                                            <div className="col-12">
                                              &nbsp;
                                            </div>
                                            <div className="col-12">
                                              <strong>Gambar Thumbnail</strong>
                                              <br/>
                                              <img
                                                src={API_URL_WISATA+this.state.dataWisata.thumbnail}
                                                alt="logo"
                                                // height="65"
                                                style={styleImgIcon}
                                              />
                                            </div>
                                          </>
                                          :
                                          <></>
                                        }
                                      </div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane>
										<TabPane tabId="1">
											<div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
                                <div className="col-md-5 text-right">
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginBottom: '15px' }}
                                    id="cariTable"
                                    placeholder="Cari Berdasarkan Nama Paket atau Status"
                                    defaultValue={this.state.textCari}
                                    onChange={(e) => {
                                      this.setState({ textCari: e.target.value })
                                    }}
                                    onKeyPress={this.cariData}
                                  />
                                  <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                                    <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                      this.setState({
                                        textCari: '',
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                      document.getElementById('cariTable').value = ''
                                    }} ></span>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="info"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        textCari: document.getElementById("cariTable").value,
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                                <div className="col-md-5 text-right">
                                  <Button
                                    size="sm"
                                    color="success"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        judul: "Tambah Paket Wisata",
                                        dt: frmPaket,
                                        dataFoto:{
                                          link:'',
                                        },
                                      });
                                      //console.log(this.state.statUbah);
                                      this.bukaForm();
                                    }}
                                  >
                                    Tambah Data
                                  </Button>
                                </div>
                                <div className='col-12'>
                                  &nbsp;
                                </div>
																{data.length > 0 ? (
																	<>
																		<div className='col-12'>
																			<BootstrapTable keyField="id" data={data} columns={columns} />
																			<div className="pull-right text-white">
																				{this.state.awal}
																				{this.state.sebelum}
																				{this.state.hal.map((dt) => {
																					return dt;
																				})}
																				{this.state.setelah}
																				{this.state.akhir}
																			</div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane>
										{/* <TabPane tabId="2">
                      <div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
																{this.state.data2 ? (
																	<>
                                    <div className='col-12'>
																			<div className="row">
                                        <div className="col-md-12 text-right">
                                          <Button
                                            size="sm"
                                            color="success"
                                            className="btn-square"
                                            onClick={() => {
                                              this.setState({
                                                judul: "Ubah Fasilitas Wisata",
                                                dt_fasilitas:{
                                                  deskripsi: this.state.data2.deskripsi
                                                },
                                                status:{
                                                  form_fasilitas: true
                                                },
                                              });
                                            }}
                                          >
                                            Ubah Data Fasilitas
                                          </Button>
                                        </div>
                                        <div className="col-md-12 text-right">
                                          &nbsp;
                                        </div>
                                      </div>
                                      <br/>
																			<div className="row">
                                        <div className="col-12 text-center">
                                          <strong>Deskripsi Fasilitas Wisata</strong>
                                          <br/>
                                          {this.state.data2.deskripsi}
                                        </div>
                                        <div className="col-12 text-center">
                                          &nbsp;
                                        </div>
                                        <div className="col-12 text-center">
                                          &nbsp;
                                        </div>
                                      </div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
                                    <br/>
																		<h6 className="text-center mb-2 mt-2">Belum ada data!</h6>
                                    <br/>
                                    <div className="col-12 text-center">
                                      <Button
                                        size="sm"
                                        color="success"
                                        className="btn-square"
                                        onClick={() => {
                                          this.setState({
                                            judul: "Tambah Fasilitas Wisata",
                                            dt_fasilitas: frmFasilitas,
                                            status:{
                                              form_fasilitas: true
                                            },
                                          });
                                          //console.log(this.state.statUbah);
                                          this.bukaForm();
                                        }}
                                      >
                                        Tambah Data Fasilitas
                                      </Button>
                                    </div>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane> */}
										<TabPane tabId="3">
											<div style={{ marginTop: "15px" }}>
                        <div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
                                <div className="col-md-5 text-right">
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginBottom: '15px' }}
                                    id="cariTable"
                                    placeholder="Cari berdasarkan nama"
                                    defaultValue={this.state.textCari}
                                    onChange={(e) => {
                                      this.setState({ textCari: e.target.value })
                                    }}
                                    onKeyPress={this.cariData}
                                  />
                                  <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                                    <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                      this.setState({
                                        textCari: '',
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                      document.getElementById('cariTable').value = ''
                                    }} ></span>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="info"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        textCari: document.getElementById("cariTable").value,
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                                <div className="col-md-5 text-right" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="success"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        judul: "Tambah Galeri Wisata",
                                        dt_galeri: frmGaleri,
                                        status:{
                                          form_galeri: true
                                        },
                                        dataFoto:{
                                          link:'',
                                        },
                                      });
                                    }}
                                  >
                                    Tambah Data
                                  </Button>
                                </div>
                                <div className='col-12'>
                                  &nbsp;
                                </div>
																{data3.length > 0 ? (
																	<>
																		<div className='col-12'>
																			<BootstrapTable keyField="id" data={data3} columns={columnsGaleri} />
																			<div className="pull-right text-white">
																				{this.state.awal3}
																				{this.state.sebelum3}
																				{this.state.hal3.map((dt) => {
																					return dt;
																				})}
																				{this.state.setelah3}
																				{this.state.akhir3}
																			</div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane>
										{/* <TabPane tabId="4">
                      <div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
                                <div className="col-md-5 text-right">
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginBottom: '15px' }}
                                    id="cariTable"
                                    placeholder="Cari berdasarkan nama, jabatan atau status pengelola"
                                    defaultValue={this.state.textCari}
                                    onChange={(e) => {
                                      this.setState({ textCari: e.target.value })
                                    }}
                                    onKeyPress={this.cariData}
                                  />
                                  <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                                    <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                      this.setState({
                                        textCari: '',
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                      document.getElementById('cariTable').value = ''
                                    }} ></span>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="info"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        textCari: document.getElementById("cariTable").value,
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                                <div className="col-md-5 text-right" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="success"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        judul: "Tambah Pengelola Wisata",
                                        dt_pengelola: frmPengelola,
                                        status:{
                                          form_pengelola: true
                                        },
                                      });
                                    }}
                                  >
                                    Tambah Data
                                  </Button>
                                </div>
                                <div className='col-12'>
                                  &nbsp;
                                </div>
																{data4.length > 0 ? (
																	<>
																		<div className='col-12'>
																			<BootstrapTable keyField="id" data={data4} columns={columnsPengelola} />
																			<div className="pull-right text-white">
																				{this.state.awal4}
																				{this.state.sebelum4}
																				{this.state.hal4.map((dt) => {
																					return dt;
																				})}
																				{this.state.setelah4}
																				{this.state.akhir4}
																			</div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane> */}
                    <TabPane tabId="5">
											<div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
                                <div className="col-md-5 text-right">
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginBottom: '15px' }}
                                    id="cariTable"
                                    placeholder="Cari data"
                                    defaultValue={this.state.textCari}
                                    onChange={(e) => {
                                      this.setState({ textCari: e.target.value })
                                    }}
                                    onKeyPress={this.cariData}
                                  />
                                  <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                                    <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                      this.setState({
                                        textCari: '',
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                      document.getElementById('cariTable').value = ''
                                    }} ></span>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="info"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        textCari: document.getElementById("cariTable").value,
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>

																{data5.length > 0 ? (
																	<>
																		<div className='col-12'>
																			<BootstrapTable keyField="id" data={data5} columns={columnsTrafik} />
																			<div className="pull-right text-white">
																				{this.state.awal5}
																				{this.state.sebelum5}
																				{this.state.hal5.map((dt) => {
																					return dt;
																				})}
																				{this.state.setelah5}
																				{this.state.akhir5}
																			</div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane>
                    <TabPane tabId="6">
											<div style={{ marginTop: "15px" }}>
												<div className="row">
													{this.state.loading ? (
														<div className="col-sm-12" align="center">
															<img 
															alt="loading"
															src={require("../../assets/images/tes-loading.gif")}
															style={{
															// width: "100%",
															borderRadius: "10px",
															width: '100px'
															}}/> 
														</div>
														) : (
															<>
                                <div className="col-md-5 text-right">
                                  <input
                                    type="text"
                                    className="form-control"
                                    style={{ marginBottom: '15px' }}
                                    id="cariTable"
                                    placeholder="Cari data"
                                    defaultValue={this.state.textCari}
                                    onChange={(e) => {
                                      this.setState({ textCari: e.target.value })
                                    }}
                                    onKeyPress={this.cariData}
                                  />
                                  <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                                    <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                      this.setState({
                                        textCari: '',
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                      document.getElementById('cariTable').value = ''
                                    }} ></span>
                                  </div>
                                </div>
                                <div className="col-md-2" style={{ marginBottom: '15px' }}>
                                  <Button
                                    size="sm"
                                    color="info"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        textCari: document.getElementById("cariTable").value,
                                        loading: true
                                      });
                                      setTimeout(
                                        function () {
                                          this.fetch();
                                        }.bind(this),
                                        500
                                      );
                                    }}
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                                <div className="col-md-5 text-right">
                                  <Button
                                    size="sm"
                                    color="success"
                                    className="btn-square"
                                    onClick={() => {
                                      this.setState({
                                        judul: "Tambah Data Pengunjung Wisata",
                                        dt_booking_offline: frmBookingOffline,
                                        status: {
                                          ...this.state.status,
                                          form_trafik: true,
                                        }
                                      });
                                      this.bukaForm();
                                    }}
                                  >
                                    Tambah Data
                                  </Button>
                                </div>
																{data6.length > 0 ? (
																	<>
																		<div className='col-12'>
																			<BootstrapTable keyField="id" data={data6} columns={columnsBooking} />
																			<div className="pull-right text-white">
																				{this.state.awal6}
																				{this.state.sebelum6}
																				{this.state.hal6.map((dt) => {
																					return dt;
																				})}
																				{this.state.setelah6}
																				{this.state.akhir6}
																			</div>
																		</div>
																	</>
																) : (
																	<div className="col-md-12">
																		<h6 className="text-center mb-2 mt-2">Belum ada data</h6>
																	</div>
																)}
															</>
													)}
												</div>
											</div>
										</TabPane>
									</TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
					open={this.state.detail_preview ? true : false}
					onClose={() => {
						this.setState({ detail_preview: false, detail_data: [] })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
          styles={{ modal: { width: 'auto', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title" style={{ fontWeight: 'bold' }}>{this.state.detail_data.title}</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detail_data ? (
									<>
										<div className="table-responsive" align='center'>
                    <img 
                      alt="loading"
                      src={API_URL_WISATA+this.state.detail_data.link}
                      style={{
                        width:'900px',
                        borderRadius: "10px",
                      }}
                    /> 
										
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
        <Modal
          size="lg"
          open={this.state.status.formDetail}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Col sm="3">
                  <b>Nama Paket:</b>
                </Col>
                <Col sm="9">
                  {this.state.dt.nama}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Deskripsi Singkat:</b>
                </Col>
                <Col sm="9">
                  {htmlToReactParser.parse(this.state.dt.deskripsi_singkat)}
                </Col>
							</FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Harga (Rp.):</b>
                </Col>
                <Col sm="9">
                <NumberFormat
                  thousandSeparator={true}
                  prefix={'Rp. '}
                  displayType={'text'}
                  defaultValue={this.state.dt.harga}
                  value={this.state.dt.harga}
                />
                  {/* {this.state.dt.harga.toFixed(2)} */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Jumlah Orang:</b>
                </Col>
                <Col sm="9">
                  {this.state.dt.orang}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Durasi Wisata:</b>
                </Col>
                <Col sm="9">
                  {this.state.dt.durasi_wisata}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Fasilitas:</b>
                </Col>
                <Col sm="9">
                  {htmlToReactParser.parse(this.state.dt.fasilitas)}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Periode Booking:</b>
                </Col>
                <Col sm="9">
                  {this.state.dt.periode_booking}<br/>
                  <font color='red'><small>* Ket: Pembookingan dapat dilakukan H- hari yg diinputkan</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="3">
                  <b>Foto:</b>
                </Col>
                <Col sm="9">
                  <img src={API_URL_WISATA+this.state.dt.foto} style={{maxWidth:'100%',maxHeight: 'auto'}}/>
                </Col>
              </FormGroup>
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Col sm="6" className="mb-2">
                  <Label htmlFor="inputEmail3">
                    Nama Paket
                  </Label>
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Camping"
                    required
                    defaultValue={this.state.dt.nama}
                  />
                </Col>
              
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Harga (Rp.)
                  </Label>
                  <NumberFormat
										data-testid="harga"
										id="harga"
										name="harga"
										placeholder="Masukkan nominal harga"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.harga}
										value={this.state.dt.harga}
										onChange={(e) => {
											const harga = e.target.value
											this.setState({
												dt: {
													...this.state.dt,
													harga
												}
											})

										}}
										required
									/>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Jumlah Orang
                  </Label>
                  <input
                    className="form-control"
                    id="orang"
                    type="number"
                    placeholder="masukan minimal orang"
                    required
                    defaultValue={this.state.dt.orang}
                    min="1"
                  />
                </Col>

                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Durasi Wisata
                  </Label>
                  <input
                    className="form-control"
                    id="durasi_wisata"
                    type="text"
                    placeholder="masukan durasi paket wisata"
                    required
                    defaultValue={this.state.dt.durasi_wisata}
                  />
                  <font color='red'><small>*Contoh: 2 hari 1 malam</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Periode Booking
                  </Label>
                  <input
                    className="form-control"
                    id="periode_booking"
                    type="number"
                    placeholder="masukan periode booking"
                    required
                    defaultValue={this.state.dt.periode_booking}
                  />
                  <font color='red'><small>* Ket: Pembookingan dapat dilakukan H- hari yg diinputkan</small></font>
                </Col>
              
              </FormGroup>
              <FormGroup className="row">
                <Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Foto
                  </Label>
                  {this.state.loadingFoto ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'paket')} maxFiles={12}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              minHeight: '100px',
                              paddingTop: '40px',
                              padding: '5px 5px 5px 5px',
                              border: '1px solid #d2d2d2'
                            }}
                          >
                            <input {...getInputProps()} />
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <p className="text-center">
                                {this.state.dataFoto.link ? (
                                  <img
                                    src={API_URL_WISATA+this.state.dataFoto.link}
                                    style={{
                                      // minHeight: '50px',
                                      // minWidth: '50px'
                                      width: '470px'
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Label
                                    className="col-form-label text-center"
                                    htmlFor="inputEmail3"
                                  >
                                    Drag 'n' drop some files here, or click to select
                                    files
                                  </Label>
                                )}
                                {this.state.foto_info_foto ? this.state.foto_info_foto : ''}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>   
                    )
                  }
                </Col>
              </FormGroup>
              <FormGroup className="row">
								<Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Deskripsi Singkat
                  </Label>
									{/* <textarea className='form-control' name="deskripsi_singkat" id="deskripsi_singkat" cols="30" defaultValue={this.state.dt.deskripsi_singkat} rows="4" required /> */}
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        dt:{
                          ...this.state.dt,
                          deskripsi_singkat: data,
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    data={this.state.dt.deskripsi_singkat}
                    config={{         
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                    }}
                  />
								</Col>
							</FormGroup>
              <FormGroup className="row">
                <Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Fasilitas
                  </Label>
                  {/* <textarea className='form-control' name="fasilitas" id="fasilitas" cols="30" defaultValue={this.state.dt.fasilitas} rows="4" required /> */}

                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        dt:{
                          ...this.state.dt,
                          fasilitas: data,
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    data={this.state.dt.fasilitas}
                    config={{         
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                    }}
                  />
                </Col>
              </FormGroup>
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        {/* <Modal
          size="lg"
          open={this.state.status.form_fasilitas}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpanFasilitas}>
            <div className="modal-body">
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Fasilitas Wisata
                </Label>
                <Col sm="9">
									<textarea className='form-control' name="deskripsi" id="deskripsi" cols="30" placeholder="Contoh: Mushalla, Toilet, Dll" defaultValue={this.state.dt_fasilitas.deskripsi} rows="4" required />
								</Col>
              </FormGroup>
              
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal> */}
        <Modal
          size="lg"
          open={this.state.status.form_galeri}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpanGaleri}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt_galeri.uuid}
              />

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Galeri
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Air Terjun Batu Dinding"
                    required
                    defaultValue={this.state.dt_galeri.nama}
                  />
                </Col>
              </FormGroup>
              
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto
								</Label>
								<Col sm="9">
                {this.state.loadingFoto ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'paket')} maxFiles={12}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              minHeight: '100px',
                              paddingTop: '40px',
                              padding: '5px 5px 5px 5px',
                              border: '1px solid #d2d2d2'
                            }}
                          >
                            <input {...getInputProps()} />
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <p className="text-center">
                                {this.state.dataFoto.link ? (
                                  <img
                                    src={API_URL_WISATA+this.state.dataFoto.link}
                                    style={{
                                      // minHeight: '50px',
                                      // minWidth: '50px'
                                      width: '470px'
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Label
                                    className="col-form-label text-center"
                                    htmlFor="inputEmail3"
                                  >
                                    Drag 'n' drop some files here, or click to select
                                    files
                                  </Label>
                                )}
                                {this.state.foto_info_foto ? this.state.foto_info_foto : ''}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>   
                    )
                  }
                </Col>
							</FormGroup>
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          size="lg"
          open={this.state.status.form_pengelola}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpanPengelola}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt_pengelola.uuid}
              />

              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Nama Pengelola
                  </Label>
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Samsul"
                    required
                    defaultValue={this.state.dt_pengelola.nama}
                  />
                </Col>

                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Jabatan
                  </Label>
                  <input
                    className="form-control"
                    id="jabatan"
                    type="text"
                    placeholder="Contoh: Sekretaris"
                    required
                    defaultValue={this.state.dt_pengelola.jabatan}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    No. Handphone
                  </Label>
                  <input
                    className="form-control"
                    id="no_hp"
                    type="number"
                    min="0"
                    placeholder="masukkan nomor handphone"
                    required
                    defaultValue={this.state.dt_pengelola.no_hp}
                  />
                </Col>
              
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Alamat
                  </Label>
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="masukkan alamat pengelola"
                    required
                    defaultValue={this.state.dt_pengelola.alamat}
                  />
                </Col>
              </FormGroup>
              {
                this.state.dt_pengelola.uuid
                ?
                  <FormGroup className="row">
                    <Col sm="6">
                      <Label htmlFor="inputEmail3">
                        Status
                      </Label>
                      <Select
                        isDisabled={this.state.status.select}
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "status");
                        }}
                        defaultValue={this.state.statusPengelola}
                        value={this.state.statusPengelola}
                        name="role"
                        options={[
                          {
                            value: 'Tidak Aktif',
                            label: "Tidak Aktif",
                          },
                          {
                            value: 'Aktif',
                            label: "Aktif",
                          },
                        ]}
                        placeholder="Pilih Status"
                        isClearable
                        required
                      />
                    </Col>
                  </FormGroup>
                :
                  <></>
              }
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          size="md"
          open={this.state.status.form_trafik}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="md" className="modal-header">
            <h5 className="modal-title">Input Data Pengunjung</h5>
          </div>
          {/* <Form className="theme-form" onSubmit={this.simpanBookingOffline}> */}
          <Form className="theme-form" onSubmit={(e) => {
            e.preventDefault();

            if (this.state.dt_booking_offline && this.state.dt_booking_offline.tanggal_kunjungan && this.state.keranjangBookingOffline.length > 0) {
              this.setState({
                alert: (
                  <SweetAlert
                    showCancel
                    confirmBtnText="Konfirmasi"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    type="info"
                    title="Apakah anda yakin data yang diinputkan sudah benar dan ingin memproses transaksi ini ?"
                    onCancel={this.hideAlert}
                    onConfirm={() => {
                      this.simpanBookingOffline()
                      this.hideAlert()
                    }}
                  >
                    Proses ini tidak dapat dibatalkan, pastikan sudah menginputkan data yang sesuai...
                  </SweetAlert>
                )
              });
            } else {
              this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "Data Pengunjung Wisata",
                pesanAlert: "Isi seluruh data dengan benar"
              });
            }

          }}>
            <div className="modal-body">

              <div className="form-row">
								<Col sm="12" className="mb-2">
                  <Label htmlFor="validationCustom05">Tanggal Kunjungan</Label>
                  <ReactDatetimeClass
                    id="tanggal_kunjungan"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt_booking_offline.tanggal_kunjungan}
                    value={this.state.dt_booking_offline.tanggal_kunjungan}
                    onChange={(e) => {
                      if (typeof e._isValid !== 'undefined') {
                        let tgl = e.format('YYYY-MM-DD');

                        this.setState({
                          dt_booking_offline: {
                            ...this.state.dt_booking_offline,
                            tanggal_kunjungan: new Date(tgl)
                          }
                        })
                      }
                    }}
                    inputProps={{ required: true, placeholder: "Klik untuk memilih tanggal" }}
                  />
                </Col>
              </div>
              <div className="form-row">
								<Col sm="8" className="mb-2">
									<Label htmlFor="validationCustom05">Paket Wisata</Label>
                  <Select
                    classNamePrefix="select"
                    onChange={e => {
                      this.setState({ paketSelected: e })
                    }}
                    defaultValue={this.state.paketSelected}
                    value={this.state.paketSelected}
                    options={this.state.listOptionPaket}
                    placeholder="Pilih Paket"
                    // isClearable
                    // required
                  />
                </Col>
								<Col sm="2" className="mb-2">
									<Label htmlFor="validationCustom05">Qty</Label>
                  <input
                    className="form-control"
                    id="qty"
                    type="number"
                    min={1}
                    placeholder="Jumlah Paket"
                    defaultValue={this.state.qtyBookingOffline}
                    value={this.state.qtyBookingOffline}
                    onChange={(e) => {
                      this.setState({qtyBookingOffline: e.target.value})
                    }}
                    // required
                  />
                </Col>
								<Col sm="2" className="d-flex flex-column justify-content-end mb-2">
                  <Button
                    size="sm"
                    type="button"
                    color="success"
                    style={{ backgroundColor: '#FFC312', marginBottom: '5px' }}
                    onClick={() => {
                      // Cek jika ada data yang kosong
                      if (this.state.paketSelected !== null && this.state.qtyBookingOffline > 0) {
                        let keranjangBookingOffline = this.state.keranjangBookingOffline

                        keranjangBookingOffline.push({
                          paket: this.state.paketSelected,
                          qty: parseInt(this.state.qtyBookingOffline),
                        })

                        console.log(keranjangBookingOffline);

                        this.setState({ 
                          keranjangBookingOffline, 
                          paketSelected: null,
                          qtyBookingOffline: 1,
                        })
                      }
                    }}
                  >
                    Tambah
                  </Button>
                </Col>
              </div>
              
              {this.state.keranjangBookingOffline.length > 0 ? (
                <>
                  <h6 className="mt-4 mb-2"><b>List pesanan :</b></h6>
                  <table className="table table-bordered table-stripes table-hovered">
                    <tr style={{ backgroundColor: 'silver' }}>
                      <th className="text-center">Nama Paket</th>
                      <th className="text-center">Qty</th>
                      <th className="text-center">Jumlah Orang</th>
                      <th className="text-center">Harga Paket</th>
                      <th className="text-center">Total</th>
                      <th className="text-center">Aksi</th>
                    </tr>
                    {this.state.keranjangBookingOffline.map((val, index) => {
                      const getDetailPaket = this.state.listPaket.filter((valPaket) => {
                        return valPaket.uuid == val.paket.value
                      })

                      return (
                        <tr>
                          <td className="text-center">{getDetailPaket[0] ? getDetailPaket[0].nama : '-'}</td>
                          <td className="text-center">{val.qty}</td>
                          <td className="text-center">{getDetailPaket[0] ? getDetailPaket[0].orang * val.qty : '-'}</td>
                          <td className="text-center">{getDetailPaket[0] ? formatRupiah(getDetailPaket[0].harga) : '-'}</td>
                          <td className="text-center">{getDetailPaket[0] ? formatRupiah((getDetailPaket[0].harga) * val.qty) : '-'}</td>
                          <td className="text-center">
                            <Button
                              size="xs"
                              color="danger"
                              className="btn-icon"
                              onClick={() => {
                                let keranjangBookingOffline = this.state.keranjangBookingOffline

                                keranjangBookingOffline.splice(index, 1)


                                this.setState({
                                  keranjangBookingOffline,
                                });
                              }}
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                </>
              )
              : (
                <h6 className="text-center mt-2">Belum ada data yang ditambahkan</h6>
              )}
              
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        
        {/* Modal Status Booking */}
        <Modal
          size="md"
          open={this.state.status.form_booking}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="md" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpanBooking}>
            <div className="modal-body">

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Kedatangan
                </Label>
                <Col sm="9">
                <select id='status' name='status' className='form-control' defaultValue={this.state.dt_booking.status} onChange={(e) => {
                    console.log(e);
                    this.setState({
                      dt_booking: {
                        ...this.state.dt_booking,
                        status: e.target.value
                      }
                    })
                  }} required>
                    <option value="">Pilih Status Booking</option>
                    <option value="Belum Check In">Belum Check In</option>
                    <option value="Check In">Check In</option>
                  </select>
                </Col>
              </FormGroup>
              
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal Detail Booking */}
        <Modal
          size="lg"
          open={this.state.status.formDetailBooking}
          onClose={this.tutupForm}
          styles={{ modal: { width: "60%", maxWidth: "unset" } }}
          closeOnEsc={false}
          closeOnOverlayClick={true}
        >
          <div size="md" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <div className="modal-body">
            <table>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Jenis Booking</td>
                <td className="text-center" style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking && this.state.detailBooking.data_booking ? this.state.detailBooking.data_booking.jenis : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Kunjungan</td>
                <td className="text-center" style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking ? Tanggal(this.state.detailBooking.tanggal_kunjungan) : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Jumlah Orang</td>
                <td className="text-center" style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking ? this.state.detailBooking.jumlah_orang : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Kode Booking</td>
                <td className="text-center" style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking && this.state.detailBooking.data_booking ? this.state.detailBooking.data_booking.kode_booking : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking ? Tanggal(this.state.detailBooking.created_at) : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Total Harga</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking && this.state.detailBooking.data_booking.total ? formatRupiah(this.state.detailBooking.data_booking.total) : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Status Check In</td>
                <td className="text-center" style={{ width: '50px' }}>:</td>
                <td>{this.state.detailBooking ? this.state.detailBooking.status : ''}</td>
              </tr>
            </table>

            <h6 className="mt-3" style={{ fontWeight: 'bold' }}>List Paket:</h6>
            {this.state.detailBooking && this.state.detailBooking.data_booking_detail_paket_wisata && this.state.detailBooking.data_booking_detail_paket_wisata.length > 0 ? (
              <table className="table table-border-black table-bordered" style={{ padding: '0.5rem' }}>
                <tr>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Nama Paket</td>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Harga</td>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Qty</td>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Total</td>
                </tr>
                {this.state.detailBooking.data_booking_detail_paket_wisata.map((ec) => {
                  return (
                    <tr>
                      <td className="text-center">{ec.data_paket.nama}</td>
                      <td className="text-center">{formatRupiah(ec.harga)}</td>
                      <td className="text-center">{ec.qty}</td>
                      <td className="text-center">{formatRupiah(ec.total)}</td>
                    </tr>
                  )
                })}

              </table>
            ) : (
              <p className="mb-1">Tidak ada data paket</p>
            )}

            <h6 className="mt-3" style={{ fontWeight: 'bold' }}>Riwayat Status:</h6>
            {this.state.detailBooking && this.state.detailBooking.data_booking_detail_riwayat_status && this.state.detailBooking.data_booking_detail_riwayat_status.length > 0 ? (
              <table className="table table-border-black table-bordered">
                <tr>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Tanggal</td>
                  <td className="text-center" style={{ fontWeight: 'bold' }}>Status</td>
                </tr>
                {this.state.detailBooking.data_booking_detail_riwayat_status.map((ec) => {
                  return (
                    <tr>
                      <td className="text-center">{HariTanggalJam(ec.created_at)}</td>
                      <td className="text-center">{ec.status}</td>
                    </tr>
                  )
                })}

              </table>
            ) : (
              <p className="mb-1">Belum ada riwayat status</p>
            )}
            
            </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              disabled={this.state.status.btnForm}
              onClick={this.tutupForm}
            >
              Tutup
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
