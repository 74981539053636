import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, PostWisata, API_URL_WISATA, GetWisata, ID_DESA, DeleteWisata } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import "../../assets/css/content-styles.css"
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from 'axios';
import { DataToken } from '../../function/Format';
import { Link, Redirect } from 'react-router-dom';

const frmDex = {
  uuid: null,
  judul: '',
  nama_penulis: '',
  tanggal: null,
  thumbnail: '',
  deskripsi: '',
}

const dateFormat = require('dateformat');
var HtmlToReactParser = require("html-to-react").Parser;

function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new FormArtikelWisata(loader);
  };
}

class FormArtikelWisata extends Component {
  constructor(props) {
    super(props);
    this.loader = props
    this.state = {
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnSubmit: false,
        modalDetail: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,

      judulForm: 'Tambah Artikel Wisata',
      uuid_desa_wisata: null,

      content: '',
      ckimage_resp: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      textCari: ''
    };
  }

  componentDidMount() {

    const dt = this.props.location.state.data

    this.setState({
      judulForm: this.props.location.state.type + ' Artikel Desa Wisata',
      dt,
      link_file: dt.thumbnail,
      content: dt.deskripsi
    })
    this.forceUpdate();

  }

  // CKEDITOR 5
  upload() {
    return new Promise((resolve, reject) => {

      const data = new FormData();

      this.loader.file.then(result => {
        data.append('files[]', result);

        const url = process.env.REACT_APP_API_URL_WISATA;

        PostWisata('upload-file/multiple/desa-wisata', null, data, (res) => {
          console.log(res);

          if (res.data.results) {
            const xhr = res.data;
            const loader = this.loader;
            const genericErrorText = 'Couldn\'t upload file:' + ` ${loader.file.name}.`;

            const response = xhr.code;

            if (response == 200) {
              resolve({
                // default: xhr.results[0]
                default: API_URL_WISATA + xhr.results[0]
              });
            } else {
              reject()
            }
          }
        });

      })
    })
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();

      this.setState({
        ckimage_resp: { code: '406', message: 'Hapus' }
      });
    }
  }

  // END CKEDITOR 5

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  simpan = ((e) => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    let complete = false
    if (this.state.link_file !== '' && this.state.dt.deskripsi) {
      complete = true
    }

    if (complete) {
      GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
        console.log(res);
        if (res.results) {
          let token = DataToken(sessionStorage.getItem('access_token'));

          console.log(token);

          this.setState({
            uuid_desa_wisata: token.sub.uuid_desa_wisata
          });

          const uuid_pengguna = token.sub.uuid
          const uuid_desa_wisata = token.sub.uuid_desa_wisata
          let thumbnail = this.state.link_file

          // console.log(thumbnail);
          // console.log(this.state.dt.deskripsi);
          // console.log(dateFormat(new Date(), 'yyyy-mm-dd'));
          // console.log(document.getElementById('uuid').value);
          // console.log(document.getElementById('judul').value);
          // console.log(document.getElementById('nama_penulis').value);

          let bodyData = {
            uuid: document.getElementById("uuid").value,
            uuid_desa_wisata,
            uuid_pengguna,
            judul: document.getElementById('judul').value,
            status: document.getElementById('status').value,
            // nama_penulis: sessionStorage.getItem('nama_admin_wisata'),
            tanggal: dateFormat(new Date(), 'yyyy-mm-dd'),
            thumbnail,
            deskripsi: this.state.dt.deskripsi,
          }
          console.log(bodyData);

          let psn = "";
          let resstat = 201;
          let metode = "create";

          if (bodyData.uuid === "") {
            psn = "tambah";
            resstat = 201;
            bodyData.uuid = null;
          } else {
            psn = "ubah";
            resstat = 201;
            metode = "update";
          }

          PostWisata("desa-wisata/artikel/" + metode, bodyData.uuid, bodyData, res => {
            console.log(res.status);
            if (res.status === resstat) {

              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Berhasil " + psn + " Data",
                loadingPreview: false,
                status: {
                  ...this.state.status,
                  form: false
                }
              });
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Info " + JENIS_DESA,
                pesanAlert: "Gagal " + psn + " Data",
                loadingPreview: false,
              });
            }
          })

        }
      })
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Artikel Wisata",
        pesanAlert: "Masukkan gambar thumbnail dan isi artikel",
        loadingPreview: false,
      });
    }

  })

  render() {
    var data = this.state.data
    var htmlToReactParser = new HtmlToReactParser();

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          // onConfirm={this.closeAlert}
          onConfirm={() => this.props.history.push('/wisata/artikel')}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  {/* <div className="card-header">
                    <div className="row">
                      <div className="col-sm-12 text-right">
                      </div>
                    </div>
                  </div> */}
                  <div className="card-body datatable-react">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="col-sm-12" align="center">
                          <img
                            alt="loading"
                            src={require("../../assets/images/tes-loading.gif")}
                            style={{
                              borderRadius: "10px",
                              width: '100px'
                            }} />
                        </div>
                      ) : (
                        <>
                          <div className='col-sm-12'>
                            <Form id="myForm" className="theme-form" onSubmit={this.simpan}>
                              <div className="form-row">
                                <Col sm="12" className="mb-3">
                                  <h3><b>{this.state.judulForm}</b></h3>
                                </Col>
                                <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />
                                <Col sm="12" className="mb-3">
                                  <Label htmlFor="validationCustom05">Judul Artikel</Label>
                                  <input
                                    className="form-control"
                                    id="judul"
                                    type="text"
                                    placeholder="Contoh : Desa Sungai Petai meraih penghargaan sebagai Desa Wisata terbaik 2022"
                                    defaultValue={this.state.dt.judul}
                                    minLength={3}
                                    maxLength={50}
                                    onChange={(e) => {
                                      this.setState({
                                        dt: {
                                          ...this.state.dt,
                                          judul: e.target.value
                                        }
                                      })
                                    }}
                                    required
                                  />
                                </Col>
                                <Col sm="12" className="mb-3">
                                  <Label htmlFor="validationCustom05">Thumbnail Artikel</Label>
                                  <Dropzone
                                    onDrop={(files) => {
                                      console.log(files);

                                      if (files.length === 1) {
                                        let type = files[0].type.split('/');

                                        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {
                                          let bodyFormData = new FormData();
                                          bodyFormData.append('files', files[0]);

                                          this.setState({
                                            files, status: {
                                              ...this.state.status,
                                              btnSubmit: true
                                            }
                                          })

                                          PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                                            console.log(data);

                                            if (data.data.results) {
                                              this.setState({
                                                link_file: data.data.results
                                              });
                                            }

                                            this.setState({
                                              status: {
                                                ...this.state.status,
                                                btnSubmit: false,
                                              }
                                            })
                                          });

                                        } else { // Jika bukan pdf
                                          this.setState({
                                            show: true,
                                            basicType: 'warning',
                                            basicTitle: 'Terjadi Kesalahan',
                                            pesanAlert: 'Masukkan thumbnail dengan format gambar'
                                          });
                                        }
                                      }
                                    }}>
                                    {({ getRootProps, getInputProps }) => (
                                      <section>
                                        <div
                                          {...getRootProps()}
                                          style={{
                                            minHeight: '100px',
                                            paddingTop: '40px',
                                            padding: '5px 5px 5px 5px',
                                            border: '1px solid #d2d2d2'
                                          }}
                                        >
                                          <input {...getInputProps()} />
                                          <div
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                          >
                                            {this.state.link_file ? (
                                              <img
                                                src={API_URL_WISATA + this.state.link_file}
                                                alt="thumbnail artikel"
                                                style={{
                                                  // width: '470px',
                                                  maxHeight: '300px',
                                                  maxWidth: '500px'
                                                }}
                                              />
                                            ) : (
                                              <p>Drag 'n' drop some files here, or click to select files</p>
                                            )}
                                          </div>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </Col>
                                <Col sm="12" className="mb-3">
                                  <Label htmlFor="validationCustom05">Isi Artikel</Label>
                                  <CKEditor
                                    onReady={editor => {
                                      // Insert the toolbar before the editable area.
                                      editor.ui.getEditableElement().parentElement.insertBefore(
                                        editor.ui.view.toolbar.element,
                                        editor.ui.getEditableElement()
                                      );

                                      this.editor = editor;

                                      if (this.state.dt.deskripsi !== '') {
                                        this.editor.setData(this.state.dt.deskripsi);
                                      }
                                    }}
                                    onError={(error, { willEditorRestart }) => {
                                      if (willEditorRestart) {
                                        this.editor.ui.view.toolbar.element.remove();
                                      }
                                    }}
                                    onChange={(event, editor) => {
                                      var data = editor.getData();
                                      console.log(data);
                                      console.log(this.state.dt.deskripsi);

                                      // if (this.state.dt.deskripsi !== '') {
                                      //   data = this.state.dt.deskripsi
                                      // }

                                      this.setState({
                                        content: data,
                                        dt: {
                                          ...this.state.dt,
                                          deskripsi: data
                                        },
                                      })
                                      this.forceUpdate()
                                    }}
                                    editor={DecoupledEditor}
                                    // data={this.state.content}
                                    data={this.state.dt.deskripsi}
                                    config={{
                                      extraPlugins: [MyCustomUploadAdapterPlugin],
                                    }}
                                  />
                                </Col>
                                <Col sm="12" className="mb-3">
                                  <Label htmlFor="validationCustom05">Status</Label>
                                  <select id='status' name='status' className='form-control' defaultValue={this.state.dt.status} value={this.state.dt.status} onChange={(e) => {
                                    console.log(e);
                                    this.setState({
                                      dt: {
                                        ...this.state.dt,
                                        status: e.target.value,
                                      }
                                    })
                                  }} required>
                                    <option value="">Pilih Status</option>
                                    <option value="Draft">Draft</option>
                                    <option value="Archive">Archive</option>
                                    <option value="Publish">Publish</option>
                                  </select>
                                </Col>
                              </div>
                              <div className="form-row">
                                <Col sm="12" className="mb-3">
                                  <button type="button" className="btn btn-default mr-3" onClick={() => {
                                    document.getElementById('myForm').reset()
                                    this.setState({
                                      dt: frmDex
                                    })
                                    this.props.history.goBack()
                                  }}>
                                    Batal
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={this.state.status.btnSubmit}
                                  >
                                    Submit
                                  </button>
                                </Col>
                              </div>
                            </Form>
                          </div>

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    );
  }
}

FormArtikelWisata.propTypes = {

};

export default FormArtikelWisata;