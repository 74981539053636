import React, { Component } from 'react';

import Modal from 'react-responsive-modal';
import { Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import { PostStatus, Get, GetWisata, PostWisata, PatchWisata, } from '../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { DataToken } from '../../function/Format';

const frmDef = {
	uuid: '',
	id_desa: '',
	nama: '',
	no_hp: '',
	alamat: '',
	username: '',
	email: '',
};
const initPassword = {
	password: '',
	password_lama: '',
}
class EditProfilWisata extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: frmDef,
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			dt: frmDef,
			dataPassword: initPassword,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		// Validasi

		this.setState({
			status: {
				...this.state.status,
				btnForm: true,
			},
		})
		this.forceUpdate();
		let bodyFormData = {
			// uuid: this.state.data.uuid,
			// id_desa: this.state.data.id_desa,
			// username: this.state.data.username,
			nama: document.getElementById('nama').value,
			email: document.getElementById('email').value,
			alamat: document.getElementById('alamat').value,
			no_hp: document.getElementById('no_hp').value,
		};

		// const password = this.state.dataPassword.password.trim()

		// // Jika password berisi nilai
		// if (password.trim() !== '') {
		// 	let bodyDataPassword = {
		// 		username: this.state.data.username,
		// 		no_hp: document.getElementById('no_hp').value,
		// 		password,
		// 		password_lama : this.state.dataPassword.password_lama.trim(),
		// 	}

		// 	PatchWisata("auth/update-password", '', bodyDataPassword, res2 => {
		// 		console.log(res2);

		// 		if (res2.status === resstat) {
		// 			this.setState({
		// 				show: true,
		// 				basicType: "success",
		// 				basicTitle: "Edit Profil dan Password Pengguna",
		// 				pesanAlert: "Berhasil Edit Profil dan Password"
		// 			});
		// 		} else {
		// 			this.setState({
		// 				show: true,
		// 				basicType: "danger",
		// 				basicTitle: "Edit Profil dan Password Pengguna",
		// 				pesanAlert: "Gagal Edit Profil dan Password"
		// 			});
		// 		}
		// 	})

		// }
		// // End case ganti password

		console.log(bodyFormData)

		let psn = 'Edit';
		let resstat = 201;

		PostWisata("auth/edit-profil/" + this.state.data.uuid, null, bodyFormData, res => {
			console.log(res);

			const password = this.state.dataPassword.password.trim()

			// Jika password berisi nilai
			if (password.trim() !== '') {
				let bodyDataPassword = {
					username: this.state.data.username,
					no_hp: document.getElementById('no_hp').value,
					password_baru: password,
					password_lama: this.state.dataPassword.password_lama.trim(),
				}

				PatchWisata("auth/update-password", '', bodyDataPassword, res2 => {
					console.log(res2);

					if (res2.status === resstat) {
						this.setState({
							show: true,
							basicType: "success",
							basicTitle: "Edit Profil dan Password Pengguna",
							pesanAlert: "Berhasil Edit Profil dan Password"
						});
					} else {
						let message = 'Gagal Edit Profil dan Password'

						if (res2.data && res2.data.message) {
							message = res2.data.message
						}

						this.setState({
							show: true,
							basicType: "danger",
							basicTitle: "Edit Profil dan Password Pengguna",
							pesanAlert: message
						});
					}
					this.componentDidMount();

					document.getElementById("password").value = ''
					document.getElementById("password_lama").value = ''
				})

			} else { // Jika tidak ganti password
				this.setState({
					status: {
						...this.state.status,
						btnForm: false,
						form: false
					}
				})

				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: "success",
						basicTitle: "Edit Profil Pengguna",
						pesanAlert: "Berhasil Edit Profil"
					});
				} else {
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "Edit Profil Pengguna",
						pesanAlert: "Gagal Edit Profil"
					});
				}

				this.componentDidMount();

				document.getElementById("password").value = ''
				document.getElementById("password_lama").value = ''
			}

		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false,
			},
		})
		this.forceUpdate();
	};

	componentDidMount() {
		let token = DataToken(sessionStorage.getItem('access_token'));
		//console.log(token)

		if (token) {
			GetWisata("auth/show", token.sub.uuid, response => {
				//console.log(response)
				if (response.results) {
					let data = {
						uuid: response.results.uuid,
						id_desa: response.results.id_desa,
						nama: response.results.nama,
						no_hp: response.results.no_hp,
						alamat: response.results.alamat,
						username: response.results.username,
						email: response.results.email,
					}
					this.setState({ data, dt: data })
				}
			})
		}

		// Reset kolom password
		this.setState({
			dataPassword: initPassword
		})
	}

	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		// var data = this.state.data;
		// const frmDef = {
		// 	uuid: '',
		// 	nama_role: ''
		// };

		// const columns = [
		// 	{
		// 		dataField: 'uuid',
		// 		text: 'ID',
		// 		sort: false
		// 	},
		// 	{
		// 		dataField: 'nama_role',
		// 		text: 'Role',
		// 		sort: true
		// 	},
		// 	{
		// 		dataField: 'aksi',
		// 		text: 'Aksi',
		// 		isDummyField: true,
		// 		csvExport: false,
		// 		formatter: this.tombolAksi
		// 	}
		// ];

		return (
			<div>
				{/* <Breadcrumb title="Data Roles" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										{/* <div className="col-sm-6">
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({ dt: frmDef });
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div> */}
										<div className="col-sm-12">
											<h5>Edit Profil Pengguna</h5>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									{/* <BootstrapTable keyField="uuid" data={data} columns={columns} />
									{this.pagination} */}
									<div className="row" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
										<div className="col-sm-7">
											{this.state.data !== null ? (
												<div>
													<Form className="theme-form" onSubmit={this.simpan}>
														<input type="hidden" id="uuid" />
														{/* Username */}
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Username</b>
															</Label>
															<Col sm="8">
																<input
																	id="username"
																	className="form-control mb-0"
																	type="text"
																	value={this.state.data.username}
																	style={{ backgroundColor: "#c9d1d8" }}
																	disabled
																/>
															</Col>
														</FormGroup>
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Nama Lengkap</b>
															</Label>
															<Col sm="8">
																<input
																	id="nama"
																	className="form-control mb-0"
																	type="text"
																	defaultValue={this.state.data.nama}
																	required
																/>
															</Col>
														</FormGroup>
														{/* Email */}
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Email</b>
															</Label>
															<Col sm="8">
																<input
																	id="email"
																	className="form-control mb-0"
																	type="email"
																	defaultValue={this.state.data.email}
																	required
																/>
															</Col>
														</FormGroup>
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Alamat</b>
															</Label>
															<Col sm="8">
																<input
																	id="alamat"
																	className="form-control mb-0"
																	type="text"
																	defaultValue={this.state.data.alamat}
																/>
															</Col>
														</FormGroup>
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Nomor HP</b>
															</Label>
															<Col sm="8">
																<input
																	id="no_hp"
																	className="form-control mb-0"
																	type="text"
																	defaultValue={this.state.data.no_hp}
																	required
																/>
															</Col>
														</FormGroup>
														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Password</b>
															</Label>
															<Col sm="8">
																<input
																	id="password"
																	className="form-control mb-0"
																	type="password"
																	placeholder="Password baru (jika diperlukan)"
																	onChange={(event2) => {
																		let password = event2.target.value.trim()

																		this.setState({
																			dataPassword: {
																				...this.state.dataPassword,
																				password
																			}
																		})

																		// Show kolom input password lama jika ada value
																		if (password !== '') {
																			document.getElementById('parent-password-lama').style.display = "flex"
																		} else {
																			document.getElementById('parent-password-lama').style.display = "none"
																		}
																	}}
																/>
															</Col>
														</FormGroup>
														<FormGroup className="row" id="parent-password-lama" style={{ display: 'none' }}>
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															>
																<b>Password Lama</b>
															</Label>
															<Col sm="8">
																<input
																	id="password_lama"
																	className="form-control mb-0"
																	type="password"
																	placeholder="Masukkan password lama anda"
																	onChange={(event2) => {
																		let password_lama = event2.target.value.trim()

																		this.setState({
																			dataPassword: {
																				...this.state.dataPassword,
																				password_lama
																			}
																		})
																	}}
																/>
															</Col>
														</FormGroup>

														<FormGroup className="row">
															<Label
																className="col-sm-4 col-form-label"
																htmlFor="inputEmail3"
															/>
															<Col sm="8">
																<button className="btn btn-md btn-success" type="submit">Simpan</button>
															</Col>
														</FormGroup>
													</Form>
												</div>
											) : (
												<div>
													<h6 style={{ textAlign: "center" }}>Sedang memuat data...</h6>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="Role"
								defaultValue={this.state.dt.uuid}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Role
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_role"
										type="text"
										placeholder="Role"
										defaultValue={this.state.dt.nama_role}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								disabled={this.state.status.btnForm}
								onClick={this.simpan}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default EditProfilWisata;
