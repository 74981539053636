import React, { Component } from "react";
import {Link} from 'react-router-dom';

// Import authService
import { Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";

class UserMenu extends Component {
  state = {
    navigate: false,
    akun: DataToken(sessionStorage.getItem("access_token"))
  };
  logOut(e) {
    e.preventDefault();
    // localStorage.clear();
    sessionStorage.removeItem("access_token");
    this.setState({ navigate: true });
  }
  
  render() {
    const { navigate} = this.state;
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    let link_update_profil = process.env.PUBLIC_URL + '/edit-profil'

    // Jika bukan operator administrasi (admin wisata)
    if (this.state.akun && this.state.akun.sub && typeof this.state.akun.sub.data_role == 'undefined') {
      link_update_profil = process.env.PUBLIC_URL + '/edit-profil-wisata'
    }

    return (
      <li className="onhover-dropdown">
        <div className="media  align-items-center">
          {/* <img
            className="align-self-center pull-right mr-2"
            src={require("../../../assets/images/dashboard/user.png")}
            alt="header-user"
          /> */}
          <div className="media-body">
            <h6 className="m-0 txt-dark f-16">
            {/* {akun.sub.username} */}
              <Link to={link_update_profil} title="Edit Profile">
              <img
                className="align-self-center pull-right"
                src={require("../../../assets/images/dashboard/user.png").default}
                alt="header-user"
              />
                {/* <div className="card-body p-1 text-center text-middle">
                  <i className="fa fa-angle-down pull-right ml-2"></i>
                </div> */}
              </Link>
              {/* <i className="fa fa-angle-down pull-right ml-2"></i> */}
            </h6>
          </div>
        </div>
        {/* <ul className="profile-dropdown onhover-show-div p-20">
          <li>
            <Link to={process.env.PUBLIC_URL + '/edit-profil'}>
              <div className="card-body p-1 text-center text-middle">
                <i className="icon-user"></i>
                Edit Profile
              </div>
            </Link>
          </li>

          {akun.sub.data_role.nama_role != "Admin" ? (
            <li>
              <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                <i className="icon-user"></i>
                Manajemen Pengguna
              </Link>
            </li>
          ) : (
            null
          )}

          <li>
            <a href="#" onClick={this.logOut.bind(this)}>
              <i className="icon-power-off"></i>
              Logout
            </a>
          </li>
        </ul> */}
      </li>
    );
  }
}

export default UserMenu;
