import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, GetWisata, ID_DESA, PostWisata, DeleteWisata } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';
import { DataToken, HariTanggalJam, Tanggal, formatRupiah } from '../../function/Format';
import Select from "react-select";

const frmDex = {
  uuid: null,
  status: '',
}

class BookingWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      akun: DataToken(sessionStorage.getItem('access_token')),
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnSubmit: false,
        modalDetail: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,
      listWisata: [],

      judulForm: 'Tambah Booking Wisata',

      foto_desa: null,
      uuid_desa_wisata: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      kategoriStatus: null,
      filterObjekWisata: null,
      textCari: ''
    };
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });

    let uuid_desa_wisata = this.state.akun.sub.uuid_desa_wisata

    GetWisata("wisata/list", uuid_desa_wisata, dtkat => {

      if (dtkat.results) {
        let listWisata = [];

        dtkat.results.map((e) => {
          listWisata.push({
            value: e.uuid,
            label: e.nama,
          })
        })

        this.setState({
          listWisata,
        })
      }

    });
  }

  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true })

    let link = "";
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    let uuid_desa_wisata = this.state.akun.sub.uuid_desa_wisata

    let bodyData = {
      uuid_desa_wisata,
      status: this.state.kategoriStatus ? this.state.kategoriStatus.value: null,
      uuid_wisata: this.state.filterObjekWisata ? this.state.filterObjekWisata.value : null,
      q: this.state.textCari,
    }

    if (bodyData.status == null || bodyData.status == '') {
      delete bodyData.status
    }
    if (this.state.filterObjekWisata == null) {
      delete bodyData.uuid_wisata
    }

    PostWisata("wisata/booking" + link, null, bodyData, dtkat => {
      console.log(dtkat);
      // console.log(dtkat.data);

      if (dtkat.data) {
        this.setState({
          data: dtkat.data.results.data,
          pagination: dtkat.data.results,
          loading: false
        })
        // Pagination
        this.pagination(dtkat.data.results);
      }
      this.setState({ loading: false })

    });

  };

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  ubahData = data => {
    console.log(data);

    const dt = {
      uuid: data.uuid,
      status: data.status,
    }

    this.setState({
      dt,
      judulForm: 'Update Status Booking',
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  }

  hapusData = (uuid) => {
    this.setState({
      alert: null,
      loadingPreview: true
    })
    // console.log(uuid);

    DeleteWisata('wisata/booking/delete', uuid, (res) => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: 'success',
          basicTitle: 'Data Booking Wisata',
          pesanAlert: 'Berhasil hapus data'
        });
        this.fetch()
      } else {
        this.setState({
          show: true,
          basicType: 'danger',
          basicTitle: 'Data Booking Wisata',
          pesanAlert: 'Gagal hapus data'
        });
      }
      this.setState({ alert: null })
      this.setState({ loadingPreview: false })
    })

  }

  cariData = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        textCari: e.target.value,
      });
      setTimeout(
        function () {
          this.fetch();
        }.bind(this),
        500
      );
    }
  };

  simpan = ((e) => {
    // e.preventDefault();

    this.setState({ loadingPreview: true })

    if (this.state.dt.status !== '') {
      let bodyData = {
        status: this.state.dt.status
      }

      console.log(bodyData);

      let psn = "";
      let resstat = 204;
      let metode = "create";

      if (bodyData.uuid === "") {
        psn = "tambah";
        resstat = 201;
      } else {
        psn = "ubah";
        resstat = 201;
        metode = "update";
      }

      PostWisata("wisata/booking/update", this.state.dt.uuid, bodyData, res => {
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Booking Wisata",
            pesanAlert: "Berhasil " + psn + " Data",
            loadingPreview: false,
            status: {
              ...this.state.status,
              form: false
            }
          });
          this.fetch()
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Info " + JENIS_DESA,
            pesanAlert: "Gagal " + psn + " Data",
            loadingPreview: false,
          });
        }
      })

    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Booking Wisata",
        pesanAlert: "Pilih status booking",
        loadingPreview: false,
      });
    }



  })

  getDetail = (uuid) => {
    this.setState({ loading: true })

    GetWisata("wisata/booking/show", uuid, dtkat => {
      console.log(dtkat);

      if (dtkat.results) {
        this.setState({
          detailData: dtkat.results,
          status: {
            ...this.state.status,
            modalDetail: true
          }
        })
      }
      this.setState({ loading: false })

    });
  }

  updateStatus = (status, uuid) => {

    this.setState({
      dt: {
        ...this.state.dt,
        uuid,
        status,
      },
      alert: null,
    })

    setTimeout(() => {
      this.simpan();
    }, 500);

  }

  render() {
    var data = this.state.data

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          // return index + 1

          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: 'created_at',
        text: 'Tanggal Booking',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        formatter: (cell, obj) => {
          return HariTanggalJam(obj.created_at)
        }
      },
      {
        dataField: 'data_pengguna.nama',
        text: 'Nama',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      // {
      //   dataField: 'data_paket_wisata.nama',
      //   text: 'Nama Paket Wisata',
      //   headerAlign: 'center',
      //   align: 'center',
      //   sort: false
      // },
      {
        dataField: 'kode_booking',
        text: 'Kode Booking',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'jenis',
        text: 'Jenis',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'total',
        text: 'Total Biaya',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        formatter: function (cell, row) {
          if (row.total) {
            return formatRupiah(row.total)
          }
        }
      },
      {
        dataField: 'status',
        text: 'Status',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        // formatter: function (cell, row) {
        //   return row.detail.replace(/(<([^>]+)>)/gi, "")
        // },
      },
      {
        dataField: 'aksi',
        text: 'Aksi',
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: function (cell, row) {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                size="xs"
                color="info"
                className="btn-icon mr-2"
                title="Detail Artikel"
                onClick={() => {
                  this.getDetail(row.uuid)
                  // this.setState({
                  //   // dt: row,
                  //   detailData: row,
                  //   status: {
                  //     ...this.state.status,
                  //     modalDetail: true
                  //   }
                  // })
                }}
              >
                <i className="fa fa-eye"></i>
              </Button>
              {row.status.toLowerCase() == 'menunggu konfirmasi' && row.jenis.toLowerCase() !== 'offline' ? (
                <Button
                  size="xs"
                  color="success"
                  className="btn-icon mr-2"
                  onClick={() => {
                    // this.ubahData(row)
                    let curStatus = row.status;
                    let nextStatusConfirm = 'Konfirmasi'
                    let nextStatusCancel = 'Ditolak'

                    this.setState({
                      alert: (
                        <ReactBootstrapSweetalert
                          showConfirm={false}
                          showCancel={true}
                          showClose={true}
                          // confirmBtnText={'Konfirmasi'}
                          cancelBtnText={'Tutup'}
                          // confirmBtnBsStyle="success"
                          cancelBtnBsStyle="default"
                          type="warning"
                          title="Update status booking"
                          // onConfirm={this.onConfirm}
                          // onCancel={this.onCancel}
                          // onCancel={() => this.updateStatus(nextStatusCancel) }
                          // onClose={() => this.setState({ alert: null })}
                          onCancel={() => this.setState({ alert: null })}
                        >
                          Proses ini tidak dapat dibatalkan, pastikan anda memilih status yang sesuai

                          <div className='mt-3'>
                            <Button
                              size="md"
                              color="success"
                              className="btn-icon mr-2"
                              onClick={() => this.updateStatus(nextStatusConfirm, row.uuid)}
                            >
                              Konfirmasi
                            </Button>
                            <Button
                              size="md"
                              color="warning"
                              className="btn-icon mr-2"
                              onClick={() => this.updateStatus(nextStatusCancel, row.uuid)}
                            >
                              Tolak
                            </Button>
                          </div>
                        </ReactBootstrapSweetalert>
                      )
                    });
                  }}
                >
                  <i className="fa fa-refresh"></i>
                </Button>
              ) : ''}

            </div>
          )
        }.bind(this)
      }
    ]

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  {/* <div className="card-header">
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <Button
                          size="sm"
                          color="success"
                          className="btn-square"
                          onClick={() => {
                            this.setState({
                              judulForm: 'Tambah Booking Wisata',
                              dt: '',
                            });
                            this.bukaForm();
                          }}
                        >
                          Tambah Data
                        </Button>
                      </div>
                    </div>
                  </div> */}
                  <div className="card-body datatable-react">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="row">
                          <div className="col-sm-12" align="center">
                            <img
                              alt="loading"
                              src={require("../../assets/images/tes-loading.gif")}
                              style={{
                                borderRadius: "10px",
                                width: '100px'
                              }} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-sm-5 mb-3">
                            <Select
                              classNamePrefix="select"
                              onChange={e => {
                                this.setState({ kategoriStatus: e })
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                              }}
                              value={this.state.kategoriStatus}
                              name="kategoriStatus"
                              options={[
                                {
                                  value: '',
                                  label: 'Semua',
                                },
                                {
                                  value: 'Menunggu Konfirmasi',
                                  label: 'Menunggu Konfirmasi',
                                },
                                {
                                  value: 'Konfirmasi',
                                  label: 'Konfirmasi',
                                },
                                {
                                  value: 'Batal',
                                  label: 'Batal',
                                },
                                {
                                  value: 'Ditolak',
                                  label: 'Ditolak',
                                },
                              ]}
                              placeholder={"Pilih Berdasarkan Status Booking"}
                              isClearable
                            />
                          </div>
                          <div className="col-sm-7 mb-3"></div>

                          <div className="col-sm-5 mb-3">
                            <Select
                              classNamePrefix="select"
                              onChange={e => {
                                this.setState({ filterObjekWisata: e })
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                              }}
                              // defaultValue={this.state.stpStat}
                              value={this.state.filterObjekWisata}
                              name="filterwisata"
                              options={this.state.listWisata}
                              placeholder={"Pilih Berdasarkan Objek Wisata"}
                              isClearable
                            />
                          </div>
                          <div className="col-sm-7  mb-3"></div>

                          <div className="col-sm-5 text-right">
                            <input
                              type="text"
                              className="form-control"
                              style={{ marginBottom: '15px' }}
                              id="cariTable"
                              placeholder="Cari berdasarkan nama atau kode booking"
                              defaultValue={this.state.textCari}
                              onChange={(e) => {
                                this.setState({ textCari: e.target.value })
                              }}
                              onKeyPress={this.cariData}
                            />
                            <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                              <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                                this.setState({
                                  textCari: '',
                                  loading: true
                                });
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                                document.getElementById('cariTable').value = ''
                              }} ></span>
                            </div>
                          </div>
                          <div className="col-sm-7" style={{ marginBottom: '15px' }}>
                            <Button
                              size="sm"
                              color="info"
                              className="btn-square"
                              onClick={() => {
                                this.setState({
                                  textCari: document.getElementById("cariTable").value,
                                  loading: true
                                });
                                setTimeout(
                                  function () {
                                    this.fetch();
                                  }.bind(this),
                                  500
                                );
                              }}
                            >
                              <i className="fa fa-search"></i>
                            </Button>
                          </div>

                          <div className='col-sm-12'>
                            <BootstrapTable
                              keyField="id"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judulForm}</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                  Status Booking
                </Label>
                <Col sm="9">
                  <select id='status' name='status' className='form-control' defaultValue={this.state.dt.status} onChange={(e) => {
                    console.log(e);
                    this.setState({
                      dt: {
                        ...this.state.dt,
                        status: e.target.value
                      }
                    })
                  }} required>
                    <option value="">Pilih Status Booking</option>
                    <option value="Menunggu Konfirmasi">Menunggu Konfirmasi</option>
                    <option value="Konfirmasi">Konfirmasi</option>
                    <option value="Batal">Batal</option>
                    <option value="Ditolak">Ditolak</option>
                  </select>
                </Col>
              </FormGroup>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnSubmit}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal Detail */}
        <Modal
          open={this.state.status.modalDetail}
          styles={{
            modal: {
              width: '90%'
            }
          }}
          onClose={() => {
            let status = { ...this.state.status };
            status.modalDetail = false;
            this.setState({ status });
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Detail Data Booking Wisata</h5>
          </div>
          <div className="modal-body">
            <table>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Jenis Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData && this.state.detailData.jenis ? this.state.detailData.jenis : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Nama Pengunjung</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData && this.state.detailData.data_pengguna ? this.state.detailData.data_pengguna.nama : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Status Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData ? this.state.detailData.status : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>No. HP</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData && this.state.detailData.data_pengguna ? this.state.detailData.data_pengguna.no_hp : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Email</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData && this.state.detailData.data_pengguna ? this.state.detailData.data_pengguna.email : '-'}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Kode Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData ? this.state.detailData.kode_booking : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.detailData ? Tanggal(this.state.detailData.created_at) : ''}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Total Harga</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                {/* <td>{this.state.detailData ? this.state.detailData.jumlah + ' x ' + this.state.detailData.harga + ' = ' + this.state.detailData.total  : ''}</td> */}
                <td>{this.state.detailData && this.state.detailData.total ? formatRupiah(this.state.detailData.total) : '-'}</td>
              </tr>
              {/* <tr>
                <td style={{ fontWeight: 'bold' }}>Thumbnail</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>
                  <img
                    src={API_URL_WISATA + this.state.dt.thumbnail}
                    alt="thumbnail promo"
                    style={{
                      maxHeight: '150px',
                      maxWidth: '250px'
                    }}
                  />
                </td>
              </tr> */}
              {/* <tr>
                <td style={{ fontWeight: 'bold' }}>Deskripsi</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td></td>
              </tr> */}
              {/* <tr>
                <td style={{ fontWeight: 'bold' }}>Detail Booking</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td></td>
              </tr> */}
            </table>

            <h6 className='mt-3' style={{ fontWeight: 'bold' }}>Detail Wisata:</h6>
            <div className='ml-3'>
              {this.state.detailData && this.state.detailData.data_booking_detail_wisata ? (
                this.state.detailData.data_booking_detail_wisata.map((ex, index) => {
                  return (
                    <>
                      <table>
                        <tr>
                          <td style={{ fontWeight: 'bold' }} >Wisata {index + 1}</td>
                          <td style={{ width: "50px" }} className="text-center"></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Nama</td>
                          <td style={{ width: "50px" }} className="text-center">:</td>
                          <td>{ex.data_wisata.nama}</td>
                        </tr>
                        <tr>
                          <td>Tanggal Kunjungan</td>
                          <td style={{ width: "50px" }} className="text-center">:</td>
                          <td>{Tanggal(ex.tanggal_kunjungan)}</td>
                        </tr>
                        <tr>
                          <td>Jumlah Pengunjung</td>
                          <td style={{ width: "50px" }} className="text-center">:</td>
                          <td>{ex.jumlah_orang}</td>
                        </tr>
                        <tr>
                          <td>Status Check In</td>
                          <td style={{ width: "50px" }} className="text-center">:</td>
                          <td>{ex.status}</td>
                        </tr>
                      </table>

                      <h6 className='mt-3' style={{ fontWeight: 'bold' }}>Detail Paket Wisata:</h6>
                      {ex.data_booking_detail_paket_wisata && ex.data_booking_detail_paket_wisata.length > 0 ? (
                        <table className='table table-border-black table-bordered table-stripes table-hovered mb-3'>
                          <thead>
                            <tr>
                              <td className='text-center' style={{ width: '50px', fontWeight: 'bold' }}>No. </td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Nama Paket</td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Tanggal Kunjungan</td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Jumlah Orang</td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Harga</td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Kuantitas</td>
                              <td className='text-center' style={{ fontWeight: 'bold' }}>Total</td>
                            </tr>
                          </thead>
                          <tbody>
                            {ex.data_booking_detail_paket_wisata ? (
                              ex.data_booking_detail_paket_wisata.map((e, i) => {
                                return (
                                  <tr>
                                    <td className='text-center'>{i + 1}</td>
                                    <td className='text-center'>{e.data_paket ? e.data_paket.nama : ''}</td>
                                    <td className='text-center'>{e ? Tanggal(e.tanggal_kunjungan) : ''}</td>
                                    <td className='text-center'>{e ? e.jumlah_orang : ''}</td>
                                    <td className='text-center'>{e ? new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(e.harga) : ''}</td>
                                    <td className='text-center'>{e ? e.qty : ''}</td>
                                    <td className='text-center'>{e ? new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(e.total) : ''}</td>
                                  </tr>
                                )
                              })
                            ) : ''}
                          </tbody>
                        </table>
                      ) : (
                        <p className='text-center mb-1'>Tidak ada data paket wisata</p>
                      )}

                    </>
                  )
                })
              ) : ''}
            </div>

            <h6 className='mt-4' style={{ fontWeight: 'bold' }}>Riwayat Status Booking:</h6>
            {this.state.detailData && this.state.detailData.data_riwayat_status && this.state.detailData.data_riwayat_status.length > 0 ? (
              <table className='table table-border-black table-bordered'>
                <thead>
                  <tr>
                    <td className='text-center' style={{ fontWeight: 'bold' }}>Tanggal</td>
                    <td className='text-center' style={{ fontWeight: 'bold' }}>Status</td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.detailData.data_riwayat_status.map((e) => {
                    return (
                      <tr>
                        <td className='text-center'>{HariTanggalJam(e.created_at)}</td>
                        <td className='text-center'>{e.status}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <p className='text-center mb-1'>Tidak ada riwayat status booking</p>
            )}

          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                let status = { ...this.state.status };
                status.modalDetail = false;
                this.setState({
                  status,
                  modalDetail: null,
                });
              }}
              style={{ fontWeight: 600 }}
            >
              Tutup
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

BookingWisata.propTypes = {

};

export default BookingWisata;