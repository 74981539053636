import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, GetWisata, ID_DESA, PostWisata, DeleteWisata, API_URL_WISATA } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';
import NumberFormat from 'react-number-format';
import ReactDatetimeClass from 'react-datetime';

const frmDex = {
  uuid: null,
  uuid_desa_wisata: null,
  nama: '',
  deskripsi: '',
  diskon: '',
  tanggal_mulai: null,
  tanggal_expired: null,
  link_gambar: '',
}

const dateFormat = require('dateformat');

class PromoWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        formDetail: false,
        btnSubmit: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,

      judulForm: 'Tambah Promo Wisata',

      foto_desa: null,
      uuid_desa_wisata: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      textCari: '',
    };
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });
    // this.setState({
    //   data: [
    //     {
    //       judul: 'Tempat Menginap',
    //       detail: `1. Wisma\n2. Villa`,
    //     }
    //   ]
    // })
  }

  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true })

    let link = "";
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    if (this.state.textCari !== '') {
      link = link + '&q=' + this.state.textCari
    }

    GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
      if (res.results) {
        this.setState({
          uuid_desa_wisata: res.results.uuid
        });

        let uuid_desa_wisata = res.results.uuid

        let bodyData = {
          uuid_desa_wisata,
          q: this.state.textCari,
        }

        PostWisata("desa-wisata/promo", null, bodyData, dtkat => {
          console.log(dtkat);
          // console.log(dtkat.data);

          if (dtkat.data) {
            this.setState({
              data: dtkat.data.results.data,
              pagination: dtkat.data.results,
              loading: false
            })
            // Pagination
            this.pagination(dtkat.data.results);
          }
          this.setState({ loading: false })

        });
      }
    });

  };

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
        formDetail: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  ubahData = data => {
    console.log(data);

    const dt = {
      uuid: data.uuid,
      uuid_desa_wisata: data.uuid_desa_wisata,
      nama: data.nama,
      deskripsi: data.deskripsi,
      diskon: data.diskon,
      tanggal_mulai: new Date(data.tanggal_mulai),
      tanggal_expired: new Date(data.tanggal_expired),
      link_gambar: data.link_gambar,
    }

    console.log(dt);

    let link_file = data.link_gambar

    this.setState({
      dt,
      link_file,
      judulForm: "Ubah Promo Wisata",
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  }

  hapusData = (uuid) => {
    this.setState({
      alert: null,
      loadingPreview: true
    })
    // console.log(uuid);

    DeleteWisata('desa-wisata/promo/delete', uuid, (res) => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: 'success',
          basicTitle: 'Data Promo Wisata',
          pesanAlert: 'Berhasil hapus data'
        });
        this.fetch()
      } else {
        this.setState({
          show: true,
          basicType: 'danger',
          basicTitle: 'Data Promo Wisata',
          pesanAlert: 'Gagal hapus data'
        });
      }
      this.setState({ alert: null })
      this.setState({ loadingPreview: false })
    })

  }

  cariData = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        textCari: e.target.value,
      });
      setTimeout(
        function () {
          this.fetch();
        }.bind(this),
        500
      );
    }
  };

  simpan = ((e) => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    let complete = false
    if (this.state.link_file !== '') {
      complete = true
    }

    if (complete) {
      GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
        if (res.results) {
          this.setState({
            uuid_desa_wisata: res.results.uuid
          });

          let uuid_desa_wisata = res.results.uuid
          let link_gambar = this.state.link_file
          let diskon = this.state.dt.diskon.toString()

          // console.log(uuid_desa_wisata);
          // console.log(document.getElementById('nama').value);
          // console.log(diskon);
          // console.log(diskon.replace(/[^\d]/g, ''));

          let bodyData = {
            uuid: document.getElementById("uuid").value,
            uuid_desa_wisata,
            nama: document.getElementById("nama").value,
            deskripsi: document.getElementById("deskripsi").value,
            diskon: diskon.replace(/[^\d]/g, ''),
            tanggal_mulai: dateFormat(
              this.state.dt.tanggal_mulai,
              'yyyy-mm-dd'
            ),
            tanggal_expired: dateFormat(
              this.state.dt.tanggal_expired,
              'yyyy-mm-dd'
            ),
            link_gambar,
          }
          console.log(bodyData);

          let psn = "";
          let resstat = 204;
          let metode = "create";

          if (bodyData.uuid === "") {
            psn = "tambah";
            resstat = 201;
            bodyData.uuid = null;
          } else {
            psn = "ubah";
            resstat = 201;
            metode = "update";
          }

          PostWisata("desa-wisata/promo/" + metode, bodyData.uuid, bodyData, res => {
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Promo Wisata",
                pesanAlert: "Berhasil " + psn + " Data",
                loadingPreview: false,
                status: {
                  ...this.state.status,
                  form: false
                }
              });
              this.fetch()
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Promo Wisata",
                pesanAlert: "Gagal " + psn + " Data",
                loadingPreview: false,
              });
            }
          })

        }
      })
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Promo Wisata",
        pesanAlert: "Silahkan masukkan gambar thumbnail",
        loadingPreview: false,
      });
    }

  })

  render() {
    var data = this.state.data

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          // return index + 1

          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: 'nama',
        text: 'Judul Promo',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'diskon',
        text: 'Diskon',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'tanggal_mulai',
        text: 'Tanggal Mulai',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'tanggal_expired',
        text: 'Tanggal Expired',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'aksi',
        text: 'Aksi',
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: function (cell, row) {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                size="xs"
                color="info"
                className="btn-icon mr-2"
                onClick={() => this.setState({
                  dt: row,
                  status: {
                    ...this.state.status,
                    formDetail: true,
                  }
                })}
              >
                <i className="fa fa-eye"></i>
              </Button>
              <Button
                size="xs"
                color="success"
                className="btn-icon mr-2"
                onClick={() => this.ubahData(row)}
              >
                <i className="fa fa-pencil"></i>
              </Button>
              <Button
                size="xs"
                color="danger"
                className="btn-icon mr-2"
                onClick={() => {
                  this.setState({
                    alert: (
                      <ReactBootstrapSweetalert
                        showCancel
                        confirmBtnText="Hapus"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        type="danger"
                        title="Yakin ingin hapus data ?"
                        onCancel={() => {
                          this.setState({
                            alert: null
                          });
                        }}
                        onConfirm={() => this.hapusData(row.uuid)}
                      >
                        Proses ini tidak dapat dibatalkan!
                      </ReactBootstrapSweetalert>
                    )
                  });
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          )
        }.bind(this)
      }
    ]

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        <div className="row mt-4">
          {this.state.loading ? (
            <div className="col-sm-12" align="center">
              <img
                alt="loading"
                src={require("../../assets/images/tes-loading.gif")}
                style={{
                  borderRadius: "10px",
                  width: '100px'
                }} />
            </div>
          ) : (
            <>
              <div className="col-sm-5 text-right">
                <input
                  type="text"
                  className="form-control"
                  style={{ marginBottom: '15px' }}
                  id="cariTable"
                  placeholder="Cari berdasarkan judul promo"
                  defaultValue={this.state.textCari}
                  onChange={(e) => {
                    this.setState({ textCari: e.target.value })
                  }}
                  onKeyPress={this.cariData}
                />
                <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                  <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                    this.setState({
                      textCari: '',
                      loading: true
                    });
                    setTimeout(
                      function () {
                        this.fetch();
                      }.bind(this),
                      500
                    );
                    document.getElementById('cariTable').value = ''
                  }} ></span>
                </div>
              </div>
              <div className="col-sm-2" style={{ marginBottom: '15px' }}>
                <Button
                  size="sm"
                  color="info"
                  className="btn-square"
                  onClick={() => {
                    this.setState({
                      textCari: document.getElementById("cariTable").value,
                      loading: true
                    });
                    setTimeout(
                      function () {
                        this.fetch();
                      }.bind(this),
                      500
                    );
                  }}
                >
                  <i className="fa fa-search"></i>
                </Button>
              </div>
              <div className="col-sm-5 text-right">
                <Button
                  size="sm"
                  color="success"
                  className="btn-square"
                  onClick={() => {
                    this.setState({
                      judulForm: 'Tambah Promo Wisata',
                      dt: frmDex,
                      link_file: '',
                    });
                    this.bukaForm();
                  }}
                >
                  Tambah Data
                </Button>
              </div>
              <div className='col-sm-12'>
                <BootstrapTable
                  keyField="id"
                  data={data}
                  columns={columns}
                />
                <div className="pull-right text-white">
                  {this.state.awal}
                  {this.state.sebelum}
                  {this.state.hal.map(dt => {
                    return dt;
                  })}
                  {this.state.setelah}
                  {this.state.akhir}
                </div>
              </div>

            </>
          )}
        </div>

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judulForm}</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

              <div className="form-row">
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Judul Promo</Label>
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Promo HUT RI"
                    defaultValue={this.state.dt.nama}
                    minLength={3}
                    maxLength={50}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          nama: e.target.value
                        }
                      })
                    }}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Deskripsi</Label>
                  <textarea name="deskripsi" id="deskripsi" className='form-control' defaultValue={this.state.dt.deskripsi} rows="4" required></textarea>
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Diskon</Label>
                  <NumberFormat
                    id="penghasilan"
                    placeholder="Contoh : 50000"
                    thousandSeparator={true}
                    prefix={'Rp. '}
                    className="form-control"
                    value={this.state.dt.diskon}
                    onChange={(e) => {
                      this.setState({
                        dt: {
                          ...this.state.dt,
                          diskon: e.target.value,
                        }
                      })
                    }}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Tanggal Mulai</Label>
                  <ReactDatetimeClass
                    id="tanggal_mulai"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt.tanggal_mulai}
                    value={this.state.dt.tanggal_mulai}
                    onChange={(e) => {
                      if (typeof e._isValid !== 'undefined') {
                        let tgl = e.format('YYYY-MM-DD');

                        this.setState({
                          dt: {
                            ...this.state.dt,
                            tanggal_mulai: new Date(tgl)
                          }
                        })
                      }
                    }}
                    inputProps={{ required: true }}
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Tanggal Selesai</Label>
                  <ReactDatetimeClass
                    id="tanggal_expired"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt.tanggal_expired}
                    value={this.state.dt.tanggal_expired}
                    onChange={(e) => {
                      if (typeof e._isValid !== 'undefined') {
                        let tgl = e.format('YYYY-MM-DD');

                        this.setState({
                          dt: {
                            ...this.state.dt,
                            tanggal_expired: new Date(tgl)
                          }
                        })
                      }
                    }}
                    inputProps={{ required: true }}
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Gambar Thumbnail</Label>
                  <Dropzone
                    onDrop={(files) => {

                      if (files.length === 1) {
                        let type = files[0].type.split('/');

                        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {
                          let bodyFormData = new FormData();
                          bodyFormData.append('files', files[0]);

                          this.setState({ files })

                          PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                            console.log(data);

                            if (data.data.results) {
                              this.setState({
                                link_file: data.data.results
                              });
                            }
                          });

                        } else { // Jika bukan pdf
                          this.setState({
                            show: true,
                            basicType: 'warning',
                            basicTitle: 'Terjadi Kesalahan',
                            pesanAlert: 'Masukkan file dengan format gambar'
                          });
                        }
                      }
                    }}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            minHeight: '100px',
                            paddingTop: '40px',
                            padding: '5px 5px 5px 5px',
                            border: '1px solid #d2d2d2'
                          }}
                        >
                          <input {...getInputProps()} />
                          <div
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            {this.state.link_file ? (
                              <img
                                // src={this.state.link_file}
                                src={API_URL_WISATA + this.state.link_file}
                                alt="thumbnail promo"
                                style={{
                                  // width: '470px',
                                  maxHeight: '300px',
                                  maxWidth: '500px'
                                }}
                              />
                            ) : (
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>

            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnSubmit}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal */}
        <Modal
          open={this.state.status.formDetail}
          onClose={this.tutupForm}
          styles={{ modal: { width: '55%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div className="modal-header">
            <h4 className="modal-title">Detail Promo Wisata</h4>
          </div>
          <div className="modal-body">

            <table>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Judul Promo</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.nama}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Deskripsi</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.deskripsi}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Diskon</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.diskon}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Mulai Promo</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.tanggal_mulai}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Tanggal Selesai Promo</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt.tanggal_expired}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Gambar Thumbnail</td>
                <td className='text-center' style={{ width: '50px' }}>:</td>
                <td>{this.state.dt && this.state.dt.link_gambar ? (
                  <img
                    // src={this.state.link_file}
                    src={API_URL_WISATA + this.state.dt.link_gambar}
                    alt="thumbnail promo"
                    style={{
                      maxHeight: '150px',
                      maxWidth: '250px'
                    }}
                  />
                ) : ''}</td>
              </tr>
            </table>

          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-default" onClick={this.tutupForm}>
              Tutup
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

PromoWisata.propTypes = {

};

export default PromoWisata;