import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// koneksi
import { GetWisata, PostWisata, DeleteWisata } from "../../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../../../function/Format";

const frmDef = {
  uuid: "",
  balasan: '',
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset:false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      akun: DataToken(sessionStorage.getItem('access_token')),
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    
    PostWisata("wisata/ulasan" + link, null, {
			uuid_desa_wisata: this.state.akun.sub.uuid_desa_wisata,
      q: this.state.textCari
		}, dtkat => {
      this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results, loading: false });
      this.pagination(dtkat.data.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  
  // Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
  
    this.state.status.btnForm = true;
    this.forceUpdate();

    let add = {
      uuid: this.state.dt.uuid,
      balasan: document.getElementById("balasan").value,
    };

    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (add.uuid === "") {
      psn = "Tambah";
      resstat = 201;
      add.uuid = null;
    } else {
      psn = "Ubah";
      resstat = 201;
      metode = "update";
    }
    
    PostWisata("wisata/ulasan/" + metode, add.uuid, add, res => {
      this.state.status.btnForm = false;
      this.state.status.form = false;
      this.forceUpdate();
      if (res.status === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Ulasan",
          pesanAlert: "Berhasil Balas Ulasan"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Ulasan",
          pesanAlert: "Gagal Balas Ulasan"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================
  
  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    GetWisata("wisata/ulasan/show", id, data => {
      this.setState({
        dt: {
          uuid: data.results.uuid,
          balasan: data.results.balasan,
        },
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
    });
  };
  
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    DeleteWisata("wisata/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Wisata",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Wisata",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {

    return (
      <>
        <Button
          size="md"
          color="info"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Balas Ulasan",
              statUbah: false
            });
            this.ubahData(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"> {row.balasan?'Ubah Balasan':'Balas'}</i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Akun",
              statUbah: false
            });
            this.konfirmHapus(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
        &nbsp;&nbsp; */}
      </>
    );
  };

  
  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  render() {
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      balasan: '',
    };
    
    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "data_pengguna.nama",
        text: "Nama Pengguna",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "data_pengguna.email",
        text: "Email Pengguna",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
	  {
        dataField: "data_wisata.nama",
        text: "Nama Wisata",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "rating",
        text: "Rating",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "ulasan",
        text: "Ulasan",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
	  {
        dataField: "balasan",
        text: "Balasan",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        formatter: this.tombolAksi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-5 text-right">
                      <input
                        type="text"
                        className="form-control"
                        style={{ marginBottom: '15px' }}
                        id="cariTable"
                        placeholder="Cari data ulasan..."
                        defaultValue={this.state.textCari}
                        onChange={(e) => {
                          this.setState({ textCari: e.target.value })
                        }}
                        onKeyPress={this.cariData}
                      />
                      <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                        <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            textCari: '',
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                          document.getElementById('cariTable').value = ''
                        }} ></span>
                      </div>
                    </div>
                    <div className="col-md-7" style={{ marginBottom: '15px' }}>
                      <Button
                        size="sm"
                        color="info"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            textCari: document.getElementById("cariTable").value,
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    {this.state.loading ? (
                      <div className='col-12 datatable-react'>
                        <div className="col-sm-12" align="center">
                          <img
                            alt="loading"
                            src={require("../../../assets/images/tes-loading.gif")}
                            style={{
                              borderRadius: "10px",
                              width: '100px'
                            }} />
                        </div>
                      </div>
                    ) : data.length > 0 ? (
                      <>
                        <div className='col-12 datatable-react'>
                          <BootstrapTable keyField="id" data={data} columns={columns} />
                          <div className="pull-right text-white">
                            {this.state.awal}
                            {this.state.sebelum}
                            {this.state.hal.map(dt => {
                              return dt;
                            })}
                            {this.state.setelah}
                            {this.state.akhir}
                          </div>
                        </div>
                      </>
                    ) : (
                      <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

				<FormGroup className="row">
					<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
						Balasan
					</Label>
					<Col sm="9">
						<textarea className='form-control' name="balasan" id="balasan" cols="30" defaultValue={this.state.dt.balasan} rows="4" required />
					</Col>
				</FormGroup>
              </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
