import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { Get, JENIS_DUSUN, Post } from '../../function/Koneksi';
import { DataToken } from '../../function/Format';

const ModalSPPD = ({ data, optionData, setAlert, isOpen, setIsOpen, statusRefresh, setStatusRefresh, propsData, kiosk }) => {
	const dateFormat = require('dateformat');

	const currentDate = new Date()

	Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
	}

	const frmDex = {
		uuid: '',
		id_jenis: "",
		nik: "",
		
		// pemberi_perintah: "",
		nama_pegawai: "",
		pangkat_golongan: "",
		jabatan_instansi: "",
		tingkat_biaya_perjalanan: "",
		maksud_perjalanan: "",
		alat_transportasi: "",
		tempat_berangkat: "",
		tempat_tujuan: "",
		lama_perjalanan: "",
		tanggal_berangkat: new Date(),
		tanggal_kembali: (currentDate.addDays(1)),
		sumber_anggaran: '',
		// waktu_mulai: "",
		kode_rekening: "",
		ket: "",

		status_ttd: '',
		jabatan_ttd: '',
		nama_ttd: '',
		nip_ttd: '',
		gol_ttd: '',
	}

	const initFormData = {
		listPerangkat: optionData.listPerangkat,
		dataKades: optionData.dataKades,
		tmpListPerangkat: optionData.tmpListPerangkat,
		accButtonPerangkatDesa: optionData.accButtonPerangkatDesa,
		listPerangkatDesa: optionData.listPerangkatDesa,
		ttdSelected: optionData.ttdSelected,
		jenisTtd: optionData.jenisTtd ? optionData.jenisTtd : {value: 'internal', label: 'internal'},
		golTtd: null,
		nikStat: null,
		status: {
			form: false,
			btnForm: false,
			btnAksi: [],
			dataPenduduk: "none",
		},
		show: false,
		basicTitle: "",
		basicType: "default",
		pesanAlert: "",
	}

	const [formData, setFormData] = useState(initFormData)
	const [props, setProps] = useState(propsData)
	const [kioskMode, setKioskMode] = useState(kiosk)
	const [dt, setDt] = useState(data ? data : frmDex)
	const [draggable, setDraggable] = useState({
		isDown: false,
		startY: null,
		scrollUp: null,
	})

	useEffect(() => {
		if (document.getElementById("modalForm")) {
			document.getElementById("modalForm").reset()
		}

		setDt(data ? data : frmDex)
		setFormData(data ? {
			...initFormData,
			nikStat: optionData.nikStat,
			status: optionData.status,
		} : initFormData)
	}, [isOpen])

	const handleInputChangeNIK = (e, fld) => {
		if (e && kioskMode == false) {
			let nikStat = { value: e, label: e };

			Post("penduduk", null, { nik: e }, datanik => {
				let listPerangkat = [];
				let tmpListPerangkat = {}
				datanik.data.results.data.forEach(dt => {
					listPerangkat.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
					tmpListPerangkat = {
						...tmpListPerangkat,
						[dt.id]: dt
					}
				});

				switch (fld) {
					case "nik":
						setFormData({
							...formData,
							listPerangkat,
							tmpListPerangkat,
							nikStat
						})
						break;

					default:
						break;
				}

			});

		}
	};

	const changeInputText = (e, fld) => {
		setDt({
			...dt,
			[fld]: e.target.value,
		})
	};

	const getPenduduk = (e, sel, jns) => {
		if (e) {
			let getNik = e.value

			const tmpDetailPenduduk = formData.tmpListPerangkat[getNik]
			console.log(formData.tmpListPerangkat);
			console.log(tmpDetailPenduduk);

			// pelapor
			setFormData({
				...formData,
				nikStat: e,
				status: {
					...formData.status,
					dataPenduduk: "block",
				},
			})

			// Hanya dapat set nama pegawai, sisanya dari kolom input teks
			setDt({
				...dt,
				nik: tmpDetailPenduduk.nik,
				nama_pegawai: tmpDetailPenduduk.data_penduduk.nama,
				// pangkat_golongan: tmpDetailPenduduk.tempat_lahir,
				jabatan_instansi: tmpDetailPenduduk.data_jabatan.nama,
				// pendidikan_terakhir: tmpDetailPenduduk.data_pendidikan_terakhir.nama,
				// agama: tmpDetailPenduduk.data_agama.nama,
				// status_perkawinan: tmpDetailPenduduk.status_perkawinan,
				// jenis_kelamin: tmpDetailPenduduk.jk,
				// alamat: tmpDetailPenduduk.alamat,
				// kewarganegaraan: 'WNI',
			})

		} else {
			switch (jns) {
				case "perangkat":
					setDt({
						...dt,
						nik: '',
						nama_pegawai: '',
						jabatan_instansi: '',
					})
					setFormData({
						...formData,
						status: {
							...formData.status,
							dataPenduduk: "none"
						},
						nikStat: null
					})
					break;
				default:
					break;
			}
		}
	};

	const gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_berangkat":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
					setDt({
						...dt,
						tanggal_berangkat: new Date(tgl)
					})
        }
        break;
      case "tanggal_kembali":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
					setDt({
						...dt,
						tanggal_kembali: new Date(tgl)
					})
        }
        break;
      default:
        // no default
        break;
    }
  };

	const simpan = (e) => {
		e.preventDefault();

		if (kioskMode) {
			props.setLoadingPreview(true)
		}

		const oneDay = 24 * 60 * 60 * 1000;
		let tgl_brkt = new Date(dt.tanggal_berangkat);
		let tgl_kmbli = new Date(dt.tanggal_kembali);
		const lama_perjalanan = Math.round(Math.abs((tgl_brkt - tgl_kmbli) / oneDay));

		let detail_surat = {
			// pemberi_perintah: dt.pemberi_perintah,
			nama_pegawai: dt.nama_pegawai,
			pangkat_golongan: dt.pangkat_golongan,
			jabatan_instansi: dt.jabatan_instansi,
			tingkat_biaya_perjalanan: dt.tingkat_biaya_perjalanan,
			maksud_perjalanan: dt.maksud_perjalanan,
			alat_transportasi: dt.alat_transportasi,
			tempat_berangkat: dt.tempat_berangkat,
			tempat_tujuan: dt.tempat_tujuan,
			lama_perjalanan: lama_perjalanan + " hari",
			tanggal_berangkat: dateFormat(new Date(dt.tanggal_berangkat), 'yyyy-mm-dd'),
			tanggal_kembali: dateFormat(new Date(dt.tanggal_kembali), 'yyyy-mm-dd'),
			sumber_anggaran: dt.sumber_anggaran,
			kode_rekening: dt.kode_rekening,
			ket: dt.ket,
			nik: dt.nik,
		}

		let getLoggedData = DataToken(sessionStorage.getItem('access_token'));

		let jabatan_ttd = "Camat"
		let status_ttd = null
		let nama_ttd = dt.nama_ttd
		let nip_ttd = dt.nip_ttd
		let gol_ttd = dt.gol_ttd

		if (formData.jenisTtd.value == "internal") {
			detail_surat.uuidTtd = formData.ttdSelected.uuid

			jabatan_ttd = formData.ttdSelected.jabatan_pengganti ? formData.ttdSelected.jabatan_pengganti : formData.ttdSelected.jabatan
			status_ttd = formData.ttdSelected.status
			nama_ttd = formData.ttdSelected.nama
			nip_ttd = formData.ttdSelected.nip
			gol_ttd = null
		}

		let bodyRaw = {
			uuid: dt.uuid,
			id_jenis: props.location.state.uuid,
			id_pembuat: getLoggedData.sub.uuid,
			tanggal_surat: dateFormat(new Date(), 'yyyy-mm-dd'),
			detail_surat,
			jenis_ttd: formData.jenisTtd.value,
			status_ttd,
			jabatan_ttd,
			nama_ttd,
			nip_ttd,
			gol_ttd,
		}
		
		// Validasi
		let validasiPelapor = formData.nikStat;

		if (validasiPelapor) {
			console.log(bodyRaw);

			let psn = "";
			let resstat = 204;
			let metode = "store";
			if (bodyRaw.uuid === "") {
				psn = "Tambah";
				resstat = 201;
				bodyRaw.uuid = null;
			} else {
				psn = "Ubah";
				resstat = 200;
				metode = "update";
			}
			Post(
				"surat-internal/" + metode,
				bodyRaw.uuid,
				bodyRaw,
				res => {

					// For testing
					if (props.resSubmit) {
						props.resSubmit(res.status)
					}

					setFormData({
						...formData,
						status: {
							...formData.status,
							btnForm: false,
							form: false,
						}
					})
					setIsOpen(false)

					// Tidak ada untuk kiosk
					if (res.status === resstat) {
						const alertData = {
							show: true,
							basicType: "success",
							basicTitle: "Data Surat Perintah Perjalanan Dinas",
							pesanAlert: "Berhasil " + psn + " Data",
							successAlert: true,
						}
						setAlert(alertData)

						// Refresh list
						setStatusRefresh(true)
					} else {
						const alertData = {
							show: true,
							basicType: "danger",
							basicTitle: "Data Surat Perintah Perjalanan Dinas",
							pesanAlert: "Gagal " + psn + " Data"
						}
						setAlert(alertData)
					}
				}
			);
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			setAlert(alertData)
		}
	};

	return (
		<Modal
			classNames={{ overlay: 'card-scrollable' }}
			open={isOpen}
			onClose={() => {
				setIsOpen(false)
			}}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			style={{
				maxwidth: 'unset'
			}}
		>
			<Form
				id="modalForm"
				data-testid="form-create"
				className="theme-form"
				onSubmit={simpan} >
					<div
						onMouseDown={(e) => {
							let slider = document.querySelector('.card-scrollable');
							setDraggable({
								isDown: true,
								startY: e.pageY - slider.offsetTop,
								scrollUp: slider.scrollTop,
							})
						}}
						onMouseLeave={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseUp={() => {
							setDraggable({
								...draggable,
								isDown: false
							})
						}}
						onMouseMove={(e) => {
							e.preventDefault();

							let slider = document.querySelector('.card-scrollable');

							if (!draggable.isDown) {
								return;
							}

							const y = e.pageY - slider.offsetTop;
							const startY = draggable.startY;
							const walk = y - startY;
							slider.scrollTop = draggable.scrollUp - walk;
						}}
					>
				<div className="modal-header">
					<h5 className="modal-title">{dt.uuid == '' ? 'Tambah' : 'Edit'} Surat Perintah Perjalanan Dinas</h5>
				</div>
				<div className="modal-body">
					<p>
						Data identitas akan terisi secara otomatis saat NIK dipilih.
						Jika ada ketidak sesuaian data, silakan ubah di data master
						penduduk.
					</p>
					<input
						className="form-control"
						id="uuid"
						type="hidden"
						placeholder=""
						defaultValue={dt.uuid}
					/>
					{/* <FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							Pemberi Perintah
						</Label>
						<Col sm="9">
							<input
								data-testid="pemberi_perintah"
								name="pemberi_perintah"
								className="form-control"
								id="pemberi_perintah"
								type="text"
								required
								placeholder="Contoh : Kepala Desa B"
								defaultValue={dt.pemberi_perintah}
								value={dt.pemberi_perintah}
								onChange={e => changeInputText(e, "pemberi_perintah")}
							/>
						</Col>
					</FormGroup> */}
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							Jenis Surat
						</Label>
						<Col sm="9">
							<div data-testid="selectJenisSurat">
								<Select
									inputId="jenis_surat"
									classNamePrefix="select"
									onChange={e => {
										setFormData({
											...formData,
											jenisTtd: e
										})
										// Jika eksternal, list perangkat yg tersedia hanya yg jabatannya kades
										// #continuethis
									}}
									defaultValue={formData.jenisTtd}
									value={formData.jenisTtd}
									name="nik"
									options={[
										{
											value: 'internal',
											label: 'internal',
										},
										{
											value: 'eksternal',
											label: 'eksternal',
										},
									]}
									placeholder="Pilih Jenis Surat"
									// onInputChange={e => handleInputChangeNIK(e, 'nik')}
									// isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							Perangkat
						</Label>
						<Col sm="9">
							<div data-testid="selectNIK">
								<Select
									inputId="nik"
									classNamePrefix="select"
									onChange={e => {
										getPenduduk(e, "nik", "perangkat");
									}}
									defaultValue={formData.nikStat}
									value={formData.nikStat}
									name="nik"
									options={formData.jenisTtd.value == 'internal' ? formData.listPerangkat : formData.dataKades}
									placeholder="Pilih Perangkat"
									// onInputChange={e => handleInputChangeNIK(e, 'nik')}
									isClearable
								/>
							</div>
						</Col>
					</FormGroup>
					<div style={{ display: formData.status.dataPenduduk }}>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Nama Pegawai
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_pegawai"
									type="text"
									placeholder="Nama Pegawai"
									value={dt.nama_pegawai}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								Jabatan
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="jabatan_instansi"
									type="text"
									placeholder="Jabatan"
									value={dt.jabatan_instansi}
									readOnly
									style={{ backgroundColor: "#c9d1d8" }}
									disabled
								/>
							</Col>
						</FormGroup>
					</div>
					<FormGroup className="row">
						<Label
							className="col-sm-3 col-form-label"
							htmlFor="nik"
						>
							Pangkat Golongan
						</Label>
						<Col sm="9">
							<input
								data-testid="pangkat_golongan"
								name="pangkat_golongan"
								className="form-control"
								id="pangkat_golongan"
								type="text"
								// required
								placeholder="Pangkat Golongan"
								defaultValue={dt.pangkat_golongan}
								value={dt.pangkat_golongan}
								onChange={e => changeInputText(e, "pangkat_golongan")}
							/>
						</Col>
					</FormGroup>

					<div className="form-row">
						<Col md="12 mb-3">
							<Label>Maksud Perjalanan</Label>
							<textarea
								data-testid="maksud_perjalanan"
								name="maksud_perjalanan"
								className="form-control"
								id="maksud_perjalanan"
								type="text"
								required
								placeholder="Maksud Perjalanan"
								defaultValue={dt.maksud_perjalanan}
								value={dt.maksud_perjalanan}
								onChange={e => changeInputText(e, "maksud_perjalanan")}
							></textarea>
						</Col>
					</div>
					<div className="form-row">
						<Col md="4 mb-3">
							<Label>Tempat Berangkat</Label>
							<input
								data-testid="tempat_berangkat"
								name="tempat_berangkat"
								className="form-control"
								id="tempat_berangkat"
								type="text"
								required
								placeholder="Tempat Berangkat"
								defaultValue={dt.tempat_berangkat}
								value={dt.tempat_berangkat}
								onChange={e => changeInputText(e, "tempat_berangkat")}
							/>
						</Col>
						<Col md="4 mb-3">
							<Label>Tempat Tujuan</Label>
							<input
								data-testid="tempat_tujuan"
								name="tempat_tujuan"
								className="form-control"
								id="tempat_tujuan"
								type="text"
								required
								placeholder="Tempat Tujuan"
								defaultValue={dt.tempat_tujuan}
								value={dt.tempat_tujuan}
								onChange={e => changeInputText(e, "tempat_tujuan")}
							/>
						</Col>
						<Col md="4 mb-3">
							<Label>Alat Transportasi</Label>
							<input
								data-testid="alat_transportasi"
								name="alat_transportasi"
								className="form-control"
								id="alat_transportasi"
								type="text"
								required
								placeholder="Contoh : Darat, Laut, atau Udara"
								defaultValue={dt.alat_transportasi}
								value={dt.alat_transportasi}
								onChange={e => changeInputText(e, "alat_transportasi")}
							/>
						</Col>
					</div>
					{/* <div className="form-row">
						<Col md="6 mb-3">
							<Label>Alat Transportasi</Label>
							<input
								data-testid="alat_transportasi"
								name="alat_transportasi"
								className="form-control"
								id="alat_transportasi"
								type="text"
								required
								placeholder="Alat Transportasi"
								defaultValue={dt.alat_transportasi}
								value={dt.alat_transportasi}
								onChange={e => changeInputText(e, "alat_transportasi")}
							/>
						</Col>
						<Col md="6 mb-3">
							<Label>Lama Perjalanan</Label>
							<input
								data-testid="lama_perjalanan"
								name="lama_perjalanan"
								className="form-control"
								id="lama_perjalanan"
								type="text"
								required
								placeholder="Contoh : 3 hari, 1 minggu"
								defaultValue={dt.lama_perjalanan}
								value={dt.lama_perjalanan}
								onChange={e => changeInputText(e, "lama_perjalanan")}
							/>
						</Col>
					</div> */}
					<div className="form-row">
						<Col md="6 mb-3">
							<Label>Tanggal Berangkat</Label>
							<Datetime
								inputProps={{ 
									"data-testid": "tanggal_berangkat", 
									placeholder: 'Tanggal Berangkat', 
									required: true
								}}
								// data-testid="tanggal_berangkat"
								inputId="tanggal_berangkat"
								name="tanggal_berangkat"
								locale="id-ID"
								timeFormat={false}
								dateFormat="DD/MM/yyyy"
								input={true}
								closeOnTab={true}
								initialValue={(dt.tanggal_berangkat)}
								value={(dt.tanggal_berangkat)}
								onChange={e => {
									gantiTgl(e, 'tanggal_berangkat')
								}}
							/>
						</Col>
						<Col md="6 mb-3">
							<Label>Tanggal Kembali</Label>
							<Datetime
								inputProps={{ 
									"data-testid": "tanggal_kembali", 
									placeholder: 'Waktu Kembali', 
									required: true 
								}}
								// data-testid="tanggal_kembali"
								inputId="tanggal_kembali"
								name="tanggal_kembali"
								locale="id-ID"
								dateFormat="DD/MM/yyyy"
								timeFormat={false}
								input={true}
								closeOnTab={true}
								initialValue={dt.tanggal_kembali}
								value={dt.tanggal_kembali}
								onChange={e => {
									gantiTgl(e, 'tanggal_kembali')
								}}
							/>
						</Col>
					</div>

					<div className="form-row">
						<Col md="4 mb-3">
							<Label>Tingkat Biaya Perjalanan</Label>
							<input
								data-testid="tingkat_biaya_perjalanan"
								name="tingkat_biaya_perjalanan"
								className="form-control"
								id="tingkat_biaya_perjalanan"
								type="text"
								required
								placeholder="Tingkat Biaya Perjalanan"
								defaultValue={dt.tingkat_biaya_perjalanan}
								value={dt.tingkat_biaya_perjalanan}
								onChange={e => changeInputText(e, "tingkat_biaya_perjalanan")}
							/>
						</Col>
						<Col md="4 mb-3">
							<Label>Sumber Anggaran</Label>
							<input
								data-testid="sumber_anggaran"
								name="sumber_anggaran"
								className="form-control"
								id="sumber_anggaran"
								type="text"
								required
								placeholder="Sumber Anggaran"
								defaultValue={dt.sumber_anggaran}
								value={dt.sumber_anggaran}
								onChange={e => changeInputText(e, "sumber_anggaran")}
							/>
						</Col>
						<Col md="4 mb-3">
							<Label>Kode Rekening</Label>
							<input
								data-testid="kode_rekening"
								name="kode_rekening"
								className="form-control"
								id="kode_rekening"
								type="text"
								required
								placeholder="Kode Rekening"
								defaultValue={dt.kode_rekening}
								value={dt.kode_rekening}
								onChange={e => changeInputText(e, "kode_rekening")}
							/>
						</Col>
					</div>
					<div className="form-row">
						<Col md="12 mb-3">
							<Label>Keterangan</Label>
							<textarea
								data-testid="ket"
								name="ket"
								className="form-control"
								id="ket"
								type="text"
								required
								placeholder="Keterangan tambahan"
								defaultValue={dt.ket}
								value={dt.ket}
								onChange={e => changeInputText(e, "ket")}
							></textarea>
						</Col>
					</div>

					<div className="form-row">
						{formData.jenisTtd.value == 'internal' ? (
							<Col md="12 mb-3">
								<Label><b>Pilih Penandatangan Surat</b></Label>
								<table className="table table-bordered table-stripes table-hovered">
									<thead>
										<tr>
											<th>No</th>
											<th>Jabatan</th>
											<th>Jabatan Pengganti</th>
											<th>Nama</th>
											<th>NIP</th>
											<th>Status</th>
											<th>Aksi</th>
										</tr>
									</thead>
									<tbody>
										{formData.listPerangkatDesa.map((data, i) => {
											return (
												<tr key={i}>
													<td>{i + 1}</td>
													<td>{data.jabatan}</td>
													<td>{data.jabatan_pengganti ? data.jabatan_pengganti : '-'}</td>
													<td>{data.nama}</td>
													<td>{data.nip ? data.nip : '-'}</td>
													<td>{data.status ? data.status : '-'}</td>
													<td>
														<Button
															data-testid={"btnPilihPerangkat-" + i}
															size="xs"
															onClick={() => {
																// this.pilihPerangkatDesa(data.uuid, i)
																let temp = formData.accButtonPerangkatDesa.map((x) => '0');
																temp[i] = '1';

																setFormData({
																	...formData,
																	accButtonPerangkatDesa: temp,
																	ttdSelected: data,
																})
															}}
															color={
																formData.accButtonPerangkatDesa[i] === '0' ? 'danger' : 'default'
															}
															className="btn-icon"
														>
															{formData.accButtonPerangkatDesa[i] === '1' ? 'Dipilih' : 'Pilih'}
														</Button>
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</Col>
						) : (
							<>
								<Col md="12">
									<Label><b>Data Penandatangan</b></Label>
								</Col>
								<Col md="4 mb-3">
									<Label>Nama</Label>
									<input
										data-testid="nama_ttd"
										name="nama_ttd"
										className="form-control"
										id="nama_ttd"
										type="text"
										required
										placeholder="Nama Penandatangan"
										defaultValue={dt.nama_ttd}
										value={dt.nama_ttd}
										onChange={e => changeInputText(e, "nama_ttd")}
									/>
								</Col>
								<Col md="4 mb-3">
									<Label>NIP</Label>
									<input
										data-testid="nip_ttd"
										name="nip_ttd"
										className="form-control"
										id="nip_ttd"
										type="text"
										required
										placeholder="NIP Penandatangan"
										defaultValue={dt.nip_ttd}
										value={dt.nip_ttd}
										onChange={e => changeInputText(e, "nip_ttd")}
									/>
								</Col>
								<Col md="4 mb-3">
									<Label>Golongan</Label>
									<input
										data-testid="gol_ttd"
										name="gol_ttd"
										className="form-control"
										id="gol_ttd"
										type="text"
										required
										placeholder="Golongan Penandatangan"
										defaultValue={dt.gol_ttd}
										value={dt.gol_ttd}
										onChange={e => changeInputText(e, "gol_ttd")}
									/>
								</Col>
							
							</>
						)}

					</div>

				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-warning"
						onClick={() => {
							// Tutup modal
							setFormData({
								...formData,
								status: {
									...formData.status,
									form: false
								}
							})
							setIsOpen(false)
						}}
					>
						Tutup
					</button>
					<button
						data-testid="btnSubmitCreate"
						type="submit"
						className="btn btn-primary-custom"
						disabled={formData.status && formData.status.btnForm}
					>
						Simpan
					</button>
				</div>
				</div>
			</Form>
		</Modal>
	);
}

export default ModalSPPD;
