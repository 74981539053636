import { JENIS_DESA, JENIS_DUSUN } from './Koneksi';

const jwt = require('jsonwebtoken');

export function DataToken(token) {
	const decoded = jwt.decode(token, { complete: true });
	if (decoded != null) {
		return decoded.payload;
	} else {
		return null
	}
	
}

export function generateAlamat(tmpDetailPenduduk) {
	// New set alamat
	let alamat = tmpDetailPenduduk.alamat;
	const dataKK = tmpDetailPenduduk.data_kk
	const dataDesa = tmpDetailPenduduk.data_keldes

	if (dataKK !== null && dataDesa !== null) {
		const dataKecamatan = dataDesa.data_kecamatan
		const dataKabkota = dataKecamatan.data_kabkota
		const dataProvinsi = dataKabkota.data_provinsi

		if (JENIS_DESA.toLowerCase() !== 'kelurahan') {
			if (dataKK.data_rt) {
				const dataRT = dataKK.data_rt
				const dataRW = dataRT.data_rw ? dataRT.data_rw : {nama: '-'}
				const dataDusun = dataRW.data_dusun ? dataRW.data_dusun : {nama: '-'}

				// Jika tidak ada RT
				if (dataRT.id === '0') {
					alamat = `${JENIS_DUSUN} ${dataDusun.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
				} else {
					alamat = `RT/RW : ${dataRT.nama}/${dataRW.nama}, ${JENIS_DUSUN} ${dataDusun.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
				}
			}
		} else { // Jika kelurahan tanpa dusun
			if (dataKK.data_rt) {
				const dataRT = dataKK.data_rt
				const dataRW = dataRT.data_rw ? dataRT.data_rw : {nama: '-'}

				alamat = `RT/RW : ${dataRT.nama}/${dataRW.nama}, ${JENIS_DESA} ${dataDesa.nama}, Kecamatan ${dataKecamatan.nama}, ${dataKabkota.nama}, ${dataProvinsi.nama}`
			}
		}
	}

	return alamat
}

export function numberSeparator(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function TanggalToISO(tgl) {
	let ta = tgl.split('/');
	return new Date(ta[2] + '-' + ta[1] + '-' + ta[0]).toISOString();
}

export function Tanggal(tgl) {
	var d = new Date(tgl);
	// var month = new Array();
	var month = [];
	month[0] = 'Januari';
	month[1] = 'Februari';
	month[2] = 'Maret';
	month[3] = 'April';
	month[4] = 'Mei';
	month[5] = 'Juni';
	month[6] = 'Juli';
	month[7] = 'Agustus';
	month[8] = 'September';
	month[9] = 'Oktober';
	month[10] = 'November';
	month[11] = 'Desember';
	var tanggal = d.getDate();
	var bln = month[d.getMonth()];
	var tahun = d.getFullYear();

	return tanggal + ' ' + bln + ' ' + tahun;
}

export function Hari(tgl) {
	var d = new Date(tgl);
	// var days = new Array();
	var days = [];
	days[0] = 'Minggu';
	days[1] = 'Senin';
	days[2] = 'Selasa';
	days[3] = 'Rabu';
	days[4] = 'Kamis';
	days[5] = "Jum'at";
	days[6] = 'Sabtu';
	// var tanggal = Tanggal(d);
	var hari = days[d.getDay()];
	return hari;
}

export function HariTanggal(tgl) {
	var d = new Date(tgl);
	// var days = new Array();
	var days = [];
	days[0] = 'Minggu';
	days[1] = 'Senin';
	days[2] = 'Selasa';
	days[3] = 'Rabu';
	days[4] = 'Kamis';
	days[5] = "Jum'at";
	days[6] = 'Sabtu';
	var tanggal = Tanggal(d);
	var hari = days[d.getDay()];
	return hari + ', ' + tanggal;
}

export function HariTanggalJam(tgl) {
	var d = new Date(tgl);
	// var days = new Array();
	var days = [];
	days[0] = 'Minggu';
	days[1] = 'Senin';
	days[2] = 'Selasa';
	days[3] = 'Rabu';
	days[4] = 'Kamis';
	days[5] = "Jum'at";
	days[6] = 'Sabtu';
	var tanggal = Tanggal(d);
	var hari = days[d.getDay()];
	var menit = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
	var jam = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
	var waktu = jam + ':' + menit;
	return hari + ', ' + tanggal + ' ' + waktu;
}
export function formatYMD(inputDate) {
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
        // Months use 0 index.
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    }
}
export function formatRupiah(nominal) {
	let fix_nominal = parseInt(nominal)

	return new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR"
	  }).format(fix_nominal);
}