import React, { Component } from "react";

import BootstrapTable from "react-bootstrap-table-next";
import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import axios from 'axios';


// koneksi
import { PostMarketplace, Post, Get, Delete, cekLogin,ID_DESA } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../function/Format";
// import DatePicker from "reactdatepicker";


const frmDef = {
  uuid: "",
  nik: "",
  kk: "",
  nama: "",
  username: "",
  password: "",
  no_hp: null,
};

class PendaftaranAkun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset: false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      loading: false,
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      formPenduduk: "none",
      photoIndex: 0,
      isOpen: false,
      idVerifikasi: null,
      dataReset: {
        uuid: '',
        username: '',
      },
      dataVerifikasi:{
        ktp:'',
        kk:'',
        selfie:'',
      },
      loadingKTP: false,
      loadingKK: false,
      loadingSelfie: false,
      // Cari Data
			textCari: '',
      akun: DataToken(sessionStorage.getItem('access_token')),
    };
  }


  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Post("penduduk/list-tidak-ada-pengguna" + link, null, {
			q: this.state.textCari
		}, dtkat => {

      console.log('datanya')
      console.log(dtkat)
      this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results, loading: false });
      this.pagination(dtkat.data.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination; 
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

  // ========================================================================

  
	/// upload file Banner

	processGbr = (d, jenis) => {
    if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
       
        if(jenis === 'ktp'){
          this.setState({
            loadingKTP: true,
            status:{
              ...this.state.status,
              btnForm: true,
            },
          })
        }
        else if(jenis === 'kk'){
          this.setState({
            loadingKK: true,
            status:{
              ...this.state.status,
              btnForm: true,
            },
          })
        }
        else if(jenis === 'selfie'){
          this.setState({
            loadingSelfie: true,
            status:{
              ...this.state.status,
              btnForm: true,
            },
          })
        }

				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				Post('upload-file/'+jenis, null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
              if(jenis === 'ktp'){
                this.setState({
                  foto_desa: uri,
                  foto_info_ktp: '',
                  dataVerifikasi: {
                    ...this.state.dataVerifikasi,
                    ktp: data.data.results,
                  },
                  status:{
                    ...this.state.status,
                    btnForm: false,
                  },
                  loadingKTP: false,
                });
              }
              else if(jenis === 'kk'){
                this.setState({
                  foto_desa: uri,
                  foto_info_ktp: '',
                  dataVerifikasi: {
                    ...this.state.dataVerifikasi,
                    kk: data.data.results,
                  },
                  status:{
                    ...this.state.status,
                    btnForm: false,
                  },
                  loadingKK: false,
                });
              }
              else if(jenis === 'selfie'){
                this.setState({
                  foto_desa: uri,
                  foto_info_ktp: '',
                  dataVerifikasi: {
                    ...this.state.dataVerifikasi,
                    selfie: data.data.results,
                  },
                  status:{
                    ...this.state.status,
                    btnForm: false,
                  },
                  loadingSelfie: false,
                });
              }
						},
						'base64'
					);
				});
			} else {
        if(jenis === 'ktp'){
          this.setState({
            foto_desa: '',
            foto_info_ktp: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
        else if(jenis === 'kk'){
          this.setState({
            foto_desa: '',
            foto_info_kk: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
        else if(jenis === 'selfie'){
          this.setState({
            foto_desa: '',
            foto_info_selfie: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
			}
		} else {
      if(jenis === 'ktp'){
        this.setState({
          foto_desa: '',
          foto_info_ktp: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
      else if(jenis === 'kk'){
        this.setState({
          foto_desa: '',
          foto_info_kk: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
      else if(jenis === 'selfie'){
        this.setState({
          foto_desa: '',
          foto_info_selfie: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
		}
	};

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    //console.log(e);
    if (
      document.getElementById("username").value !== "" &&
      document.getElementById("password").value !== "" &&
      document.getElementById("nohp").value !== "" &&
      document.getElementById("email").value !== "" &&
      this.state.dataVerifikasi.ktp != "" &&
      this.state.dataVerifikasi.kk != "" &&
      this.state.dataVerifikasi.selfie != ""
     ) {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let add = {
        uuid: this.state.dt.uuid,
        nik: this.state.dt.nik,
        kk: this.state.dt.kk,
        username: document.getElementById("username").value,
        password: document.getElementById("password").value,
        id_desa: ID_DESA,
        no_hp: document.getElementById("nohp").value,
        email: document.getElementById("email").value,
      };

      console.log('Data Simpannya')
      console.log(add)
      
      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      
      PostMarketplace("pengguna/register", add.uuid, add, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        //console.log(res);
        if (res === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Pengguna",
            pesanAlert: "Berhasil " + psn + " Data"
          });
          // PostMarketplace("pengguna/register/" + metode, add.uuid, add, res => {
          //   this.state.status.btnForm = false;
          //   this.state.status.form = false;
          //   this.forceUpdate();
          //   //console.log(res);
          //   if (res === resstat) {
          //     this.setState({
          //       show: true,
          //       basicType: "success",
          //       basicTitle: "Data Pengguna",
          //       pesanAlert: "Berhasil " + psn + " Data"
          //     });
          //   } else {
          //     this.setState({
          //       show: true,
          //       basicType: "danger",
          //       basicTitle: "Data Pengguna",
          //       pesanAlert: "Gagal " + psn + " Data"
          //     });
          //   }
          //   this.componentDidMount();
          // });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Pengguna",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Pendaftaran Akun",
        pesanAlert: "Data tidak boleh kosong "
      });
    }
  };
  // ========================================================================


  // ================== simpan data ==================
  
  // ============================== Ubah Data ===============================

  ubahPilihan = (table, data) => {
    //console.log("data");

    //console.log(data);
    // Get(table+'/find', id, (data) => {
    switch (table) {
      // case 'provinsi': this.setState({prvStat:{value:data.id, label:data.nama}}); break;
      case "kecamatan":
        this.getChild(
          {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );
        this.getChild(
          { value: data.data_kabkota.id, label: data.data_kabkota.nama },
          "kab-kota",
          "kab",
          "kecamatan"
        );
        this.setState({
          prvStat: {
            value: data.data_kabkota.data_provinsi.id,
            label: data.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kabkota.id,
            label: data.data_kabkota.nama
          },
          kecStat: { value: data.id, label: data.nama }
        });
        break;
      case "keldes":
        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          { value: data.data_kecamatan.id, label: data.data_kecamatan.nama },
          "kecamatan",
          "kec",
          "keldes"
        );

        this.setState({
          prvStat: {
            value: data.data_kecamatan.data_kabkota.data_provinsi.id,
            label: data.data_kecamatan.data_kabkota.data_provinsi.nama
          },
          kbktStat: {
            value: data.data_kecamatan.data_kabkota.id,
            label: data.data_kecamatan.data_kabkota.nama
          },
          kecStat: {
            value: data.data_kecamatan.id,
            label: data.data_kecamatan.nama
          },
          kelStat: { value: data.id, label: data.nama }
        });
        //console.log(data);
        this.bukaForm();
        break;
      case "rt":
        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          "provinsi",
          "prov",
          "kab-kota"
        );

        this.getChild(
          {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          "kab-kota",
          "kab",
          "kecamatan"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          "kecamatan",
          "kec",
          "keldes"
        );
        this.getChild(
          {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },
          "keldes",
          "kel",
          "dusun"
        );

        this.getChild(
          {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          "dusun",
          "dus",
          "rw"
        );

        this.getChild(
          { value: data.data_rw.id, label: data.data_rw.nama },
          "rw",
          "rw",
          "rt"
        );

        this.setState({
          prvStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .data_provinsi.nama
          },
          kbktStat: {
            value:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .id,
            label:
              data.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota
                .nama
          },
          kecStat: {
            value: data.data_rw.data_dusun.data_keldes.data_kecamatan.id,
            label: data.data_rw.data_dusun.data_keldes.data_kecamatan.nama
          },
          kelStat: {
            value: data.data_rw.data_dusun.data_keldes.id,
            label: data.data_rw.data_dusun.data_keldes.nama
          },

          dusStat: {
            value: data.data_rw.data_dusun.id,
            label: data.data_rw.data_dusun.nama
          },
          rwStat: { value: data.data_rw.id, label: data.data_rw.nama },
          rtStat: { value: data.id, label: data.nama }
        });
        this.bukaForm();
        break;
      default:
        // no default
        break;
    }
    // });
  };
  // ========================================================================
  
  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.state.status.formReset = false
    this.state.status.formVerifikasi = false;
    this.forceUpdate();
  };


  getChild = (e, self, parent, child) => {
    this.state.status.select = true;
    this.forceUpdate();
    this.changeSelectValue(e, self);
    if (e) {
      Get(child + "/" + parent + "/" + e.value + "/list", null, data => {
        let frm = [];
        data.results.forEach(dt => {
          frm.push({ value: dt.id, label: dt.nama });
        });
        switch (child) {
          case "kab-kota":
            this.setState({ frmKbkt: frm, frmKec: [] });
            break;
          case "kecamatan":
            this.setState({ frmKec: frm });
            break;
          case "keldes":
            this.setState({ frmKel: frm });
            break;
          case "dusun":
            this.setState({ frmDus: frm });
            break;
          default:
            // no default
            break;
        }
        this.state.status.select = false;
        this.forceUpdate();
      });
    }
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: e });
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [] });
          break;
        case "kab-kota":
          //console.log(e);
          this.setState({ kbktStat: e });
          this.setState({ kecStat: [] });
          break;
        case "kecamatan":
          this.setState({ kecStat: e });
          this.setState({ kelStat: [] });

          break;
        case "keldes":
          this.setState({ kelStat: e });
          this.setState({ dusStat: [] });

          break;

        case "jabatan":
          this.state.dt.id_jabatan_perangkat = e.value;
          this.setState({ jpStat: e });
          break;
        case "penduduk":
          this.state.dt.nik = e.value;
          this.setState({ pendStat: e });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = e.value;
          this.setState({ statusPegawaiStat: e });
          break;
        case "status_aktif":
          this.state.dt.status = e.value;
          this.setState({ statusAktifStat: e });
          break;

        case "role":
          this.state.dt.id_role = e.value;
          //console.log(e);
          if (e.label !== "Super Admin") {
            this.setState({ roleStat: e, formPenduduk: "" });
          } else {
            this.setState({ roleStat: e, formPenduduk: "none" });
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "provinsi":
          this.setState({ prvStat: [] });
          this.setState({ kbktStat: [], frmKbkt: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "kab-kota":
          this.setState({ kbktStat: [] });
          this.setState({ kecStat: [], frmKec: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "kecamatan":
          this.setState({ kecStat: [] });
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmKel: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });

          break;
        case "keldes":
          this.state.dt.id_rt = 0;
          this.setState({
            kelStat: [],
            frmDus: [],
            dusStat: [],
            frmRw: [],
            rwStat: [],
            frmRt: [],
            rtStat: []
          });
          break;
        case "jabatan":
          this.state.dt.id_jabatan_perangkat = "";
          this.setState({ jpStat: [] });
          break;
        case "penduduk":
          this.state.dt.nik = 0;
          this.setState({ pendStat: [] });
          break;
        case "status_kepegawaian":
          this.state.dt.status_pegawai = "";
          this.setState({ statusPegawaiStat: [] });
          break;
        case "status_aktif":
          this.state.dt.status = 0;
          this.setState({ statusAktifStat: [] });
          break;
        case "role":
          this.state.dt.id_role = "";
          this.setState({ roleStat: [] });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };

  componentDidMount() {
    console.log(this.state.akun.sub.data_role.nama_role);
    this.fetch({ page: null, where: [] });
  }

	ubahData = data => {
		this.setState({
			judul: "Pendaftaran Pengguna",
			editMode: true
		})
		if (data) {
			this.setState({
				dt: {
					uuid: '',
					nik: data.id,
					kk: data.id_kk,
					nama: data.nama,
				},	
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

  render() {
    console.log('cek')
    console.log(this.state.status)
    const { photoIndex, isOpen } = this.state;

    var data = this.state.data;

    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "id",
        text: "NIK",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "id_kk",
        text: "KK",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "nama",
        text: "Nama Lengkap",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
     
      {
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							>
								<i className="fa fa-user-plus"></i>
							</Button>
						</div>
					)
				}.bind(this)
			}
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  {this.state.loading ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                  ) : data.length > 0 ? (
                    <>
                      <div className='row'>
                        <div className="col-md-5 text-right">
                          <input
                            type="text"
                            className="form-control"
                            style={{ marginBottom: '15px' }}
                            id="cariTable"
                            placeholder="Cari berdasarkan nama kegiatan atau tahun perencanaan"
                            defaultValue={this.state.textCari}
                            onChange={(e) => {
                              this.setState({ textCari: e.target.value })
                            }}
                            onKeyPress={this.cariData}
                          />
                          <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                            <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                              this.setState({
                                textCari: '',
                                loading: true
                              });
                              setTimeout(
                                function () {
                                  this.fetch();
                                }.bind(this),
                                500
                              );
                              document.getElementById('cariTable').value = ''
                            }} ></span>
                          </div>
                        </div>
                        <div className="col-md-7" style={{ marginBottom: '15px' }}>
                          <Button
                            size="sm"
                            color="info"
                            className="btn-square"
                            onClick={() => {
                              this.setState({
                                textCari: document.getElementById("cariTable").value,
                                loading: true
                              });
                              setTimeout(
                                function () {
                                  this.fetch();
                                }.bind(this),
                                500
                              );
                            }}
                          >
                            <i className="fa fa-search"></i>
                          </Button>
                        </div>
                        {data.length > 0 ? (
                          <>
                            <div className="col-md-12">
                              <BootstrapTable
                                keyField="id"
                                data={data}
                                columns={columns}
                              />
                              <div className="pull-right text-white">
                                {this.state.awal}
                                {this.state.sebelum}
                                {this.state.hal.map(dt => {
                                  return dt;
                                })}
                                {this.state.setelah}
                                {this.state.akhir}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="col-md-12">
                            <h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />
              <div style={{ backgroundColor:'#666666', padding:'10px'}}>
								<h6><b><font color='white'>Data Pengguna</font></b></h6>
							</div>
              
							<div className='col-sm-12'>
								&nbsp;
							</div>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  NIK
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nik"
                    type="text"
                    placeholder="masukan nik"
                    required
                    defaultValue={this.state.dt.nik}
                    readOnly
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No. KK
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="kk"
                    type="text"
                    placeholder="masukan no kk"
                    required
                    defaultValue={this.state.dt.kk}
                    readOnly
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="masukan nama"
                    required
                    defaultValue={this.state.dt.nama}
                    readOnly
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Username
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="masukan username"
                    required
                    defaultValue={this.state.dt.username}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Password
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    placeholder="masukan password"
                    required
                    defaultValue={this.state.dt.password}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  No Hp
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nohp"
                    type="number"
                    placeholder="masukan Nomor Hp"
                    required
                    defaultValue={this.state.dt.no_hp}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Email
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="masukan Email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>

							<div style={{ backgroundColor:'#666666', padding:'10px'}}>
								<h6><b><font color='white'>Dokumen Verifikasi</font></b></h6>
							</div>
              
							<div className='col-sm-12'>
								&nbsp;
							</div>
              
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto KTP
								</Label>
								<Col sm="9">
                  {this.state.loadingKTP ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'ktp')} maxFiles={12}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              minHeight: '100px',
                              paddingTop: '40px',
                              padding: '5px 5px 5px 5px',
                              border: '1px solid #d2d2d2'
                            }}
                          >
                            <input {...getInputProps()} />
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <p className="text-center">
                                {this.state.dataVerifikasi.ktp ? (
                                  <img
                                    src={this.state.dataVerifikasi.ktp}
                                    style={{
                                      // minHeight: '50px',
                                      // minWidth: '50px'
                                      width: '470px'
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Label
                                    className="col-form-label text-center"
                                    htmlFor="inputEmail3"
                                  >
                                    Drag 'n' drop some files here, or click to select
                                    files
                                  </Label>
                                )}
                                {this.state.foto_info_ktp ? this.state.foto_info_ktp : ''}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>   
                    )
                  }
								</Col>
							</FormGroup>

              
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto KK
								</Label>
								<Col sm="9">
                  {this.state.loadingKK ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'kk')} maxFiles={12}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                minHeight: '100px',
                                paddingTop: '40px',
                                padding: '5px 5px 5px 5px',
                                border: '1px solid #d2d2d2'
                              }}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <p className="text-center">
                                  {this.state.dataVerifikasi.kk ? (
                                    <img
                                      src={this.state.dataVerifikasi.kk}
                                      style={{
                                        // minHeight: '50px',
                                        // minWidth: '50px'
                                        width: '470px'
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Label
                                      className="col-form-label text-center"
                                      htmlFor="inputEmail3"
                                    >
                                      Drag 'n' drop some files here, or click to select
                                      files
                                    </Label>
                                  )}
                                  {this.state.foto_info_kk ? this.state.foto_info_kk : ''}
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )
                  }
								</Col>
							</FormGroup>
              
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Foto Selfie
								</Label>
								<Col sm="9">
                  {this.state.loadingSelfie ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'selfie')} maxFiles={12}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              style={{
                                minHeight: '100px',
                                paddingTop: '40px',
                                padding: '5px 5px 5px 5px',
                                border: '1px solid #d2d2d2'
                              }}
                            >
                              <input {...getInputProps()} />
                              <div
                                style={{
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <p className="text-center">
                                  {this.state.dataVerifikasi.selfie ? (
                                    <img
                                      src={this.state.dataVerifikasi.selfie}
                                      style={{
                                        // minHeight: '50px',
                                        // minWidth: '50px'
                                        width: '470px'
                                      }}
                                      alt=""
                                    />
                                  ) : (
                                    <Label
                                      className="col-form-label text-center"
                                      htmlFor="inputEmail3"
                                    >
                                      Drag 'n' drop some files here, or click to select
                                      files
                                    </Label>
                                  )}
                                  {this.state.foto_info_selfie ? this.state.foto_info_selfie : ''}
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )
                  }
								</Col>
							</FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default PendaftaranAkun;
