import React, { Component } from "react";

import { Link} from "react-router-dom";
// import { DataToken } from "../../../function/Format";
import { JENIS_DESA } from "../../../function/Koneksi";

class MenuWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          {/* Dashboard */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + '/wisata/dashboard'}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/wisata/kelola-wisata"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-map"></i> Wisata
                </button>
              </div>
            </Link>
          </div>
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + '/wisata/booking'}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-bell"></i> Booking
                </button>
              </div>
            </Link>
          </div>
          {/* Informasi */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/wisata/informasi"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-info"></i> Informasi
                </button>
              </div>
            </Link>
          </div>
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/wisata/ulasan"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-comment"></i> Ulasan
                </button>
              </div>
            </Link>
          </div>
          {/* Galeri */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/wisata/galeri"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-image"></i> Galeri
                </button>
              </div>
            </Link>
          </div>
          {/* Artikel */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/wisata/artikel"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-primary-custom"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="icofont icofont-newspaper"></i> Artikel
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuWisata;
