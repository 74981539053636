import React, { Component } from "react";
import { Link } from 'react-router-dom'

import BootstrapTable from "react-bootstrap-table-next";
import Dropzone from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

import Lightbox from "react-image-lightbox";

import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// koneksi
import { GetWisata, PostWisata, DeleteWisata,API_URL_WISATA, cekLogin, ID_DESA } from "../../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { DataToken } from "../../function/Format";
// import DatePicker from "reactdatepicker";
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],  
	iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

var HtmlToReactParser = require("html-to-react").Parser;

const frmDef = {
  uuid: "",
  uuid_desa_wisata: '',
  nama: "",
  email: null,
  alamat: null,
  thumbnail: null,
  latitude: 0,
	longitude: 0,
  deskripsi_singkat: '',
  link_fb: '',
  fanspage: '',
  link_ig: '',
  nomor_wa: '',
  link_youtube: '',
  link_messanger: '',
  link_tiktok: '',
  fasilitas: '',
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false,
        formVerifikasi: false,
        statGet: false,
        formReset:false
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDef,
      loading: false,
      alert: null,
      alert_create: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // =========== Select 2 ============
      isOpen: false,
      statusAkun: null,
      akun: DataToken(sessionStorage.getItem('access_token')),
      // Maps Location
      curStaticLocation: [0, 0],
			currentLocation: [0, 0],
      dataFoto:{
        link:'',
      },
      loadingFoto: false,
      create_id: null,
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
      alert_create: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    this.setState({ loading: true })
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    
    PostWisata("wisata" + link, null, {
			uuid_desa_wisata: this.state.akun.sub.uuid_desa_wisata,
      q: this.state.textCari
		}, dtkat => {
      this.setState({ data: dtkat.data.results.data, pagination: dtkat.data.results, loading: false });
      this.pagination(dtkat.data.results);
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  
  // Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

  // ========================================================================

  
  /// upload file Foto

	processGbr = (d, jenis) => {
    if (d.length === 1) {
			let type = d[0].type.split('/');
			if (
				type[1] === 'jpg' ||
				type[1] === 'jpeg' ||
				type[1] === 'png' ||
				type[1] === 'bmp' ||
				type[1] === 'tiff' ||
				type[1] === 'webp'
			) {
       
        if(jenis === 'thumbnail'){
          this.setState({
            loadingFoto: true,
            status:{
              ...this.state.status,
              btnForm: true,
            },
          })
        }
        
				let bodyFormData = new FormData();
				bodyFormData.append('files', d[0]);

				PostWisata('upload-file/wisata', null, bodyFormData, (data) => {
					Resizer.imageFileResizer(
						d[0],
						300,
						300,
						type[1],
						100,
						0,
						(uri) => {
              if(jenis === 'thumbnail'){
                this.setState({
                  foto_desa: uri,
                  foto_info_foto: '',
                  dataFoto: {
                    ...this.state.dataFoto,
                    link: data.data.results,
                  },
                  status:{
                    ...this.state.status,
                    btnForm: false,
                  },
                  loadingFoto: false,
                });
              }
						},
						'base64'
					);
				});
			} else {
        if(jenis === 'thumbnail'){
          this.setState({
            foto_desa: '',
            foto_info_foto: <div className="text-danger font-size-10">Type file tidak valid</div>
          }); 
        }
			}
		} else {
      if(jenis === 'thumbnail'){
        this.setState({
          foto_desa: '',
          foto_info_foto: <div className="text-danger font-size-10">Silahkan masukan satu gambar</div>
        });
      }
		}
	};

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
  
    if (
      this.state.dataFoto.link !== "" &&
      this.state.dt.deskripsi_singkat !== "" &&
      this.state.dt.fasilitas !== ""
    ) {
      this.state.status.btnForm = true;
      this.forceUpdate();

      let add = {
        uuid: this.state.dt.uuid,
        uuid_desa_wisata: this.state.akun.sub.uuid_desa_wisata,
        nama: document.getElementById("nama").value,
        email: document.getElementById("email").value,
        alamat: document.getElementById("alamat").value,
        thumbnail: this.state.dataFoto.link,
        latitude: this.state.dt.latitude,
        longitude: this.state.dt.longitude,
        deskripsi_singkat: this.state.dt.deskripsi_singkat,
        link_fb: document.getElementById("link_fb").value,
        fanspage: document.getElementById("fanspage").value,
        link_ig: document.getElementById("link_ig").value,
        nomor_wa: document.getElementById("nomor_wa").value,
        link_youtube: document.getElementById("link_youtube").value,
        link_messanger: document.getElementById("link_messanger").value,
        link_tiktok: document.getElementById("link_tiktok").value,
        fasilitas: this.state.dt.fasilitas,
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      // Slug manual
      // let nama = document.getElementById("nama").value;
      // let repl = nama.replace(/[^a-zA-Z0-9 ]/g,'').toLowerCase();
      // let slug = repl.replace(/[^a-zA-Z0-9]/g,'-');

      if (add.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        add.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 201;
        metode = "update";
      }
      
      PostWisata("wisata/" + metode, add.uuid, add, res => {
        console.log('respon simpan')
        console.log(res)
        // res.data.results.uuid
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          if(metode === 'create'){
            console.log('ini create')
            this.setState({
              alert_create: (
                <SweetAlert
                  confirmBtnText="OK"
                  confirmBtnBsStyle="success"
                  type="success"
                  basicType = "success"
                  title = "Data Wisata"
                  pesanAlert = "Berhasil Tambah Data"
                  showConfirm={false}
                >
                  Berhasil Tambah Data
                  <br/><br/>
                  <Button
                    className="btn btn-success-custom"
                    >
                      <Link
                        onClick={() => {
                        }}
                        to={{
                          pathname: process.env.PUBLIC_URL + "kelola-wisata/detail/"+res.data.results.slug,
                          state: res.data.results,
                        }}
                      >
                        <span style={{ color: "white" }}>OK</span>
                      </Link>
                    </Button>
                </SweetAlert>
              ),
            });
          }
          else{
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Wisata",
              pesanAlert: "Berhasil " + psn + " Data"
            });
          }
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Wisata",
            pesanAlert: "Gagal " + psn + " Data"
          });
        }
        this.componentDidMount();
      });
    } else {
      let message = ''
      if(this.state.dt.fasilitas === ''){
        message = message+'Fasilitas, ';
      }
      if(this.state.dt.deskripsi_singkat === ''){
        message = message+'Deskripsi, ';
      }
      if(this.state.dataFoto.link === ''){
        message = message+'Foto Thumbnail, ';
      }
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Data Penambahan Wisata",
        pesanAlert: "Data "+message+"tidak boleh kosong"
      });
    }
  };
  // ========================================================================
  
  // ============================== Ubah Data ===============================

  ubahData = id => {
    this.state.status.btnAksi = true;
    this.forceUpdate();
    GetWisata("wisata/show", id, data => {
      let lat_edit = 0;
      let long_edit = 0;
      let currentLocation = [0,0];
      if(data.results.latitude || data.results.latitude !== null){
        lat_edit = data.results.latitude
      }
      if(data.results.latitude || data.results.latitude !== null){
        long_edit = data.results.longitude
      }

      if(data.results.latitude === null || data.results.latitude === null){
        navigator.geolocation.getCurrentPosition(function(position) {
          const lat = (position.coords.latitude)
          const long = (position.coords.longitude)
  
          currentLocation = [lat, long]
          this.setState({ currentLocation, curStaticLocation: currentLocation })
          this.forceUpdate()
          
        }.bind(this));
      }
      else{
        currentLocation = [lat_edit, long_edit]
        this.setState({ currentLocation, curStaticLocation: currentLocation })
      }

      this.setState({
        dt: {
          uuid: data.results.uuid,
          nama: data.results.nama,
          email: data.results.email,
          alamat: data.results.alamat,
          latitude: lat_edit,
          longitude: long_edit,
          deskripsi_singkat: data.results.deskripsi_singkat,
          link_fb: data.results.link_fb,
          fanspage: data.results.fanspage,
          link_ig: data.results.link_ig,
          nomor_wa: data.results.nomor_wa,
          link_youtube: data.results.link_youtube,
          link_messanger: data.results.link_messanger,
          link_tiktok: data.results.link_tiktok,
          fasilitas: data.results.fasilitas,
        },
        dataFoto:{
          link:data.results.thumbnail,
        },
      });
      this.state.status.btnAksi = false;
      this.forceUpdate();
      this.bukaForm();
    });
  };

  resetPassword = data => {
    this.setState({
      dataReset:data,
      status:{
        ...this.state.status,
        formReset:true,
      }
    })
  };
  
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    DeleteWisata("wisata/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Wisata",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Wisata",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dataFoto:{
        link:'',
      },
      status:{
        form:false,
        formReset:false,
        formVerifikasi:false,
      },
      dt: frmDef,
    })
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    // Slug Manual
    // let nama = row.nama;
    // let repl = nama.replace(/[^a-zA-Z0-9 ]/g,'').toLowerCase();
    // let slug = repl.replace(/[^a-zA-Z0-9]/g,'-');
    return (
      <>
        <Link to={{ pathname: "/wisata/kelola-wisata/detail/"+row.slug, state: row }}>
          <Button
            size="xs"
            color="info"
            className="btn-icon"
            title='Detail Wisata'
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </Link>
       
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Ubah Wisata",
              statUbah: false
            });
            this.ubahData(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;

        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => {
            this.setState({
              judul: "Hapus Akun",
              statUbah: false
            });
            this.konfirmHapus(row.uuid);
          }}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
       
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button> */}
      </>
    );
  };

  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: e });
          break;

        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "status":
          this.setState({ statusAkun: null });
          break;
        default:
          // no default
          break;
      }
    }
    this.state.status.select = false;
    this.forceUpdate();
  };
  
  componentDidMount() {
    this.fetch({ page: null, where: [] });
    this.getLocation()
  }

  getLocation() {
		if ("geolocation" in navigator) {
      // console.log("Geolocation Available");
			navigator.geolocation.getCurrentPosition(function(position) {
				const lat = (position.coords.latitude)
				const long = (position.coords.longitude)

				const currentLocation = [lat, long]
				this.setState({ currentLocation, curStaticLocation: currentLocation })
				this.forceUpdate()
				
			}.bind(this));
    } else {
      // console.log("Geolocation Not Available");
    }
	}

  render() {
    var data = this.state.data;

    const frmDef = {
      uuid: "",
      uuid_desa_wisata: '',
      nama: "",
      email: null,
      alamat: null,
      latitude: 0,
	    longitude: 0,
      deskripsi_singkat: '',
      link_fb: '',
      fanspage: '',
      link_ig: '',
      nomor_wa: '',
      link_youtube: '',
      link_messanger: '',
      link_tiktok: '',
      fasilitas: '',
    };
    
    const columns = [
      {
        dataField: "no",
        text: "No",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: "nama",
        text: "Nama",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "nomor_wa",
        text: "No. Whatsapp",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "email",
        text: "Email",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "alamat",
        text: "Alamat",
        headerAlign: 'center',
        align: 'center',
        sort: true
      },
      {
        dataField: "aksi",
        text: "Aksi",
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: this.tombolAksi
      }
    ];

    return (
      <div>
        {/* <Breadcrumb title="Penguna" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}
        {this.state.alert_create}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-5 text-right">
                      <input
                        type="text"
                        className="form-control"
                        style={{ marginBottom: '15px' }}
                        id="cariTable"
                        placeholder="Cari berdasarkan nama wisata"
                        defaultValue={this.state.textCari}
                        onChange={(e) => {
                          this.setState({ textCari: e.target.value })
                        }}
                        onKeyPress={this.cariData}
                      />
                      <div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
                        <span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
                          this.setState({
                            textCari: '',
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                          document.getElementById('cariTable').value = ''
                        }} ></span>
                      </div>
                    </div>
                    <div className="col-md-2" style={{ marginBottom: '15px' }}>
                      <Button
                        size="sm"
                        color="info"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            textCari: document.getElementById("cariTable").value,
                            loading: true
                          });
                          setTimeout(
                            function () {
                              this.fetch();
                            }.bind(this),
                            500
                          );
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </Button>
                    </div>
                    <div className="col-md-5 text-right">
                      <Button
                        size="sm"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Wisata",
                            dt: frmDef,
                            dataFoto:{
                              link:'',
                            },
                          });
                          //console.log(this.state.statUbah);
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                    <div className='col-12'>
                      &nbsp;
                    </div>
                    {this.state.loading ? (
                      <div className='col-12 datatable-react'>
                        <div className="col-sm-12" align="center">
                          <img
                            alt="loading"
                            src={require("../../assets/images/tes-loading.gif")}
                            style={{
                              borderRadius: "10px",
                              width: '100px'
                            }} />
                        </div>
                      </div>
                    ) : data.length > 0 ? (
                      <>
                        <div className='col-12 datatable-react'>
                          <BootstrapTable keyField="id" data={data} columns={columns} />
                          <div className="pull-right text-white">
                            {this.state.awal}
                            {this.state.sebelum}
                            {this.state.hal.map(dt => {
                              return dt;
                            })}
                            {this.state.setelah}
                            {this.state.akhir}
                          </div>
                        </div>
                      </>
                    ) : (
                      <h6 className="text-center mb-2 mt-2">Belum ada data</h6>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          size="lg"
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <div size="lg" className="modal-header">
            <h5 className="modal-title">{this.state.judul}</h5>
          </div>
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder="Role"
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Nama Wisata
                  </Label>
                  <input
                    className="form-control"
                    id="nama"
                    type="text"
                    placeholder="Contoh : Air Terjun Batu Dinding"
                    required
                    defaultValue={this.state.dt.nama}
                  />
                </Col>
              
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Email
                  </Label>
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="masukan email"
                    required
                    defaultValue={this.state.dt.email}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    No. Whatsapp
                  </Label>
                  <input
                    className="form-control"
                    id="nomor_wa"
                    type="number"
                    placeholder="masukan no. wa"
                    required
                    min="0"
                    defaultValue={this.state.dt.nomor_wa}
                  />
                </Col>
                
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link FB
                  </Label>
                  <input
                    className="form-control"
                    id="link_fb"
                    type="text"
                    placeholder="masukan link facebook"
                    required
                    defaultValue={this.state.dt.link_fb}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link Fanspage
                  </Label>
                  <input
                    className="form-control"
                    id="fanspage"
                    type="text"
                    placeholder="masukan link fanspage"
                    required
                    defaultValue={this.state.dt.fanspage}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
                
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link Instagram
                  </Label>
                  <input
                    className="form-control"
                    id="link_ig"
                    type="text"
                    placeholder="masukan link instagram"
                    required
                    defaultValue={this.state.dt.link_ig}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link Youtube
                  </Label>
                  <input
                    className="form-control"
                    id="link_youtube"
                    type="text"
                    placeholder="masukan link youtube"
                    required
                    defaultValue={this.state.dt.link_youtube}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link Messanger
                  </Label>
                  <input
                    className="form-control"
                    id="link_messanger"
                    type="text"
                    placeholder="masukan link messanger"
                    required
                    defaultValue={this.state.dt.link_messanger}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="6">
                  <Label htmlFor="inputEmail3">
                    Link Tik-Tok
                  </Label>
                  <input
                    className="form-control"
                    id="link_tiktok"
                    type="text"
                    placeholder="masukan link tik-tok"
                    required
                    defaultValue={this.state.dt.link_tiktok}
                  />
                  <font color='red'><small>* Isi dengan <strong>-</strong> jika tidak ada</small></font>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Alamat
                  </Label>
                  <input
                    className="form-control"
                    id="alamat"
                    type="text"
                    placeholder="masukan alamat"
                    required
                    defaultValue={this.state.dt.alamat}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
								<Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Fasilitas
                  </Label>
									{/* <textarea className='form-control' name="fasilitas" id="fasilitas" cols="30" defaultValue={this.state.dt.fasilitas} rows="4" required /> */}
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        dt:{
                          ...this.state.dt,
                          fasilitas: data,
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    data={this.state.dt.fasilitas}
                    config={{         
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                    }}
                  />
								</Col>
							</FormGroup>
              <FormGroup className="row">
								<Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Deskripsi Singkat
                  </Label>
									{/* <textarea className='form-control' name="deskripsi_singkat" id="deskripsi_singkat" cols="30" defaultValue={this.state.dt.deskripsi_singkat} rows="4" required /> */}
                  <CKEditor
                    onReady={editor => {
                      // Insert the toolbar before the editable area.
                      editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                      );

                      this.editor = editor;
                    }}
                    onError={(error, { willEditorRestart }) => {
                      if (willEditorRestart) {
                        this.editor.ui.view.toolbar.element.remove();
                      }
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log(data);

                      this.setState({
                        dt:{
                          ...this.state.dt,
                          deskripsi_singkat: data,
                        },
                      })
                    }}
                    editor={DecoupledEditor}
                    data={this.state.dt.deskripsi_singkat}
                    config={{         
                      toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                    }}
                  />
								</Col>
							</FormGroup>
              <FormGroup className="row">
								<Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Foto
                  </Label>
                  {this.state.loadingFoto ? (
                    <div className="row">
                      <div className="col-sm-12" align="center">
                        <img
                          alt="loading"
                          src={require("../../assets/images/tes-loading.gif")}
                          style={{
                            borderRadius: "10px",
                            width: '100px'
                          }} />
                      </div>
                    </div>
                    )
                  :
                    (
                      <Dropzone onDrop={(acceptedFiles) => this.processGbr(acceptedFiles,'thumbnail')} maxFiles={12}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            style={{
                              minHeight: '100px',
                              paddingTop: '40px',
                              padding: '5px 5px 5px 5px',
                              border: '1px solid #d2d2d2'
                            }}
                          >
                            <input {...getInputProps()} />
                            <div
                              style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                              }}
                            >
                              <p className="text-center">
                                {this.state.dataFoto.link ? (
                                  <img
                                    src={API_URL_WISATA+this.state.dataFoto.link}
                                    style={{
                                      // minHeight: '50px',
                                      // minWidth: '50px'
                                      width: '470px'
                                    }}
                                    alt=""
                                  />
                                ) : (
                                  <Label
                                    className="col-form-label text-center"
                                    htmlFor="inputEmail3"
                                  >
                                    Drag 'n' drop some files here, or click to select
                                    files
                                  </Label>
                                )}
                                {this.state.foto_info_foto ? this.state.foto_info_foto : ''}
                              </p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>   
                    )
                  }
                </Col>
							</FormGroup>
              <FormGroup className="row">
								<Col sm="12">
                  <Label htmlFor="inputEmail3">
                    Pilih lokasi wisata
                  </Label>
									<MapContainer center={this.state.currentLocation} zoom={12} scrollWheelZoom={false} id="mapwisata" >
										<TileLayer
											attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
											url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
										/>
										<Marker 
											draggable={true}
											position={this.state.currentLocation} 
											eventHandlers={{
												dragend: (event) => {
													const lat = event.target._latlng.lat
													const long = event.target._latlng.lng

													this.setState({
														currentLocation: [lat, long],
														dt: {
															...this.state.dt,
															latitude: lat,
															longitude: long
														}
													})
													this.forceUpdate()
												}
											}}
										>
											<Popup>
												{this.state.dt.nama}
											</Popup>
										</Marker>
									</MapContainer>
								</Col>
							</FormGroup>              
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default BasicTable;
