import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, GetWisata, ID_DESA, PostWisata, DeleteWisata, API_URL_WISATA } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';
import Dropzone from 'react-dropzone';

const frmDex = {
  uuid: null,
  uuid_desa_wisata: null,
  link: '',
  jenis: '',
}

class GaleriWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      files: [],
      link_file: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        btnSubmit: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: frmDex,
      loading: false,
      loadingPreview: false,
      alert: null,
      imagePreview: null,

      judulForm: 'Tambah Galeri Wisata',

      foto_desa: null,
      uuid_desa_wisata: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      textCari: '',
    };
  }

  componentDidMount() {
    this.fetch({ page: null, where: [] });
    // this.setState({
    //   data: [
    //     {
    //       judul: 'Tempat Menginap',
    //       detail: `1. Wisma\n2. Villa`,
    //     }
    //   ]
    // })
  }

  pagination = data => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };

  fetch = (params = {}) => {
    this.setState({ loading: true })

    let link = "";
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }

    if (this.state.textCari !== '') {
      link = link + '&q=' + this.state.textCari
    }

    GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
      if (res.results) {
        this.setState({
          uuid_desa_wisata: res.results.uuid
        });

        let uuid_desa_wisata = res.results.uuid

        let bodyData = {
          uuid_desa_wisata,
          q: this.state.textCari,
        }

        PostWisata("desa-wisata/galeri", null, bodyData, dtkat => {
          console.log(dtkat);
          // console.log(dtkat.data);

          if (dtkat.data) {
            this.setState({
              data: dtkat.data.results.data,
              pagination: dtkat.data.results,
              loading: false
            })
            // Pagination
            this.pagination(dtkat.data.results);
          }
          this.setState({ loading: false })

        });
      }
    });

  };

  bukaForm = () => {
    this.setState({
      dt: frmDex,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  ubahData = data => {
    console.log(data);

    const dt = {
      uuid: data.uuid,
      uuid_desa_wisata: data.uuid_desa_wisata,
      link: data.link,
      jenis: data.jenis,
    }

    let link_file = this.state.link_file
    if (data.jenis === 'Foto') {
      link_file = data.link
    }

    console.log(link_file);

    this.setState({
      dt,
      link_file,
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  }

  hapusData = (uuid) => {
    this.setState({
      alert: null,
      loadingPreview: true
    })
    // console.log(uuid);

    DeleteWisata('desa-wisata/galeri/delete', uuid, (res) => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: 'success',
          basicTitle: 'Data Galeri Wisata',
          pesanAlert: 'Berhasil hapus data'
        });
        this.fetch()
      } else {
        this.setState({
          show: true,
          basicType: 'danger',
          basicTitle: 'Data Galeri Wisata',
          pesanAlert: 'Gagal hapus data'
        });
      }
      this.setState({ alert: null })
      this.setState({ loadingPreview: false })
    })

  }

  cariData = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        textCari: e.target.value,
      });
      setTimeout(
        function () {
          this.fetch();
        }.bind(this),
        500
      );
    }
  };

  simpan = ((e) => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    let complete = false

    // Jika jenis belum diisi
    if (this.state.dt.jenis == '') {
      complete = false
    } else {
      // Jika jenis foto dan belum upload file 
      if (this.state.dt.jenis === 'Foto' && this.state.link_file === '') {
        complete = false
      }
      // Jika jenis video dan belum masukkan link video youtube
      else if (this.state.dt.jenis === 'Video' && this.state.dt.link.trim() === '') {
        complete = false
      } else {
        complete = true
      }

    }


    if (complete) {
      GetWisata('desa-wisata/show/' + ID_DESA, null, (res) => {
        if (res.results) {
          this.setState({
            uuid_desa_wisata: res.results.uuid
          });

          let uuid_desa_wisata = res.results.uuid

          let jenis = document.getElementById('jenis').value
          let link = this.state.link_file
          if (jenis !== 'Foto') {
            var link_yt = this.state.dt.link
            link_yt = link_yt.replace('watch?v=', 'embed/', link_yt)
            link_yt = link_yt.replace('youtu.be/', 'youtube.com/embed/', link_yt)

            link = link_yt
          }

          let bodyData = {
            uuid: document.getElementById("uuid").value,
            uuid_desa_wisata,
            jenis,
            link,
          }
          console.log(bodyData);

          let psn = "";
          let resstat = 204;
          let metode = "create";

          if (bodyData.uuid === "") {
            psn = "tambah";
            resstat = 201;
            bodyData.uuid = null;
          } else {
            psn = "ubah";
            resstat = 201;
            metode = "update";
          }

          PostWisata("desa-wisata/galeri/" + metode, bodyData.uuid, bodyData, res => {
            if (res.status === resstat) {
              this.setState({
                show: true,
                basicType: "success",
                basicTitle: "Data Galeri Wisata",
                pesanAlert: "Berhasil " + psn + " Data",
                loadingPreview: false,
                status: {
                  ...this.state.status,
                  form: false
                }
              });
              this.fetch()
            } else {
              this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Data Galeri Wisata",
                pesanAlert: "Gagal " + psn + " Data",
                loadingPreview: false,
              });
            }
          })

        }
      })
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Galeri Wisata",
        pesanAlert: "Silahkan lengkapi data",
        loadingPreview: false,
      });
    }

  })

  render() {
    var data = this.state.data

    const columns = [
      {
        dataField: "no",
        text: "No",
        isDummyField: true,
        csvExport: false,
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '100px'
        },
        formatter: (key, obj, index) => {
          // return index + 1

          let current_pagenum = this.state.pagination.current_page;
          let total_records_per_page = this.state.pagination.per_page;
          let row_index = (index + 1);
          let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
          return serial_num;
        }
      },
      {
        dataField: 'jenis',
        text: 'Jenis Media',
        headerAlign: 'center',
        align: 'center',
        sort: false
      },
      {
        dataField: 'link',
        text: 'Link Media',
        headerAlign: 'center',
        align: 'center',
        sort: false,
        formatter: (cell, row, index) => {
          if (row.jenis == 'Foto') {
            return <div className='d-flex justify-content-center'>
              <div className='parent-thumbnail-table'>
                <img
                  src={API_URL_WISATA + row.link}
                  alt={"Galeri " + (index + 1)}
                  className='thumbnail-table-size'
                  onClick={() => {
                    this.setState({ imagePreview: API_URL_WISATA + row.link })

                    // var modal = document.getElementById("myModal");

                    // var modalImg = document.getElementById("img01");
                    // var captionText = document.getElementById("caption-image");

                    // modal.style.display = "block";
                    // modalImg.src = API_URL_WISATA + row.link;
                    // captionText.innerHTML = "Galeri " + (index + 1);

                  }}
                />
              </div>
            </div>
          } else {
            return <iframe class="embed-responsive-item" src={row.link} style={{ width: '250px' }} allowfullscreen></iframe>;

            // return <a href={row.link} target="_blank">Lihat video</a>
          }
        },
      },
      {
        dataField: 'aksi',
        text: 'Aksi',
        headerAlign: 'center',
        align: 'center',
        style: {
          width: '150px'
        },
        formatter: function (cell, row) {
          return (
            <div className='d-flex justify-content-center'>
              <Button
                size="xs"
                color="success"
                className="btn-icon mr-2"
                onClick={() => this.ubahData(row)}
              >
                <i className="fa fa-pencil"></i>
              </Button>
              <Button
                size="xs"
                color="danger"
                className="btn-icon mr-2"
                onClick={() => {
                  this.setState({
                    alert: (
                      <ReactBootstrapSweetalert
                        showCancel
                        confirmBtnText="Hapus"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        type="danger"
                        title="Yakin ingin hapus data ?"
                        onCancel={() => {
                          this.setState({
                            alert: null
                          });
                        }}
                        onConfirm={() => this.hapusData(row.uuid)}
                      >
                        Proses ini tidak dapat dibatalkan!
                      </ReactBootstrapSweetalert>
                    )
                  });
                }}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          )
        }.bind(this)
      }
    ]

    return (
      <div>
        {/* New Modal show image */}
        {/* <div id="myModal" className="modal-image">
          <span className="close-image" onClick={() => {
            var modal = document.getElementById("myModal");
            modal.style.display = "none";
          }}>&times;</span>
          <img className="modal-content-image" id="img01" />
          <div id="caption-image"></div>
        </div> */}
        {/* End new modal show image */}

        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.alert}

        {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  {/* <div className="card-header">
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <Button
                          size="sm"
                          color="success"
                          className="btn-square"
                          onClick={() => {
                            this.setState({
                              judulForm: 'Tambah Galeri Wisata',
                              dt: frmDex,
                              link_file: '',
                            });
                            this.bukaForm();
                          }}
                        >
                          Tambah Data
                        </Button>
                      </div>
                    </div>
                  </div> */}
                  <div className="card-body datatable-react">
                    <div className="row">
                      {this.state.loading ? (
                        <div className="row">
                          <div className="col-sm-12" align="center">
                            <img
                              alt="loading"
                              src={require("../../assets/images/tes-loading.gif")}
                              style={{
                                borderRadius: "10px",
                                width: '100px'
                              }} />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-sm-5">
                            <select id='selectJenis' className='form-control' defaultValue={this.state.textCari} onChange={(e) => {
                              this.setState({
                                textCari: e.target.value,
                              })
                              setTimeout(
                                function () {
                                  this.fetch();
                                }.bind(this),
                                500
                              );
                            }}>
                              <option value="">Semua Kategori</option>
                              <option value="Foto">Foto</option>
                              <option value="Video">Video</option>
                            </select>
                          </div>
                          <div className="col-sm-7 text-right">
                            <Button
                              size="sm"
                              color="success"
                              className="btn-square"
                              onClick={() => {
                                this.setState({
                                  judulForm: 'Tambah Galeri Wisata',
                                  dt: frmDex,
                                  link_file: '',
                                });
                                this.bukaForm();
                              }}
                            >
                              Tambah Data
                            </Button>
                          </div>

                          <div className='col-sm-12'>
                            <BootstrapTable
                              keyField="id"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judulForm}</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} />

              <div className="form-row">
                <Label htmlFor="validationCustom05">Jenis Media</Label>
                <Col sm="12" className="mb-3">
                  <select id='jenis' name='jenis' className='form-control' defaultValue={this.state.dt.jenis} onChange={(e) => {
                    console.log(e);
                    this.setState({
                      dt: {
                        ...this.state.dt,
                        jenis: e.target.value,
                        link: '',
                      }
                    })
                  }} required>
                    <option value="">Pilih Jenis Media</option>
                    <option value="Foto">Foto</option>
                    <option value="Video">Video</option>
                  </select>
                </Col>
              </div>

              {this.state.dt && this.state.dt.jenis !== '' ? (
                <>
                  <div className="form-row">
                    <Label htmlFor="validationCustom05">Media</Label>
                    <Col sm="12">
                      {/* Jika jenis media foto upload file */}
                      {/* Jika video masukkan link video youtube */}
                      {this.state.dt.jenis == 'Foto' ? (
                        <Dropzone
                          onDrop={(files) => {

                            if (files.length === 1) {
                              let type = files[0].type.split('/');

                              if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {
                                let bodyFormData = new FormData();
                                bodyFormData.append('files', files[0]);

                                this.setState({ files })

                                PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                                  console.log(data);

                                  if (data.data.results) {
                                    this.setState({
                                      link_file: data.data.results
                                    });
                                  }
                                });

                              } else { // Jika bukan pdf
                                this.setState({
                                  show: true,
                                  basicType: 'warning',
                                  basicTitle: 'Terjadi Kesalahan',
                                  pesanAlert: 'Masukkan file dengan format gambar'
                                });
                              }
                            }
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                {...getRootProps()}
                                style={{
                                  minHeight: '100px',
                                  paddingTop: '40px',
                                  padding: '5px 5px 5px 5px',
                                  border: '1px solid #d2d2d2'
                                }}
                              >
                                <input {...getInputProps()} />
                                <div
                                  style={{ justifyContent: 'center', alignItems: 'center' }}
                                >
                                  {this.state.link_file ? (
                                    <img
                                      // src={this.state.link_file}
                                      src={API_URL_WISATA + this.state.link_file}
                                      alt="thumbnail artikel"
                                      style={{
                                        // width: '470px',
                                        maxHeight: '300px',
                                        maxWidth: '500px'
                                      }}
                                    />
                                  ) : (
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                  )}
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      ) : (
                        <input
                          className="form-control"
                          id="link"
                          type="text"
                          placeholder="Masukkan link video youtube"
                          defaultValue={this.state.dt.link}
                          minLength={7}
                          maxLength={250}
                          onChange={(e) => {
                            this.setState({
                              dt: {
                                ...this.state.dt,
                                link: e.target.value
                              }
                            })
                          }}
                          required
                        />
                      )}
                    </Col>
                  </div>
                </>
              ) : ''}
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnSubmit}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal image preview */}
        <Modal
          open={this.state.imagePreview ? true : false}
          onClose={() => {
            this.setState({ imagePreview: null })
          }}
          closeOnEsc={true}
          closeOnOverlayClick={true}
          styles={{ modal: { width: 'auto', maxWidth: 'unset' } }}
        >
          <div className="row">
            <div className="col-md-12 mt-4">
              <img src={this.state.imagePreview ? this.state.imagePreview : ''} alt="image-wisata-preview" style={{ width: '900px' }} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

GaleriWisata.propTypes = {

};

export default GaleriWisata;