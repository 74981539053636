import React, { Component } from 'react';

// import Custom Componenets
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// koneksi
import API_URL,{ Post, Delete, Get, Nama_DESA, JENIS_DESA } from '../../../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';

const frmData = {
	nama_kegiatan : "",
    volume : "",
    pemerintah : "",
    provinsi : "",
    kabupaten : "",
    swadaya : "",
    tahun_kegiatan : "",
    jumlah : "",
    waktu : "",
    baru : "",
    lanjutan : "",
    pelaksana : "",
    keterangan : "",
	uuid: '',
}

class MenuD2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			dt: frmData,
			judul: 'Tambah Kegiatan Pembangunan '+JENIS_DESA,
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			loading: false,
			loadingPreview: false,
			detailPreview: false,
			detailData: null,
			// Pagination
			pagination: [],
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			// Cari Data
			textCari: '',
			cetakPreview: false,
			listTahun: [],
			tahunCetak: null,
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	pagination = data => {
		// const data = this.state.pagination;
		let awal = "";
		let hal = [];
		let sebelum = "";
		let setelah = "";
		let akhir = "";
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = "primary-custom";
				if (i === data.current_page) {
					warna = "danger";
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={
							"btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
						}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{"<"}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{">"}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{"<<"}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{">>"}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};

	fetch = (params = {}) => {
		this.setState({ loading: true })
		let link = "";
		if (params.page) {
			switch (params.page) {
				case ">>":
					link =
						"?page=" +
						Math.ceil(
							this.state.pagination.total / this.state.pagination.per_page
						);
					break;
				case ">":
					link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case "<":
					link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case "<<":
					link = "?page=1";
					break;
				default:
					link = "?page=" + params.page;
					break;
			}
		}
		Post("kegiatan-pembangunan" + link, null, {
			q: this.state.textCari
		}, dtkat => {

			if (dtkat.data.results.data) {
				this.setState({
					data: dtkat.data.results.data,
					pagination: dtkat.data.results,
					loading: false
				})
				this.pagination(dtkat.data.results);
			}

		});
	};

	handleCetak = ((e) => {
		e.preventDefault()

		if (this.state.tahunCetak) {
			this.setState({ loading: false, cetakPreview: false })
			this.downloadLaporan()
		} else {
			const alertData = {
				show: true,
				basicType: "info",
				basicTitle: "Data Belum Lengkap",
				pesanAlert: "Mohon isi seluruh data dengan benar"
			}
			this.setState(alertData)
		}
	})

	// ================== simpan data ==================
	simpan = ((e) => {
		e.preventDefault();
		
		this.setState({ loadingPreview: true })

		if(
			document.getElementById("pemerintah").value ||
			document.getElementById("provinsi").value ||
			document.getElementById("kabupaten").value ||
			document.getElementById("swadaya").value
		){
			if(
				document.getElementById("baru").value ||
				document.getElementById("lanjutan").value
			){
				let bodyData = {
					nama_kegiatan: document.getElementById("nama_kegiatan").value,
					volume: document.getElementById("volume").value,
					pemerintah: document.getElementById("pemerintah").value.replace(/[^\d]/g, ''),
					provinsi: document.getElementById("provinsi").value.replace(/[^\d]/g, ''),
					kabupaten: document.getElementById("kabupaten").value.replace(/[^\d]/g, ''),
					swadaya: document.getElementById("swadaya").value.replace(/[^\d]/g, ''),
					tahun_kegiatan: document.getElementById("tahun_kegiatan").value,
					jumlah: document.getElementById("jumlah").value,
					waktu: document.getElementById("waktu").value,
					baru: document.getElementById("baru").value,
					lanjutan: document.getElementById("lanjutan").value,
					pelaksana: document.getElementById("pelaksana").value,
					keterangan: document.getElementById("keterangan").value,
					uuid: document.getElementById("uuid").value,
				}
	
				let psn = "";
				let resstat = 204;
				let metode = "create";
				if (bodyData.uuid === "") {
					psn = "tambah";
					resstat = 201;
					bodyData.uuid = null;
				} else {
					psn = "ubah";
					resstat = 200;
					metode = "update";
				}
	
				Post('kegiatan-pembangunan/' + metode, bodyData.uuid, bodyData, res => {
					if (res.status === resstat) {
						this.setState({
							loadingPreview: false,
							show: true,
							basicType: "success",
							basicTitle: "Data Kegiatan Pembangunan",
							pesanAlert: "Berhasil " + psn + " Data",
							dt: frmData,
							status: {
								...this.state.status,
								form: false
							}
						});
					} else {
						this.setState({
							loadingPreview: false,
							show: true,
							basicType: "danger",
							basicTitle: "Data Kegiatan Pembangunan",
							pesanAlert: "Gagal " + psn + " Data"
						});
					}
	
					this.componentDidMount();
				})
			}
			else{
				this.setState({
					loadingPreview: false,
					show: true,
					basicType: "danger",
					basicTitle: "Data Kegiatan Pembangunan",
					pesanAlert: "Data Sifat Proyek Tidak Boleh Kosong!"
				});
			}
		}
		else{
			this.setState({
				loadingPreview: false,
				show: true,
				basicType: "danger",
				basicTitle: "Data Kegiatan Pembangunan",
				pesanAlert: "Sumber Biaya Wajib Diisi!"
			});
		}
	});
	// ========================================================================

	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		
		Delete('kegiatan-pembangunan/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Kegiatan Pembangunan',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Kegiatan Pembangunan',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status: {
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	ubahData = data => {
		this.setState({
			judul: "Ubah Data Kegiatan Pembangunan",
			editMode: true
		})

		if (data) {
			this.setState({
				dt: {
					nama_kegiatan: data.nama_kegiatan,
					volume: data.volume,
					pemerintah: data.pemerintah,
					provinsi: data.provinsi,
					kabupaten: data.kabupaten,
					swadaya: data.swadaya,
					tahun_kegiatan: data.tahun_kegiatan,
					jumlah: data.jumlah,
					waktu: data.waktu,
					baru: data.baru,
					lanjutan: data.lanjutan,
					pelaksana: data.pelaksana,
					keterangan: data.keterangan,
					uuid: data.uuid,
				},	
				status: {
					...this.state.status,
					form: true
				}
			})
		}
	}

	changeSelectValue = (e, sel) => {
		this.setState({
			[sel]: e
		})
	}

	componentDidMount() {
		this.fetch()
		Get("kegiatan-pembangunan/list-tahun", null, dtkat => {
			let listTahun = []
			if (dtkat.results && dtkat.results.length > 0) {
				dtkat.results.map((e) => {
					listTahun.push({ value: e, label: e })
				})
			}
			this.setState({
				listTahun
			})
		});
	}

	// Cari Data Table
	cariData = e => {
		if (e.key === 'Enter') {
			let txt = e.target.value
			this.setState({
				textCari: txt.trim(),
				loading: true
			});

			setTimeout(
				function() {
					this.fetch();
				}.bind(this),
				500
			);
		}
	}

	
	// DATA DOWNLOAD LAPORAN
		downloadLaporan = () => {
			let link = API_URL + 'kegiatan-pembangunan/cetak?tahun=' + this.state.tahunCetak.value;
			let title = 'Buku Kegiatan Pembangunan '+ JENIS_DESA +' '+ Nama_DESA
			fetch(link, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
				'Content-Type': 'application/pdf',
			},
			})
			.then((response) => response.blob())
			.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${title}.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
			this.setState({
				loading: false,
			})
			});
			// ==============

			this.forceUpdate();
		};

	// END DATA DOWNLOAD LAPORAN

	render() {
		var data = this.state.data;

		const columns = [
			{
				dataField: "no",
				text: "No",
				isDummyField: true,
				csvExport: false,
				headerAlign: 'center',
				align: 'center',
				formatter: (key, obj, index) => {
					let current_pagenum = this.state.pagination.current_page;
					let total_records_per_page = this.state.pagination.per_page;
					let row_index = (index + 1);
					let serial_num = ((total_records_per_page * (current_pagenum - 1)) + row_index);
					return serial_num;
				}
			},
			{
				dataField: 'tahun_kegiatan',
				headerAlign: 'center',
				align: 'center',
				text: 'Tahun Kegiatan',
				sort: false
			},
			{
				dataField: 'nama_kegiatan',
				headerAlign: 'center',
				align: 'center',
				text: 'Nama Kegiatan',
				sort: false
			},
			{
				dataField: 'volume',
				headerAlign: 'center',
				align: 'center',
				text: 'Volume',
				sort: false
			},
			{
				dataField: 'jumlah',
				headerAlign: 'center',
				align: 'center',
				text: 'Jumlah',
				sort: false
			},
			{
				dataField: 'waktu',
				headerAlign: 'center',
				align: 'center',
				text: 'Waktu Pelaksanaan',
				sort: false
			},
			{
				dataField: 'pelaksana',
				headerAlign: 'center',
				align: 'center',
				text: 'Pelaksana',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				headerAlign: 'center',
				align: 'center',
				formatter: function (cell, row) {
					return (
						<div>
							<Button
								size="xs"
								color="success"
								className="btn-icon mr-2"
								onClick={() => this.ubahData(row)}
							// disabled={btnAksi}
							>
								<i className="fa fa-pencil"></i>
							</Button>{" "}
							<Button
								size="xs"
								color="info"
								className="btn-icon mr-2"
								onClick={() => { 
									this.setState({
										detailPreview: true,
										detailData: row
									})
								}}
							>
								<i className="fa fa-eye"></i>
							</Button>{" "}
							<Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.uuid)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>
							
						</div>
					)
				}.bind(this)
			}
		]

		return (
			<div>
				{/* <Breadcrumb title="Data Peraturan Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<SweetAlert
					show={this.state.loadingPreview}
					showCancel={false}
					showConfirm={false}
				>
					<div align="center">
						<img
							alt="loading"
							src={require("../../../assets/images/tes-loading.gif")}
							style={{
								borderRadius: "10px",
								width: '150px'
							}}
						/>
					</div>
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6 text-left">
											<Button
												size="sm"
												color="default"
												style={{ backgroundColor: '#FFC312' }}
												onClick={this.props.history.goBack}
											>
												<i className="fa fa-arrow-left"></i> Kembali
											</Button>
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="info"
												className="btn-icon mr-2"
												onClick={() => {
													// this.setState({
													// 	loading: true,
													// });
													// this.downloadLaporan();
													this.setState({ cetakPreview: true })
												}}
												>
													Cetak
											</Button>
											&nbsp;&nbsp;&nbsp;&nbsp;
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah Kegiatan Pembangunan',
														dt: frmData,
													});
													// document.getElementById('form-data').reset()
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>

								<div className="card-body datatable-react">
									<div className="row">
										<div className="col-md-12">
											{this.state.loading ? (
												<div className="row">
													<div className="col-sm-12" align="center">
														<img
															alt="loading"
															src={require("../../../assets/images/tes-loading.gif")}
															style={{
																borderRadius: "10px",
																width: '100px'
															}} />
													</div>
												</div>
											) : (
												<>
													<div className='row'>
														<div className="col-md-5 text-right">
															<input
																type="text"
																className="form-control"
																style={{ marginBottom: '15px' }}
																id="cariTable"
																placeholder="Cari berdasarkan nama kegiatan atau tahun perencanaan"
																defaultValue={this.state.textCari}
																onChange={(e) => {
																	this.setState({ textCari: e.target.value })
																}}
																onKeyPress={this.cariData}
															/>
															<div className="clearBtnSearch" style={{ display: this.state.textCari !== '' ? 'inline' : '' }}>
																<span className='fa fa-close' style={{ fontSize: '1.3em', cursor: 'pointer' }} onClick={() => {
																	this.setState({
																		textCari: '',
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																	document.getElementById('cariTable').value = ''
																}} ></span>
															</div>
														</div>
														<div className="col-md-7" style={{ marginBottom: '15px' }}>
															<Button
																size="sm"
																color="info"
																className="btn-square"
																onClick={() => {
																	this.setState({
																		textCari: document.getElementById("cariTable").value,
																		loading: true
																	});
																	setTimeout(
																		function () {
																			this.fetch();
																		}.bind(this),
																		500
																	);
																}}
															>
																<i className="fa fa-search"></i>
															</Button>
														</div>
														{data.length > 0 ? (
															<>
																<div className="col-md-12">
																	<BootstrapTable
																		keyField="id"
																		data={data}
																		columns={columns}
																	/>
																	<div className="pull-right text-white">
																		{this.state.awal}
																		{this.state.sebelum}
																		{this.state.hal.map(dt => {
																			return dt;
																		})}
																		{this.state.setelah}
																		{this.state.akhir}
																	</div>
																</div>
															</>
														) : (
															<div className="col-md-12">
																<h6 className="text-center mb-2 mt-2">Data tidak ditemukan</h6>
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				
				<Modal
					open={this.state.detailPreview ? true : false}
					onClose={() => {
						this.setState({ detailPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '50%', maxWidth: 'unset' } }}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail Data Kegiatan Pembangunan {JENIS_DESA}</h5>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								{this.state.detailData ? (
									<>
										<div className="table-responsive">
											<table className='detail-dokumen-administrasi'>
												<tbody>
													<tr>
														<th width="280">Nama Kegiatan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.nama_kegiatan}</span></td>
													</tr>
													<tr>
														<th width="280">Tahun Kegiatan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.tahun_kegiatan}</span></td>
													</tr>
													<tr>
														<th width="280">Volume</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.volume}</span></td>
													</tr>
													<tr>
														<th width="280">Jumlah</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.jumlah}</span></td>
													</tr>
													<tr>
														<th width="280">Waktu</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.waktu}</span></td>
													</tr>
													<tr>
														<th width="280">Baru</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.baru}</span></td>
													</tr>
													<tr>
														<th width="280">Lanjutan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.lanjutan}</span></td>
													</tr>
													<tr>
														<th width="280">Pelaksana</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.pelaksana}</span></td>
													</tr>
													<tr>
														<th width="280">Keterangan</th>
														<th width="50" className="text-center">:</th>
														<td><span>{this.state.detailData.keterangan}</span></td>
													</tr>
												</tbody>
												<tr>
													<th>&nbsp;</th>
													<th>&nbsp;</th>
													<th>&nbsp;</th>
												</tr>
												<tr>
													<th><b>Sumber Biaya</b></th>
													<th>&nbsp;</th>
													<th>&nbsp;</th>
												</tr>
												<tr>
													<td colSpan={3}>
														<div style={{overflowX:'auto'}}>
															<table style={{width:'100%', borderCollapse:'collapse', border:'1px solid'}}>
																<tr style={{ border:'1px solid'}}>
																	<th>Pemerintah</th>
																	<th>Provinsi</th>
																	<th>Kabupaten</th>
																	<th>Swadaya</th>
																</tr>
																<tr>
																	<td>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={'Rp. '}
																			displayType={'text'}
																			defaultValue={this.state.detailData.pemerintah}
																			value={this.state.detailData.pemerintah}
																		/>
																	</td>
																	<td>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={'Rp. '}
																			displayType={'text'}
																			defaultValue={this.state.detailData.provinsi}
																			value={this.state.detailData.provinsi}
																		/>
																	</td>
																	<td>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={'Rp. '}
																			displayType={'text'}
																			defaultValue={this.state.detailData.kabupaten}
																			value={this.state.detailData.kabupaten}
																		/>
																	</td>
																	<td>
																		<NumberFormat
																			thousandSeparator={true}
																			prefix={'Rp. '}
																			displayType={'text'}
																			defaultValue={this.state.detailData.swadaya}
																			value={this.state.detailData.swadaya}
																		/>
																	</td>
																</tr>
															</table>
														</div>
													</td>
												</tr>
											</table>
										</div>
									</>
								) : ''}
							</div>
						</div>
					</div>
				</Modal>
				<Modal
					open={this.state.cetakPreview ? true : false}
					onClose={() => {
						this.setState({ cetakPreview: false })
					}}
					closeOnEsc={true}
					closeOnOverlayClick={true}
					styles={{ modal: { width: '40%', maxWidth: 'unset' } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.handleCetak(e) }}>
						<div className="modal-header">
							<h5 className="modal-title" style={{ fontWeight: 'bold' }}>Cetak Data Kas Umum</h5>
						</div>
						<div className="modal-body">
							<div className="form-row">
								<Col sm="12 mb-2">
									<Label htmlFor="validationCustom05">Tahun</Label>
									<ReactSelect
										classNamePrefix="select"
										onChange={e => {
											this.changeSelectValue(e, "tahunCetak")
										}}
										defaultValue={this.state.tahunCetak}
										value={this.state.tahunCetak}
										name="nik"
										options={this.state.listTahun}
										placeholder="Pilih Tahun"
										// isClearable
									/>
								</Col>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								onClick={() => {
									this.setState({ cetakPreview: false })
								}}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success">
								Cetak
							</button>
						</div>
					</Form>
				</Modal>
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '60%', maxWidth: "unset" } }}
				>
					<Form id="form-data" className="theme-form" onSubmit={(e) => { this.simpan(e) }}>
						<input className="form-control" id="uuid" type="hidden" defaultValue={this.state.dt.uuid} value={this.state.dt.uuid} />
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							
							<div style={{ backgroundColor:'#666666', padding:'10px'}}>
								<h6><b><font color='white'>Data Kegiatan</font></b></h6>
							</div>
							<div className='col-sm-12'>
								&nbsp;
							</div>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Nama Kegiatan
									</Label>
									<input
										className="form-control"
										id="nama_kegiatan"
										type="text"
										placeholder='Contoh: Pembangunan Jalan'
										defaultValue={this.state.dt.nama_kegiatan}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Tahun Kegiatan
									</Label>
									<input
										className="form-control"
										id="tahun_kegiatan"
										type="text"
										pattern="\d*" maxlength="4" min="1900" max="2099"
										placeholder='Contoh: 2022'
										defaultValue={this.state.dt.tahun_kegiatan}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Volume
									</Label>
									<input
										className="form-control"
										id="volume"
										type="text"
										defaultValue={this.state.dt.volume}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Jumlah
									</Label>
									<input
										className="form-control"
										id="jumlah"
										type="text"
										pattern="\d*"
										defaultValue={this.state.dt.jumlah}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Waktu Pengerjaan
									</Label>
									<input
										className="form-control"
										id="waktu"
										type="text"
										placeholder='Contoh: 2 Tahun'
										defaultValue={this.state.dt.waktu}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Pelaksana
									</Label>
									<input
										className="form-control"
										id="pelaksana"
										type="text"
										defaultValue={this.state.dt.pelaksana}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Baru
									</Label>
									<input
										className="form-control"
										id="baru"
										type="text"
										defaultValue={this.state.dt.baru}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Lanjutan
									</Label>
									<input
										className="form-control"
										id="lanjutan"
										type="text"
										defaultValue={this.state.dt.lanjutan}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Col sm="12" className="mb-2">
									<Label htmlFor="inputEmail3">
										Keterangan
									</Label>
									<textarea
										className="form-control"
										id="keterangan"
										type="text"
										cols="30"
										defaultValue={this.state.dt.keterangan}
									/>
								</Col>
							</FormGroup>

							<div className='col-md-12'> &nbsp;</div>

							<div style={{ backgroundColor:'#666666', padding:'10px'}}>
								<h6><b><font color='white'>Data Sumber Biaya</font></b></h6>
							</div>
							<div className='col-md-12'> &nbsp;</div>

							<FormGroup className="row">
								<Col sm="6">
									Pemerintah<br/>
									<NumberFormat
										data-testid="pemerintah"
										id="pemerintah"
										name="pemerintah"
										placeholder="Sumber Biaya Pemerintah"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.pemerintah}
										value={this.state.dt.pemerintah}
									/>
								</Col>
								<Col sm="6">
									Provinsi<br/>
									<NumberFormat
										data-testid="provinsi"
										id="provinsi"
										name="provinsi"
										placeholder="Sumber Biaya Provinsi"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.provinsi}
										value={this.state.dt.provinsi}
									/>
								</Col>
								<Col sm="12">&nbsp;</Col>
								<Col sm="6">
									Kabupaten<br/>
									<NumberFormat
										data-testid="kabupaten"
										id="kabupaten"
										name="kabupaten"
										placeholder="Sumber Biaya Kabupaten"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.kabupaten}
										value={this.state.dt.kabupaten}
									/>
								</Col>
								<Col sm="6">
									Swadaya<br/>
									<NumberFormat
										data-testid="swadaya"
										id="swadaya"
										name="swadaya"
										placeholder="Sumber Biaya Swadaya"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										defaultValue={this.state.dt.swadaya}
										value={this.state.dt.swadaya}
									/>
								</Col>
							</FormGroup>
							<small><b><font color='red'>* Biaya diisi sesuai dengan Sumber Biayanya</font></b></small>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default MenuD2;
