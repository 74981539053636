import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Col, Form, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { JENIS_DESA, Nama_DESA, KECAMATAN, NAMA_KABUPATEN, PROVINSI, Post, Get, GetWisata, ID_DESA, PostWisata, PatchWisata, API_URL_WISATA } from '../../function/Koneksi';
import Modal from 'react-responsive-modal';
import Dropzone from 'react-dropzone';
import ReactBootstrapSweetalert from 'react-bootstrap-sweetalert';

// Leaflet
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

class PengaturanDesaWisata extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        uuid: 'tes',
        id_desa: '',
        nama: '',
        alamat: '',
        latitude: null,
        longitude: null,
        slug: '',
        deskripsi_singkat: '',
        no_telp: '',
        email: '',
        link_fb: '',
        link_ig: '',
        link_youtube: '',
        thumbnail: '',
        status: '',
      },
      files: [],
      link_file: '',
      link_thumbnail: '',
      active_tab_icon: "1",
      pagination: [],
      status: {
        form: false,
        formProfil: false,
        btnForm: false,
      },
      detailData: null,
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      statUbah: false,
      dt: [],
      loading: false,
      loadingPreview: false,
      alert: null,

      curStaticLocation: [0, 0],
			currentLocation: [0, 0],

      foto_desa: null,

      // SweetAlert
      show: false,
      basicTitle: '',
      basicType: 'default',
      pesanAlert: '',

      dataCari: {
        q: ""
      }
    };
  }

  getLocation(latitude = null, longitude = null) {
    if ("geolocation" in navigator) {
      console.log("Geolocation Available");
      navigator.geolocation.getCurrentPosition(function (position) {
        var lat = (position.coords.latitude)
        var long = (position.coords.longitude)

        if (lat !== null && long !== null) {
          lat = latitude
          long = longitude
        }

        const currentLocation = [lat, long]
        this.setState({ currentLocation, curStaticLocation: currentLocation })
        this.forceUpdate()

      }.bind(this));
    } else {
      console.log("Geolocation Not Available");
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    // this.getLocation()

    Get('profile-desa', null, (dtkat) => {
      if (dtkat.results) {
        this.setState({
          foto_desa: dtkat.results.foto_desa,
        });
      }
      this.setState({ loading: false })
    });

    GetWisata('desa-wisata/show/' + ID_DESA, null, (dtkat) => {
      if (dtkat.results) {
        this.getLocation(dtkat.results.latitude, dtkat.results.longitude)

        this.setState({
          data: {
            ...this.state.data,
            ...dtkat.results,
          },
          currentLocation: [dtkat.results.latitude, dtkat.results.longitude],
          link_thumbnail: dtkat.results.thumbnail
        });
      }
      this.setState({ loading: false })
    });
  }

  pengajuanVerifikasi = (status, uuid) => {
    this.setState({
      link_file: '',
      status: {
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  ubahData = () => {
    this.setState({
      status: {
        ...this.state.status,
        formProfil: true,
      },
      judul: 'Ubah Profil ' + JENIS_DESA + ' Wisata',
    })
    this.forceUpdate();
  }

  simpanPengajuan = e => {
    e.preventDefault()

    if (this.state.data && this.state.link_file) {
      this.setState({
        loadingPreview: true,
        status: {
          ...this.state.status,
          btnForm: true,
        }
      })

      let bodyData = {
        uuid_desa_wisata: this.state.data.uuid,
        id_desa: ID_DESA,
        keterangan: 'pengajuan',
        file: this.state.link_file,
      }

      PostWisata("desa-wisata/pengajuan", null, bodyData, res => {
        this.state.status.btnForm = false;
        this.forceUpdate();

        if (res.status === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Verifikasi Desa Wisata",
            pesanAlert: "Berhasil Mengajukan Verifikasi Desa Wisata Official",
            loadingPreview: false,
            status: {
              ...this.state.status,
              btnForm: false,
              form: false
            }
          });
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Verifikasi Desa Wisata",
            pesanAlert: "Gagal Mengajukan Verifikasi Desa Wisata Official",
            loadingPreview: false,
            status: {
              ...this.state.status,
              btnForm: false,
              form: false
            }
          });
        }
        this.componentDidMount();
      });

    }
  }

  simpan = e => {
    e.preventDefault();

    this.setState({ loadingPreview: true })

    let uuid = document.getElementById("uuid").value;
    let nama = document.getElementById("nama").value;
    let alamat = document.getElementById("alamat").value;
    let deskripsi_singkat = document.getElementById("deskripsi_singkat").value;
    let no_telp = document.getElementById("no_telp").value;
    let latitude = this.state.data.latitude;
    let longitude = this.state.data.longitude;
    let email = document.getElementById("email").value;
    let link_fb = document.getElementById("link_fb").value;
    let link_ig = document.getElementById("link_ig").value;
    let link_youtube = document.getElementById("link_youtube").value;
    let thumbnail = this.state.link_thumbnail

    // Validasi
    if (nama !== '' && alamat !== '' && deskripsi_singkat !== '' && no_telp !== '' && latitude != 0 && longitude != 0 && email !== '' && link_fb !== '' && link_ig !== '' && link_youtube !== '' && thumbnail !== '') {

      let addInfo = {
        uuid,
        nama,
        alamat,
        deskripsi_singkat,
        no_telp,
        latitude,
        longitude,
        email,
        link_fb,
        link_ig,
        link_youtube,
        thumbnail,
      };

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addInfo.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addInfo.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 201;
        metode = "update";
      }

      PostWisata("desa-wisata/" + metode, ID_DESA, addInfo, res => {
        this.state.status.btnForm = false;
        this.state.status.form = false;
        this.forceUpdate();
        if (res.status === resstat) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Info " + JENIS_DESA,
            pesanAlert: "Berhasil Ubah Data",
            loadingPreview: false,
            status: {
              ...this.state.status,
              formProfil: false
            }
          });
          this.componentDidMount();
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Info " + JENIS_DESA,
            pesanAlert: "Gagal Ubah Data",
            loadingPreview: false,
            status: {
              ...this.state.status,
              formProfil: false
            }
          });
        }
        // this.componentDidMount();
      });
    } else {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Desa Wisata",
        pesanAlert: "Isi seluruh data dengan benar"
      });
    }

  }

  tutupForm = (e) => {
    this.setState({
      status: {
        ...this.state.status,
        form: false,
        formProfil: false,
      },
    });
    this.forceUpdate()
  };

  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  render() {

    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <div>
        <ReactBootstrapSweetalert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </ReactBootstrapSweetalert>

        <ReactBootstrapSweetalert
          show={this.state.loadingPreview}
          showCancel={false}
          showConfirm={false}
        >
          <div align="center">
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <h5 className="modal-title mb-3" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
          </div>
        </ReactBootstrapSweetalert>

        {this.state.loading ? (
          <div align="center">
            <h5 className="modal-title" style={{ textAlign: "center", color: 'black' }}>Sedang memuat data...</h5>
            <br />
            <img
              alt="loading"
              src={require("../../assets/images/tes-loading.gif")}
              style={{
                // width: "100%",
                borderRadius: "10px",
                width: '150px'
              }}
            />
            <br /><br />
          </div>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  {/* <div className="card-header">
                    <div className="row">
                      <div className="col-sm-6">
                      </div>
                      <div className="col-sm-6 text-right">
                      </div>
                    </div>
                  </div> */}
                  <div className="card-body" style={{ padding: '19px' }}>
                    <div className="row">
                      <div className="col-md-12 text-right mb-0">
                        <Button size="md" color="success" className="btn" onClick={() => this.ubahData()}><i className="fa fa-pencil"></i> Ubah Data Profil {JENIS_DESA} Wisata</Button>

                        {this.state.data && this.state.data.status === 'Belum Terverifikasi' ? (
                          <Button size="md" color="info" className="btn-icon" onClick={() => {
                            this.pengajuanVerifikasi('update', this.state.data.uuid)
                          }} disabled={this.state.status.btnAksi}><i className="fa fa-check"></i> Ajukan Status {JENIS_DESA} Wisata Official</Button>
                        ) : ''}

                      </div>
                    </div>
                    <div
                      className="row pt-4"
                      style={{ padding: '20px', margin: '0' }}
                    >
                      {
                        this.state.data.uuid
                          ?
                          <>
                            <div className="col-md-12">
                              <div className='d-flex mb-3' style={{ justifyContent: "center" }}>
                                <img alt="fotodesa" src={this.state.foto_desa} style={{ maxHeight: '200px', maxWidth: '100%', height: 'auto' }} />
                              </div>
                              <div className="table-responsive">
                                <table>
                                  <thead style={{ fontSize: "1rem", verticalAlign: "top" }}>
                                    <tr>
                                      <th width="180">{JENIS_DESA} Wisata Official</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.status}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Nama {JENIS_DESA}</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{Nama_DESA}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Nama {JENIS_DESA} Wisata</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.nama}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Deskripsi Singkat</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.deskripsi_singkat}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Alamat {JENIS_DESA}</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.alamat}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Kecamatan</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{KECAMATAN}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Kabupaten/Kota</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{NAMA_KABUPATEN}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Provinsi</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{PROVINSI}</span></td>
                                    </tr>
                                    <tr>
                                      <th>No. Telepon</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.no_telp}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Latitude</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.latitude}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Longitude</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.longitude}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Email</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span>{this.state.data.email}</span></td>
                                    </tr>
                                    <tr>
                                      <th>Link Facebook</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span><a href={this.state.data.link_fb} target='_blank'>{this.state.data.link_fb}</a></span></td>
                                    </tr>
                                    <tr>
                                      <th>Link Instagram</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span><a href={this.state.data.link_ig} target='_blank'>{this.state.data.link_ig}</a></span></td>
                                    </tr>
                                    <tr>
                                      <th>Link Youtube</th>
                                      <th width="50" className="text-center">:</th>
                                      <td><span><a href={this.state.data.link_youtube} target='_blank'>{this.state.data.link_youtube}</a></span></td>
                                    </tr>
                                    <tr>
                                      <th>Thumbnail</th>
                                      <th width="50" className="text-center">:</th>
                                      <td>
                                        <img
                                          src={this.state.data && this.state.data.thumbnail ? API_URL_WISATA + this.state.data.thumbnail : ''}
                                          alt="thumbnail desa wisata"
                                          style={{
                                            maxHeight: '300px',
                                            maxWidth: '500px',
                                            width: '450px',
                                            height: 'auto',
                                            objectFit: 'contain'
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </thead>
                                </table>
                              </div>
                            </div>
                          </>
                          :
                          (
                            <div className="col-md-12 text-center">
                              <h4 align="center">Data Tidak Dapat Ditemukan</h4>
                            </div>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanPengajuan}>
            <div className="modal-header">
              <h4 className="modal-title">Form Pengajuan Status {JENIS_DESA} Wisata Official</h4>
            </div>

            <div className="modal-body">
              {/* <input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid} /> */}

              <div className="row">
                <div className="col-md-12">
                  <p>
                    Lampirkan file pendukung dengan format .pdf yang berisikan sertifikat, bukti, ataupun hal lain yang menunjukkan bahwa {JENIS_DESA} ini merupakan {JENIS_DESA} Wisata Resmi yang diakui oleh Pemerintah  Republik Indonesia.
                  </p>
                </div>
                <div className="col-sm-12">
                  <Label className="col-form-label" htmlFor="inputEmail3">
                    File pendukung
                  </Label>
                  <Dropzone multiple={false} onDrop={(files) => {
                    if (files.length === 1) {
                      let type = files[0].type.split('/');
                      if (type[1] === 'pdf') {
                        let bodyFormData = new FormData();
                        bodyFormData.append('files', files[0]);

                        this.setState({ files })

                        Post('upload-file/desa-wisata', null, bodyFormData, (data) => {
                          if (data.data.results) {
                            this.setState({
                              link_file: data.data.results
                            });
                          }
                        });

                      } else { // Jika bukan pdf
                        this.setState({
                          show: true,
                          basicType: 'warning',
                          basicTitle: 'Terjadi Kesalahan',
                          pesanAlert: 'Masukkan file dengan format .pdf'
                        });
                      }
                    }
                  }}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            minHeight: '100px',
                            paddingTop: '40px',
                            padding: '5px 5px 5px 5px',
                            border: '1px solid #d2d2d2'
                          }}
                        >
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>

                  {this.state.files && this.state.files.length > 0 ? (
                    <aside className='mt-4'>
                      <h5>File :</h5>
                      <ul>{files}</ul>
                    </aside>
                  ) : ''}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>

        {/* Modal Ubah Profil */}
        <Modal
          open={this.state.status.formProfil}
          onClose={this.tutupForm}
          styles={{ modal: { width: '65%', maxWidth: 'unset' } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h4 className="modal-title">Ubah Profil {JENIS_DESA} Wisata</h4>
            </div>

            <div className="modal-body">
              <input className="form-control" id="uuid" type="hidden" defaultValue={this.state.data.uuid} />

              <div className="form-row ">
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Nama {JENIS_DESA} Wisata</Label>
                  <input
                    className="form-control"
                    id="nama"
                    name="nama"
                    type="text"
                    placeholder={`Nama ${JENIS_DESA} Wisata`}
                    // value={this.state.data.nama}
                    defaultValue={this.state.data.nama}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Alamat</Label>
                  <textarea
                    className="form-control"
                    id="alamat"
                    name="alamat"
                    type="text"
                    rows={3}
                    placeholder={`Alamat ${JENIS_DESA} Wisata`}
                    required
                    defaultValue={this.state.data.alamat}
                  // value={this.state.data.alamat}
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Deskripsi Singkat</Label>
                  <textarea
                    className="form-control"
                    id="deskripsi_singkat"
                    name="deskripsi_singkat"
                    type="text"
                    rows={3}
                    placeholder={`Deskripsi Singkat ${JENIS_DESA} Wisata`}
                    required
                    defaultValue={this.state.data.deskripsi_singkat}
                  // value={this.state.data.deskripsi_singkat}
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">No. Telepon</Label>
                  <input
                    className="form-control"
                    id="no_telp"
                    name="no_telp"
                    type="text"
                    maxLength={13}
                    minLength={1}
                    pattern="[0-9]{9,}"
                    placeholder={`No. Telpon`}
                    // value={this.state.data.no_telp}
                    defaultValue={this.state.data.no_telp}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Pilih Lokasi Wisata</Label>
                  <MapContainer center={this.state.currentLocation} zoom={12} scrollWheelZoom={false} id="mapwisata" >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                      draggable={true}
                      position={this.state.currentLocation}
                      eventHandlers={{
                        dragend: (event) => {
                          const latitude = event.target._latlng.lat
                          const longitude = event.target._latlng.lng

                          this.setState({
                            currentLocation: [latitude, longitude],
                            data: {
                              ...this.state.data,
                              latitude,
                              longitude
                            }
                          })
                          this.forceUpdate()
                        }
                      }}
                    >
                      <Popup>
                        {this.state.dt.nama}
                      </Popup>
                    </Marker>
                  </MapContainer>
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Email</Label>
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    placeholder={`Email ${JENIS_DESA} Wisata`}
                    // value={this.state.data.email}
                    defaultValue={this.state.data.email}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Link Facebook</Label>
                  <input
                    className="form-control"
                    id="link_fb"
                    name="link_fb"
                    type="text"
                    placeholder={`Link Facebook ${JENIS_DESA} Wisata`}
                    // value={this.state.data.link_fb}
                    defaultValue={this.state.data.link_fb}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Link Instagram</Label>
                  <input
                    className="form-control"
                    id="link_ig"
                    name="link_ig"
                    type="text"
                    placeholder={`Link Instagram ${JENIS_DESA} Wisata`}
                    // value={this.state.data.link_ig}
                    defaultValue={this.state.data.link_ig}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Link Youtube</Label>
                  <input
                    className="form-control"
                    id="link_youtube"
                    name="link_youtube"
                    type="text"
                    placeholder={`Link Youtube ${JENIS_DESA} Wisata`}
                    // value={this.state.data.link_youtube}
                    defaultValue={this.state.data.link_youtube}
                    required
                  />
                </Col>
                <Col sm="12" className="mb-3">
                  <Label htmlFor="validationCustom05">Foto Thumbnail {JENIS_DESA} Wisata</Label>
                  <Dropzone
                    onDrop={(files) => {

                      if (files.length === 1) {
                        let type = files[0].type.split('/');

                        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'webp') {

                          let bodyFormData = new FormData();
                          bodyFormData.append('files', files[0]);
                          // this.setState({ files })

                          PostWisata('upload-file/desa-wisata', null, bodyFormData, (data) => {
                            // console.log(data);

                            if (data.data.results) {
                              this.setState({
                                link_thumbnail: data.data.results
                              });
                            }
                          });

                        } else { // Jika bukan pdf
                          this.setState({
                            show: true,
                            basicType: 'warning',
                            basicTitle: 'Terjadi Kesalahan',
                            pesanAlert: 'Masukkan file dengan format gambar jpg, jpeg, png, atau webp'
                          });
                        }
                      }
                    }}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          style={{
                            minHeight: '100px',
                            paddingTop: '40px',
                            padding: '5px 5px 5px 5px',
                            border: '1px solid #d2d2d2'
                          }}
                        >
                          <input {...getInputProps()} />
                          <div
                            style={{ justifyContent: 'center', alignItems: 'center' }}
                          >
                            {this.state.link_thumbnail ? (
                              <div className='text-center'>
                                <img
                                  src={API_URL_WISATA + this.state.link_thumbnail}
                                  alt="thumbnail desa wisata"
                                  style={{
                                    // width: '470px',
                                    maxHeight: '300px',
                                    maxWidth: '500px'
                                  }}
                                />
                              </div>
                            ) : (
                              <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Col>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-default" onClick={this.tutupForm}>
                Batal
              </button>
              <button
                type="submit"
                className="btn btn-success"
              // disabled={this.state.status.btnFormAcc}
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

PengaturanDesaWisata.propTypes = {

};

export default PengaturanDesaWisata;